export interface IPhone {
  number: string;
  extension: string;
  type: number;
  isPrimary: boolean;
}

enum PhoneType {
  HomePhone = 1,
  WorkPhone = 2,
  CellPhone = 3,
  WorkFax = 4
}

export interface IApiUserPhoneNumbers {
  phoneNumbers: Partial<IPhone>[];
}
export interface IAppUserPhoneNumbers {
  workPhone: string;
  workPhoneExtension: string;
  mobilePhone: string;
  workFax?: string;
  workFaxExtension?: string;
}

export const getPhoneNumbersForApp: (user: Partial<IApiUserPhoneNumbers>) => IAppUserPhoneNumbers = (user: Partial<IApiUserPhoneNumbers>): IAppUserPhoneNumbers => {
  const mobilePhoneModel = user.phoneNumbers?.find(o => o.type === PhoneType.CellPhone);
  const workPhoneModel = user.phoneNumbers?.find(o => o.type === PhoneType.WorkPhone);
  const workFaxModel = user.phoneNumbers?.find(o => o.type === PhoneType.WorkFax);
  return {
    workPhone: workPhoneModel?.number,
    workPhoneExtension: workPhoneModel?.extension,
    mobilePhone: mobilePhoneModel?.number,
    workFax: workFaxModel?.number,
    workFaxExtension: workFaxModel?.extension
  };
};

export const getPhoneNumbersForApi: (user: IAppUserPhoneNumbers) => IApiUserPhoneNumbers = (user: IAppUserPhoneNumbers): IApiUserPhoneNumbers => {
  const phoneNumbers = [];
  if (user.workPhone) {
    phoneNumbers.push({
      number: user.workPhone,
      type: PhoneType.WorkPhone,
      isPrimary: true,
      extension: user.workPhoneExtension
    });
  }
  if (user.mobilePhone) {
    phoneNumbers.push({
      number: user.mobilePhone,
      type: PhoneType.CellPhone,
      isPrimary: false,
      extension: null
    });
  }
  if (user.workFax) {
    phoneNumbers.push({
      number: user.workFax,
      type: PhoneType.WorkFax,
      isPrimary: false,
      extension: user.workFaxExtension
    });
  }
  return { phoneNumbers };
};
