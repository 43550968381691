import { Component, Inject, InjectionToken, OnInit } from '@angular/core';
import { ErrorObject } from '@safarilaw-webapp/shared/error-handling-message-parser';

export const GATEWAY_TIMEOUT_URLS = new InjectionToken<string>('Gateway Timeout Urls');
@Component({
  standalone: true,
  selector: 'sl-root-gateway-timeout',
  templateUrl: './gateway-timeout.component.html',
  styleUrls: ['./gateway-timeout.component.scss']
})
export class GatewayTimeoutComponent implements OnInit {
  errorObject: ErrorObject;

  constructor(@Inject(GATEWAY_TIMEOUT_URLS) public gatewayTimeoutUrls) {}

  ngOnInit() {
    this.errorObject = window['safari-error-state']?.errorObject;
  }
}
