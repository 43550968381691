import { Injectable } from '@angular/core';

import { ApiDataAdapter } from '@safarilaw-webapp/shared/crud';
import { CreateTeamModel, TeamListModel, TeamModel, UpdateTeamProfileModel } from '../../../_comanage-api-models.generated';

import { ApiCallContext, ObjectHelper } from '@safarilaw-webapp/shared/common-objects-models';
import { Team } from '../../models/app/team';

@Injectable({
  providedIn: 'root'
})
export class TeamAdapter extends ApiDataAdapter<Team> {
  toDeleteModel(model: any) {
    return model;
  }
  toCreateModel(team: Team): Required<CreateTeamModel> {
    return {
      name: team.name,
      address: team.address,
      invoiceContactEmail: team.invoiceContactEmail,
      invoiceContactName: team.invoiceContactName,
      reviewerIsSupportContact: team.reviewerIsSupportContact,
      deliveryPortalContactEmail: team.deliveryPortalContactEmail,
      deliveryPortalContactName: team.deliveryPortalContactName
    };
  }
  fromGetNewModel(context?: ApiCallContext): Required<Team> {
    return {
      id: ObjectHelper.getNewId(),
      name: '',
      address: {
        street1: '',
        street2: '',
        city: '',
        state: null,
        zipCode: '',
        country: 1
      },
      invoiceContactEmail: null,
      invoiceContactName: null,
      reviewerIsSupportContact: false,
      deliveryPortalContactEmail: null,
      deliveryPortalContactName: null
    };
  }
  toUpdateModel(team: Team): Required<UpdateTeamProfileModel> {
    return {
      name: team.name,
      address: team.address,
      invoiceContactEmail: team.invoiceContactEmail,
      invoiceContactName: team.invoiceContactName,
      reviewerIsSupportContact: team.reviewerIsSupportContact,
      deliveryPortalContactEmail: team.deliveryPortalContactEmail,
      deliveryPortalContactName: team.deliveryPortalContactName
    };
  }
  fromGetModel(team: TeamModel): Required<Team> {
    return {
      name: team.name,
      address: team.address
        ? {
            city: team.address.city,
            country: team.address.country,
            state: team.address.state,
            street1: team.address.street1,
            street2: team.address.street2,
            zipCode: team.address.zipCode
          }
        : null,
      id: team.id.toString(),
      invoiceContactEmail: team.invoiceContactEmail,
      invoiceContactName: team.invoiceContactName,
      reviewerIsSupportContact: team.reviewerIsSupportContact,
      deliveryPortalContactEmail: team.deliveryPortalContactEmail,
      deliveryPortalContactName: team.deliveryPortalContactName
    };
  }

  fromListModel(team: TeamListModel): Required<Team> {
    return {
      address: team.address
        ? {
            city: team.address.city,
            country: team.address.country,
            state: team.address.state,
            street1: team.address.street1,
            street2: team.address.street2,
            zipCode: team.address.zipCode
          }
        : null,
      name: team.name,
      invoiceContactEmail: team.invoiceContactEmail,
      invoiceContactName: team.invoiceContactName,
      reviewerIsSupportContact: team.reviewerIsSupportContact,
      deliveryPortalContactEmail: team.deliveryPortalContactEmail,
      deliveryPortalContactName: team.deliveryPortalContactName,
      id: team.id as unknown as string
    };
  }
}
