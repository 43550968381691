<div class="alert" style="margin-bottom: 0px;">
<div class="modal-header ">
    <h4 class="modal-title pull-left">{{title}}</h4>
</div>

<div class="modal-body" [innerHTML]="content">
    
</div>
<div class="modal-footer justify-content-center">
    <button id="confirmation-dialog-ok-button" [disabled]="closeDialogRequestedBy != null" *ngIf="hasOk()" type="button" class="btn s_btn-confirmation-cancel mr-2"   [ngClass]="getOkButtonClass()"  (click)="closeModal(ConfirmationDialogButton.Ok)">
        <div [innerHTML]="okBtnName ? okBtnName : 'OK'"></div>
        <div *ngIf="hasOkSubtext()" class="s_subtext">{{okBtnSubtext}}</div>
    </button>
    <button id="confirmation-dialog-cancel-button"  [disabled]="closeDialogRequestedBy != null" *ngIf="hasCancel()" type="button" class="btn s_btn-confirmation-cancel mr-2" [ngClass]="getCancelButtonClass()" (click)="closeModal(ConfirmationDialogButton.Cancel)">
        <div [innerHTML]="cancelBtnName ? cancelBtnName : 'CANCEL'"></div>
        <div *ngIf="hasCancelSubtext()" class="s_subtext">{{cancelBtnSubtext}}</div>
    </button>
    <button id="confirmation-dialog-aux-button"  [disabled]="closeDialogRequestedBy != null" *ngIf="hasAux1()" type="button" class="btn s_btn-confirmation-aux1 mr-2" [ngClass]="getAux1ButtonClass()" (click)="closeModal(ConfirmationDialogButton.Aux1)">
        <div [innerHTML]="aux1BtnName"></div>
        <div *ngIf="hasAux1Subtext()" class="s_subtext">{{aux1BtnSubtext}}</div>
    </button>
</div>
 
</div>
