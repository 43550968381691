/* eslint-disable @typescript-eslint/no-unsafe-call -- framework level so any/unsafe is OK  */
/* eslint-disable @typescript-eslint/no-unsafe-argument -- framework level so any/unsafe is OK  */
/* eslint-disable @typescript-eslint/no-unsafe-return -- framework level so any/unsafe is OK  */
/* eslint-disable @typescript-eslint/no-unsafe-member-access -- framework level so any/unsafe is OK */
/* eslint-disable @typescript-eslint/no-unsafe-assignment -- framework level so any/unsafe is OK */

import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AuthReduxObject,
  CrudOperationType,
  ErrorRetryBuilder,
  FileOperationInfo,
  FileOperationMetadata,
  FileOperationType,
  SafariObjectId,
  SafariReduxFileTransferObjectDefinition,
  reduxActionFail
} from '@safarilaw-webapp/shared/common-objects-models';

import { ErrorHandlerBase, ErrorMessageParserService } from '@safarilaw-webapp/shared/error-handling-message-parser';
import { FAILED_OBJECT_FILE, FailedObjectsService } from '@safarilaw-webapp/shared/failed-objects';
import { LoggerService } from '@safarilaw-webapp/shared/logging';

import { childError } from '@safarilaw-webapp/shared/utils';
import { Observable, of, throwError } from 'rxjs';
import { concatMap, delay, filter, map, take } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { FileSizeFormatService } from '../file-size-format.service';
import { Tus } from './tus-observable';

enum EndpointMethod {
  Post,
  Put,
  PutMetadataOnly
}

@Injectable({ providedIn: 'root' })
export class FileTransferService extends ErrorHandlerBase {
  constructor(
    private _failedObjectsService: FailedObjectsService,
    private _httpClient: HttpClient,
    private _store: Store<any>,
    errorParserService: ErrorMessageParserService,
    private _fileSizeFormatService: FileSizeFormatService,
    private _authRO: AuthReduxObject,
    private _tus: Tus,
    private _errorRetryBuilder: ErrorRetryBuilder,
    private _loggerService: LoggerService
  ) {
    super(errorParserService);
  }
  static FilesizeQueuing = -100;
  static FilesizeUnknownn = -10;
  private _getTusOrHttpClient(shouldUseTus: boolean) {
    if (shouldUseTus) {
      return this._getTusClient();
    }
    return this._getHttpClient();
  }
  private _getTusClient() {
    return this._tus;
  }
  private _getHttpClient() {
    return this._httpClient;
  }

  authErrorHandler(this: void, store: Store<any>, authRO: AuthReduxObject, error, caught) {
    store.dispatch(authRO.default.actions.refreshTokenForErrorHandler());
    return store.select(authRO.default.selectors.getAuthToken).pipe(
      // Note the delay followed by filter undefined.
      // When we dispatchRefreshTokenForErrorHandler the reducer will set token value to undefined
      // which will be followed by the effect requesting new token. We want to put a 0 delay though
      // to make sure that the reducer is done with setting to undefined, so we don't pull the old value).
      // After that filter will block until the new value is emitted
      delay(0),

      filter(o => o !== undefined),
      // IMPORTANT: Store select will be a long running observable that will clog
      // our rateLimit pipe if we don't release it. So we have to make sure we do take(1)
      // right at this spot
      take(1),
      concatMap(token => {
        if (token) {
          /*  Do not retry the old request, as it had a bad token. Instead, clone a new request with the new token and send it on


              NOTE:
              You might be wondering why we are doing this like this instead of just letting it be handled via http-interceptor
              like regular API calls get handled. The answer is - httpinterceptor will never get called because TUS doesn't use
              angular's HTTPClient but instead uses its' own protocol that it is completely unknown to the interceptor.
              Thus things like httphandler, cloning requests and calling .next() do not exist here.

              However, our way around that is simply returning "caught" which is basically the original observable whihc in turn
              is our observable wrapper around TUS. When that happens the observable will restart but because it's TUS-based it will
              use TUS functions to resume the upload, hence resulting in a seamless file upload even though a 401 happened during
              this time and even though a the observable was restarted (via caught)
          */

          return caught;
        } else {
          /* If the token renewal failed, login */
          store.dispatch(authRO.default.actions.login({ payload: null }));
          return throwError(() => error);
        }
      })
    );
  }

  private getUploadUpdatePipe(
    endpointMethod: EndpointMethod,
    url: string,
    file: File,
    id: string,
    fileNameOverride: string,
    fileTransferObject: SafariReduxFileTransferObjectDefinition,
    parentId: SafariObjectId,
    actionId: string,
    metadata: FileOperationMetadata,
    shouldUseTus,
    originalContent
  ) {
    actionId = actionId ? actionId : uuidv4();
    const formData: FormData = new FormData();
    let formDataAsObject = null;

    // File can be null if this is metadata-only update, so make sure to account for that
    const displayFilename = fileNameOverride || file == null ? fileNameOverride : file.name;
    const fileName = file == null ? displayFilename : file.name;
    const size = file == null ? 0 : file.size;
    if (endpointMethod != EndpointMethod.PutMetadataOnly) {
      let fileNameToAppendToForm = file.name;
      if (metadata != null && metadata.formValues != null && metadata.formValues.get('name') != null) {
        fileNameToAppendToForm = metadata.formValues.get('name');
      }
      formData.append('file', file, fileNameToAppendToForm);
    }
    if (metadata != null && metadata.formValues != null) {
      if (endpointMethod !== EndpointMethod.PutMetadataOnly) {
        // Non-metadata endpoints expect multipart form data
        for (const key of Array.from(metadata.formValues.keys())) {
          formData.append(key, metadata.formValues.get(key));
        }
      } else {
        // Metadata endpoint wants JSON object , not multipart form, so convert this map to object
        formDataAsObject = Array.from(metadata.formValues).reduce((obj, [key, value]) => {
          obj[key] = value;
          return obj;
        }, {});
      }
    }
    let percentComplete = 0;
    let apiCall: Observable<any> = null;
    // eslint-disable-next-line @typescript-eslint/naming-convention -- header name
    const ngswBypass = { 'ngsw-bypass': 'true' };
    const headers =
      metadata != null && metadata.etag != null
        ? // eslint-disable-next-line @typescript-eslint/naming-convention -- header name
          new HttpHeaders({ 'If-Match': metadata.etag, ...ngswBypass })
        : new HttpHeaders({
            ...ngswBypass
          });

    if (endpointMethod === EndpointMethod.Put) {
      apiCall = this._getHttpClient().put(url, formData, {
        reportProgress: true,
        observe: 'events',
        headers
      });
    } else if (endpointMethod === EndpointMethod.PutMetadataOnly) {
      apiCall = this._getHttpClient().put(url, formDataAsObject, {
        reportProgress: true,
        observe: 'events',
        headers
      });
    } else if (endpointMethod === EndpointMethod.Post) {
      const postHeaders = this._getTusClient().applyAuthorizationHeader(headers);
      apiCall = this._getTusOrHttpClient(shouldUseTus).post<any>(url, formData, {
        reportProgress: true,
        observe: 'events',
        headers: postHeaders
      });
      if (shouldUseTus) {
        apiCall = apiCall.pipe(this._errorRetryBuilder.getRetryWhenLogic(3), this._errorRetryBuilder.getCatchErrorLogic(postHeaders, this.authErrorHandler, this._store, this._authRO));
      }
    }
    return apiCall.pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.Sent:
            this._store.dispatch(
              fileTransferObject.default.actions.updateFileUploadProgress({
                payload: {
                  secondsUntilTransferDialogShown: 0,
                  displayFilename,
                  downloadLink: null,
                  fileName,
                  id,
                  percentComplete: 0,
                  actionId,
                  message: 'Pending...',
                  isError: false,
                  fileOperationType: FileOperationType.Add,
                  parentId,
                  totalProcessed: 0,
                  metadata,
                  totalSize: size,
                  isMetadataUpdate: endpointMethod === EndpointMethod.PutMetadataOnly
                } as FileOperationInfo
              })
            );
            return { status: 'progress', message: '' };

          case HttpEventType.UploadProgress:
            percentComplete = Math.round((100 * event.loaded) / event.total);
            // keep real "percent done" 5 pct less. "5" is arbitrary
            // but the point is to keep it under 100. That's because 100 is used by dialog component
            // to tell that the file has been uploaded, however the upload hasn't truly finalized until
            // after HttpEventType.Response is sent (Technically the file has been transferred - hence we would
            // receive 100 in this event, but the API hasn't closed it yet and hasn't sent us the confirmation).
            // Therefore weird things could happen if we let this go to 100 - just keep it under 100 by whatever
            // amount and all will be good.
            percentComplete = percentComplete > 5 ? percentComplete - 5 : percentComplete;
            this._store.dispatch(
              fileTransferObject.default.actions.updateFileUploadProgress({
                payload: {
                  secondsUntilTransferDialogShown: 0,
                  displayFilename,
                  downloadLink: null,
                  fileName,
                  id,
                  percentComplete,
                  actionId,

                  message: 'Uploading ( ' + percentComplete.toString() + '% )',
                  isError: false,
                  fileOperationType: FileOperationType.Add,
                  parentId,
                  totalProcessed: event.loaded,
                  metadata,
                  totalSize: event.total,
                  isMetadataUpdate: endpointMethod === EndpointMethod.PutMetadataOnly
                } as FileOperationInfo
              })
            );
            return { status: 'progress', message: percentComplete };

          case HttpEventType.Response:
            percentComplete = 100;
            this._store.dispatch(
              fileTransferObject.default.actions.processFileSuccess({
                payload: {
                  secondsUntilTransferDialogShown: 0,
                  displayFilename,
                  downloadLink: null,
                  fileName,
                  id,
                  percentComplete,
                  actionId,
                  message: 'Uploaded',
                  apiResponse: { body: event.body, status: event.status, statusText: event.statusText },
                  isError: false,
                  fileOperationType: FileOperationType.Add,
                  parentId,
                  totalProcessed: size,
                  metadata,
                  totalSize: size,
                  isMetadataUpdate: endpointMethod === EndpointMethod.PutMetadataOnly
                } as FileOperationInfo
              })
            );
            return event;
          default:
            return `Unhandled event: ${event.type as string}`;
        }
      }),

      childError(
        this._failedObjectsService,
        this._loggerService,
        CrudOperationType.Create,
        FAILED_OBJECT_FILE,
        originalContent || {
          file,
          parentId,
          metadata,
          id,
          actionId
        },
        true
      )
    );
  }
  private _handleDownloadError(error) {
    this.loadHeaders(error);
    this._store.dispatch(reduxActionFail({ originalPayload: {}, payload: {}, error, reduxErrorOptions: { source: 'FileTransferService._handleDownloadError', silent: true } }));
    return of({ error });
  }
  protected getDownloadHeaders(additionalHeaders = {}) {
    return new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention -- header name
      ...{ 'Content-Type': 'application/json' },
      ...additionalHeaders
    });
  }

  public download(
    url: string,
    id: string,
    displayFilename: string,
    fileTransferObject: SafariReduxFileTransferObjectDefinition,
    actionId: string,
    secondsUntilTransferDialogShown: number,
    isPreview: boolean,
    customHeaders: { [key: string]: string } = {}
  ): any {
    let percentComplete = 0;
    let sizeKnown = true;
    let downloadedSoFar = '0 B';
    // eslint-disable-next-line @typescript-eslint/naming-convention -- header name
    const ngswBypass = { 'ngsw-bypass': 'true' };
    const headers = new HttpHeaders({ ...ngswBypass, ...customHeaders });

    return this._getHttpClient()
      .get(url, {
        headers,
        reportProgress: true,
        observe: 'events',
        responseType: 'blob'
      })
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.DownloadProgress:
              percentComplete = Math.round((100 * event.loaded) / event.total);
              if (isNaN(percentComplete)) {
                // If this blew up then it's because total wasn't known
                // P.S. Do not try reusing "percentComplete" variable. This variable
                // has a special meaning to file upload/download window (closing on 100, etc) and trying
                // to stuff KB there will not work. Instead we'll use a separate variable that is only
                // used to render the message
                sizeKnown = false;
                percentComplete = FileTransferService.FilesizeUnknownn;
                downloadedSoFar = this._fileSizeFormatService.format(event.loaded, 2);
              }
              this._store.dispatch(
                fileTransferObject.default.actions.updateFileDownloadProgress({
                  payload: {
                    secondsUntilTransferDialogShown,
                    displayFilename,
                    downloadLink: null,
                    fileName: displayFilename,
                    id,
                    percentComplete,
                    actionId,

                    message: sizeKnown ? 'Downloading ( ' + percentComplete.toString() + '% )' : 'Downloaded ' + downloadedSoFar + ' of (size unknown)',
                    isError: false,
                    fileOperationType: FileOperationType.Download,
                    isPreview,
                    totalProcessed: event.loaded,
                    totalSize: event.total,
                    parentId: null
                  } as FileOperationInfo
                })
              );
              return event;
            case HttpEventType.Response:
              percentComplete = 100;
              this._store.dispatch(
                fileTransferObject.default.actions.updateFileDownloadProgress({
                  payload: {
                    secondsUntilTransferDialogShown,
                    displayFilename,
                    downloadLink: null,
                    fileName: displayFilename,
                    id,
                    percentComplete,
                    actionId,
                    message: 'Downloaded',
                    isError: false,
                    fileOperationType: FileOperationType.Download,
                    isPreview,
                    parentId: null
                  } as FileOperationInfo
                })
              );
              return event;
            default:
              return event;
          }
        })
      );
  }
  public upload(
    url: string,
    file: File,
    id: string,
    fileNameOverride: string,
    fileUploadActions: SafariReduxFileTransferObjectDefinition,
    parentId: SafariObjectId,
    actionId: string,
    metadata: FileOperationMetadata = null,
    shouldUseTus: boolean,
    originalContent
  ): Observable<any> {
    return this.getUploadUpdatePipe(EndpointMethod.Post, url, file, id, fileNameOverride, fileUploadActions, parentId, actionId, metadata, shouldUseTus, originalContent);
  }
  public update(
    url: string,
    file: File,
    id: string,
    fileNameOverride: string,
    fileUploadActions: any,
    parentId: SafariObjectId,
    actionId: string,
    metadata: FileOperationMetadata = null,
    shouldUseTus: boolean,
    originalContent: any
  ): Observable<any> {
    // If we're calling update but not sending a file then this must mean we are just updating the metadata
    return this.getUploadUpdatePipe(
      file != null ? EndpointMethod.Put : EndpointMethod.PutMetadataOnly,
      url,
      file,
      id,
      fileNameOverride,
      fileUploadActions,
      parentId,
      actionId,
      metadata,
      shouldUseTus,
      originalContent
    );
  }
}
export class FileTransferProgressEvent {
  id: string;
  fileName: string;
  percentComplete: number;
  message: string;
  isError: boolean;
}
