import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileSizeFormatService {
  private readonly kilobyte = 1024;
  private readonly megabyte = this.kilobyte * 1024;
  private readonly gigabyte = this.megabyte * 1024;

  constructor() {}
  format(bytes: number, decimals = 0, fixedUnitSpacing: boolean = false) {
    bytes = +bytes || 0;
    if (bytes == 0) {
      return '';
    }
    if (bytes < this.kilobyte) {
      return bytes.toFixed(0) + (fixedUnitSpacing ? ' ' : '') + ' B';
    }
    if (bytes < this.megabyte) {
      return (bytes / this.kilobyte).toFixed(decimals) + ' KB';
    }
    if (bytes < this.gigabyte) {
      return (bytes / this.megabyte).toFixed(decimals) + ' MB';
    }
    return (bytes / this.gigabyte).toFixed(decimals) + ' GB';
  }
}
