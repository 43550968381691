export class CrudServiceParams {
  orderBy?: string;
  skip?: number;
  top?: number;
  additionalFilters?: Map<string, string | string[] | number | number[]>;
}
export enum AutoRetrieveOnUpdate {
  /**
   * None means exactly that. Everything will stay exactly the same
   */
  None,
  /**
   * ETagOnly will read etag header from PUT response and will merge it with the original payload
   * So the resulting object will be exactly what was sent but with a new etag. This is probably going
   * to be the most likely option to use
   */
  EtagOnly,
  /**
   * Will take both ETAG and response body and will return that. At the time of writing this there is no
   * PUT method in the API I m aware of that actually returns BODY, so this is untested
   */
  Response,
  /**
   * Original "autoRetrieve" - it will call retrieve endpoint right after succesful PUT. Use this if you
   * want to get the latest object, not just ETAG (Usually you'd do this if you need some other properties
   * that are calculated/added on the API side after PUT). Since we usually redirect to list page or do a full
   * page reload after save this is not going to be used too much (although currently it IS the default)
   */
  Reload
}
