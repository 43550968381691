import { Inject, Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CrudGenericService, FileTransferService } from '@safarilaw-webapp/shared/crud';
import { AppConfigurationService } from '@safarilaw-webapp/shared/environment';
import { ErrorMessageParserService } from '@safarilaw-webapp/shared/error-handling-message-parser';
import { FailedObjectsService } from '@safarilaw-webapp/shared/failed-objects';
import { ObjectFileTransferService } from '@safarilaw-webapp/shared/file-upload';
import { LoggerService } from '@safarilaw-webapp/shared/logging';

import { COMANAGE_FILE_TRANSFER_OBJECT } from '../../../feature-co-manage-data-access.module';
import { FileOperationUploadRequest, SafariReduxFileTransferObjectDefinition } from '@safarilaw-webapp/shared/common-objects-models';

@Injectable({
  providedIn: 'root'
})
export class CompanyFileTransferService extends ObjectFileTransferService {
  constructor(
    errorParserService: ErrorMessageParserService,
    crudGenericService: CrudGenericService,
    fileTransferService: FileTransferService,
    appConfig: AppConfigurationService,
    store: Store<any>,
    actions: Actions,
    @Inject(COMANAGE_FILE_TRANSFER_OBJECT) fileTransferObject: SafariReduxFileTransferObjectDefinition,
    failedObjectsService: FailedObjectsService,
    loggerService: LoggerService
  ) {
    super(errorParserService, crudGenericService, fileTransferService, appConfig, store, actions, fileTransferObject, failedObjectsService, loggerService);
  }
  protected get apiEndpoint(): string {
    return this._appConfig.urls.apiRootCoManage;
  }
  getUploadUrl(payload: FileOperationUploadRequest) {
    return this.apiEndpoint + 'company/logo';
  }
  shouldUseTus(payload: FileOperationUploadRequest): boolean {
    return false;
  }
}
