export enum SafariHotkey {
  Null,
  Save,
  Close
}

import { Injectable } from '@angular/core';
import { createFeatureSelector, createSelector, on, props } from '@ngrx/store';
import { IdName } from '@safarilaw-webapp/shared/common-objects-models';
import { ReduxBoolean, SafariReduxUiObject, reduxActionFail } from '@safarilaw-webapp/shared/redux';
import { IErrorState } from '../interfaces/error-interface';
import { IUiState } from '../interfaces/ui-interface';
export interface ISharedState {
  errorState: IErrorState;
  uiState: IUiState;
}
export class PageTitleInfo {
  title: string;
  useCompanyLabel: boolean;
  useCompanyLogo: boolean;
}
const getSharedFeatureState = createFeatureSelector<ISharedState>('appState');

const getErrorState = createSelector(getSharedFeatureState, state => state.errorState);
const getUiState = createSelector(getSharedFeatureState, state => state.uiState);
@Injectable({ providedIn: 'root' })
export class AppErrorReduxObject extends SafariReduxUiObject<IErrorState, any> {
  // TODO: Follow example in matterpage redux object to combine into action/selector and get rid of default
  default = {
    selectors: {
      getlastError: createSelector(getErrorState, state => state.lastError)
    },
    reducers: ons => {
      ons.push(
        on(reduxActionFail, (state: IErrorState, action) => ({
          ...state,
          lastError: {
            error: action.error,
            mustResolve: action.reduxErrorOptions?.mustResolve,
            url: action.reduxErrorOptions.url,
            source: action.reduxErrorOptions?.source,
            payload: action.payload,
            silent: action.reduxErrorOptions?.silent,
            uiOption: action.reduxErrorOptions?.uiOption,
            maxValidationErrors: action.reduxErrorOptions?.maxValidationErrors
          }
        }))
      );
    }
  };
  constructor() {
    super('App', 'Ui');
    super.addState({ lastError: null }).finalize();
  }
}
@Injectable({ providedIn: 'root' })
export class AppUiReduxObject extends SafariReduxUiObject<IUiState, any> {
  // TODO: Follow example in matterpage redux object to combine into action/selector and get rid of default
  default = {
    actions: {
      toggleBlockUi: super.createAction('Toggle Block UI', props<{ payload: { value: boolean; transparent?: boolean } }>()),

      setShowLoaderOverlay: super.createAction('Set Loader Overlay', props<{ payload: ReduxBoolean }>()),
      toggleNavbar: super.createAction('Toggle Navbar', props<{ payload: ReduxBoolean }>()),
      toggleNavbarActive: super.createAction('Toggle Navbar Active'),
      toggleHeader: super.createAction('Toggle Header', props<{ payload: ReduxBoolean }>()),
      setIsDialog: super.createAction('Set Is Dialog', props<{ payload: ReduxBoolean }>()),
      setBackgroundImageClass: super.createAction('Set Background Image', props<{ payload: string }>()),
      allowNavigation: super.createAction('Allow Navigation', props<{ payload: ReduxBoolean }>()),
      setPageTitle: super.createAction('Set Page Title', props<{ payload: string | PageTitleInfo }>()),
      setCompanyLogoUrl: super.createAction('Set Company Logo Url', props<{ payload: string }>()),
      setCompanyLabel: super.createAction('Set Company Label', props<{ payload: string }>()),
      setShowGlobalSearch: super.createAction('Set Show Global Search', props<{ payload: ReduxBoolean }>()),
      toggleHotkey: super.createAction('Toggle Hotkey', props<{ payload: SafariHotkey }>()),
      setNavbarItemBadges: super.createAction('Set Navbar Item Badges', props<{ payload: { itemBadges: IdName[] } }>())
    },
    selectors: {
      getBlockUi: createSelector(getUiState, state => state.blockUi),
      getShowLoaderOverlay: createSelector(getUiState, state => state.showLoaderOverlay),
      getShowNavbar: createSelector(getUiState, state => state.showNavbar.value),
      getShowHeader: createSelector(getUiState, state => state.showHeader.value),
      getIsDialog: createSelector(getUiState, state => state.isDialog.value),
      getBackgroundImageClass: createSelector(getUiState, state => state.backgroundImageClass),
      getPageTitle: createSelector(getUiState, state => state.pageTitle),
      getUseCompanyLogo: createSelector(getUiState, state => state.useCompanyLogo),
      getUseCompanyLabel: createSelector(getUiState, state => state.useCompanyLabel),
      getShowGlobalSearch: createSelector(getUiState, state => state.showGlobalSearch),
      getCompanyLogoUrl: createSelector(getUiState, state => state.companyLogoUrl),
      getCompanyLabel: createSelector(getUiState, state => state.companyLabel),
      getHotkey: createSelector(getUiState, state => state.hotkey),

      getNavbarActive: createSelector(getUiState, state => state.navbar.active.value),

      getIsNavigationAllowed: createSelector(getUiState, state => state.navbar.allowNavigation.value),

      getitemBadges: createSelector(getUiState, state => state.navbar.itemBadges)
    },
    reducers: ons => {
      ons.push(
        on(this.default.actions.toggleBlockUi, (state: IUiState, action) => ({ ...state, blockUi: action.payload })),
        on(this.default.actions.setShowLoaderOverlay, (state: IUiState, action) => ({ ...state, showLoaderOverlay: action.payload })),
        on(this.default.actions.toggleNavbar, (state: IUiState, action) => ({ ...state, showNavbar: action.payload })),
        on(this.default.actions.toggleNavbarActive, (state: IUiState, action) => {
          const active: ReduxBoolean = {
            value: !state.navbar.active.value
          };
          return {
            ...state,
            navbar: {
              ...state.navbar,
              ...{ active }
            }
          };
        }),

        on(this.default.actions.toggleHeader, (state: IUiState, action) => ({ ...state, showHeader: action.payload })),
        on(this.default.actions.setIsDialog, (state: IUiState, action) => ({ ...state, isDialog: action.payload })),

        on(this.default.actions.setBackgroundImageClass, (state: IUiState, action) => ({ ...state, backgroundImageClass: action.payload })),
        on(this.default.actions.toggleHotkey, (state: IUiState, action) => ({ ...state, hotkey: { value: action.payload } })),

        on(this.default.actions.allowNavigation, (state: IUiState, action) => ({ ...state, allowNavigation: action.payload })),

        on(this.default.actions.setPageTitle, (state: IUiState, action) => {
          const isPageTitleObject = Object.prototype.hasOwnProperty.call(action.payload, 'useCompanyLabel');
          const pageTitle = isPageTitleObject ? (action.payload as PageTitleInfo).title : (action.payload as string);
          const useCompanyLabel = isPageTitleObject ? (action.payload as PageTitleInfo).useCompanyLabel : false;
          const useCompanyLogo = isPageTitleObject ? (action.payload as PageTitleInfo).useCompanyLogo : false;
          return { ...state, pageTitle, useCompanyLabel, useCompanyLogo };
        }),
        on(this.default.actions.setCompanyLogoUrl, (state: IUiState, action) => ({ ...state, companyLogoUrl: action.payload })),
        on(this.default.actions.setCompanyLabel, (state: IUiState, action) => ({ ...state, companyLabel: action.payload })),
        on(this.default.actions.setShowGlobalSearch, (state: IUiState, action) => ({ ...state, showGlobalSearch: action.payload })),
        on(this.default.actions.setNavbarItemBadges, (state: IUiState, action) => ({
          ...state,
          navbar: {
            ...state.navbar,
            ...{ itemBadges: [...action.payload.itemBadges] }
          }
        }))
      );
    }
  };

  constructor() {
    super('App', 'Ui');
    super
      .addState({
        blockUi: { value: false, transparent: false },
        showLoaderOverlay: { value: true },
        showHeader: { value: true },
        showNavbar: { value: true },
        isDialog: { value: false },
        backgroundImageClass: String(''),
        hotkey: { value: SafariHotkey.Null },

        pageTitle: String(''),
        useCompanyLabel: false,
        useCompanyLogo: false,
        companyLogoUrl: '',
        companyLabel: '',
        showGlobalSearch: { value: false },

        navbar: {
          allowNavigation: { value: true },
          itemBadges: [],
          active: { value: false }
        }
      })
      .finalize();
  }
}
