import { Actions } from '@ngrx/effects';

import { Store } from '@ngrx/store';
import { SafariInjector } from '@safarilaw-webapp/shared/common-objects-models';
import { dataAccessMixin } from '@safarilaw-webapp/shared/redux';
import { Observable, of } from 'rxjs';
import { NavbarGroup } from '../../models/navbar-group';
import { NavbarHeader } from '../../models/navbar-header';
import { NavbarItem } from '../../models/navbar-item';
import { NavbarPermissionsService } from '../navbar-permissions/navbar-permissions.service';

/**
 * Base for navbar service.
 * DO NOT forget to provide it in the app
 */

export class NavbarService extends dataAccessMixin(SafariInjector) {
  private _appAccessService: NavbarPermissionsService;
  protected _store: Store<any>;
  protected _actions: Actions;

  protected navbarCollection: (NavbarGroup | NavbarHeader | NavbarItem)[] = [];
  constructor() {
    super();
    this._appAccessService = this.inject(NavbarPermissionsService);
    this._store = this.inject(Store);
    this._actions = this.inject(Actions);
  }
  isNavbarItem(obj: any): obj is NavbarItem {
    if (obj instanceof NavbarItem) {
      return true;
    }
    return false;
  }
  allPermissionsValid(permissionFunctions: string[]): boolean {
    if (!permissionFunctions) {
      return true;
    }
    for (const pf of permissionFunctions) {
      const permissionFunctionAndParams = pf.split('|');
      const permissionFunction = permissionFunctionAndParams[0];
      const permissionFunctionParams = permissionFunctionAndParams[1];
      let permissionFunctionParamsArray = [];
      if (permissionFunctionParams != null) {
        permissionFunctionParamsArray = permissionFunctionParams.split(':');
      }
      const negativeCheck = permissionFunction.startsWith('!');
      if (!negativeCheck) {
        if (!this._appAccessService[permissionFunction.replace('!', '')](...permissionFunctionParamsArray)) {
          return false;
        }
      } else {
        if (this._appAccessService[permissionFunction.replace('!', '')](...permissionFunctionParamsArray)) {
          return false;
        }
      }
    }
    return true;
  }
  // filters the entire navbar collection (groups, headers and items)
  filterNavbarCollectionByPermissions(collection: (NavbarGroup | NavbarHeader | NavbarItem)[], items: (NavbarGroup | NavbarHeader | NavbarItem)[]) {
    collection.forEach(element => {
      if (this.allPermissionsValid(element.permissionFunction)) {
        // we don't want to add items whose parent may have been excluded by the permission check
        if (this.isNavbarItem(element)) {
          if (items.find(o => o.id === element.parentId)) {
            items.push(element);
          }
        } else {
          items.push(element);
        }
      }
    });
  }

  isAuthenticated() {
    return this._appAccessService.isAuthenticated();
  }

  // setup method is used by the App to initialize the array.  Each app adds their own items to it.
  setupNavbarItems(items: (NavbarGroup | NavbarHeader | NavbarItem)[]) {
    this.navbarCollection = items;
  }
  // get method is called by the Navbar component when it is ready to be displayed.
  getNavbarItems(): Observable<(NavbarGroup | NavbarHeader | NavbarItem)[]> {
    return of(null);
  }
}
