import { CommonModule } from '@angular/common';
import { InjectionToken, NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffect } from './auth/effects/auth/effect';

import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { AuthReducerService, IAuthState } from '@safarilaw-webapp/shared/common-objects-models';

const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<IAuthState>>('Auth  Reducers');

@NgModule({
  imports: [CommonModule, RouterModule, StoreModule.forFeature('auth', REDUCER_TOKEN), EffectsModule.forFeature([AuthEffect])],
  providers: [
    {
      provide: REDUCER_TOKEN,
      deps: [AuthReducerService],
      useFactory: (service: AuthReducerService) => service.reducers
    }
  ]
})
export class SharedAuthModule {}
