import { Injectable } from '@angular/core';
import { ApiDropdownAdapter } from '@safarilaw-webapp/shared/crud';
import { DropdownStateList } from '../models/api/dropdown-state-list';
import { DropdownState } from '../models/app/state';

@Injectable({
  providedIn: 'root'
})
export class DropdownStateAdapter extends ApiDropdownAdapter {
  fromListModel(model: DropdownStateList): Required<DropdownState> {
    return {
      ...super.fromListModel(model),
      abbreviation: model.abbreviation,
      country: model.country,
      countryName: model.countryName
    };
  }
}
