import { Injectable } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { UI_REDUCER } from '@safarilaw-webapp/shared/redux';
import { AppDialogUiReduxObject } from './actions/dialog-actions';
import { ISharedState } from './state.interface';

@Injectable({
  providedIn: 'root'
})
export class DialogReducerService {
  constructor(private _appDialogUiReduxObject: AppDialogUiReduxObject) {}
  get reducers() {
    const reducers: ActionReducerMap<ISharedState> = {
      dialogState: UI_REDUCER(this._appDialogUiReduxObject)
    };
    return reducers;
  }
}
