/* eslint-disable @typescript-eslint/no-unsafe-call -- framework level so any/unsafe is OK  */
/* eslint-disable @typescript-eslint/no-unsafe-argument -- framework level so any/unsafe is OK  */
/* eslint-disable @typescript-eslint/no-unsafe-return -- framework level so any/unsafe is OK  */
/* eslint-disable @typescript-eslint/no-unsafe-member-access -- framework level so any/unsafe is OK */
/* eslint-disable @typescript-eslint/no-unsafe-assignment -- framework level so any/unsafe is OK */
// Initial stub wrapper for TUS uploader. Probably doesn't work right now but
// once we have API working we can continue working on this to replace current POC code

import { HttpErrorResponse, HttpEvent, HttpEventType, HttpHeaders, HttpParams, HttpProgressEvent, HttpResponse, HttpSentEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import {
  AuthReduxObject,
  HTTP_STATUS_CODE_API_FORBIDDEN,
  HTTP_STATUS_CODE_API_NOTAUTHORIZED,
  HTTP_STATUS_CODE_API_TOOMANY,
  HTTP_STATUS_CODE_WEB_TUS_CLIENT_ERROR
} from '@safarilaw-webapp/shared/common-objects-models';
import { LoggerService } from '@safarilaw-webapp/shared/logging';

import { Observable } from 'rxjs';
import * as tus from 'tus-js-client';
// with something that looks similar to the current httpclient.post calls.
@Injectable({
  providedIn: 'root'
})
export class Tus {
  constructor(
    private _store: Store<any>,
    private _authRO: AuthReduxObject,
    private _loggerService: LoggerService
  ) {}
  private _getAuthString(): string {
    let accessToken = '';

    this._store.select(this._authRO.default.selectors.getAuthToken).subscribe(o => (accessToken = o));

    let authString: string;

    if (accessToken) {
      authString = `Bearer ${accessToken.toString()}`;
    } else {
      // This should never happen - if it did we'd already be getting "cannot read length" from NG
      // due to headers being undefined
      authString = 'N-A';
    }
    return authString;
  }
  applyAuthorizationHeader(headers: HttpHeaders): HttpHeaders {
    return headers.set('Authorization', this._getAuthString());
  }
  post<T>(
    url: string,
    formData: any | null,
    options: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe: 'events';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<HttpEvent<T>> {
    let completed = false;
    const file = formData.get('file');
    const chunkSize = 50 * 1024 * 1024; // 50mb;
    const parallelUploads = 1;
    // TODO: Ucomment this once we figure it out.
    // if (file.size > chunkSize) {
    //   parallelUploads = Math.min(Math.floor(file.size / chunkSize), 4);
    // }

    const metadata: any = Array.from(formData).reduce((obj, [key, value]) => {
      if (key !== 'file') {
        obj[key] = value;
      }
      return obj;
    }, {});

    const headersWithAuth = this.applyAuthorizationHeader(options.headers as HttpHeaders);
    const headers = Array.from(headersWithAuth.keys()).reduce((obj, key) => {
      obj[key] = headersWithAuth.get(key);
      return obj;
    }, {});
    metadata['name'] = file.name;
    metadata['contentType'] = file.type != null && file.type.length > 0 ? file.type : 'application/octet-stream';

    return new Observable(observer => {
      let upload = new tus.Upload(formData.get('file'), {
        // uploadDataDuringCreation: true,
        addRequestId: true,
        removeFingerprintOnSuccess: true,
        // Endpoint is the upload creation URL from your tus server
        endpoint: url,
        // Retry delays will enable tus-js-client to automatically retry on errors
        retryDelays: [0, 1000, 5000],
        headers,
        chunkSize,
        parallelUploads,
        // Attach additional meta data about the file for the server
        metadata,
        // Callback for errors which cannot be fixed using retries

        onError: (error: any) => {
          const status = error.originalResponse != null ? error.originalResponse.getStatus() : error;
          const noMessage = 'Internal Error';
          // Don't waste time parsing and catching if there was no originalResponse to begin with
          // Note: From what I've seen if originalResponse is here then it's some type of network
          // error, API TUS error, etc.
          if (error.originalResponse != null) {
            let body = null;
            try {
              // First we'll try to parse the message body - if it's a regular httpresponse error
              // this will succeed
              body = JSON.parse(error.originalResponse.getBody());
            } catch {
              // At this point the body is not really a body but probably just a plain string
              // We'll use that in the message...

              body = {
                message: error.originalResponse.getBody() || noMessage // <-- not sure that the latter can happen
              };
            }
            const httpError = new HttpErrorResponse({ url, status, statusText: 'Tus web error', error: { ...body } });
            observer.error(httpError);
            return;
          }
          // If we get here then there was no originalResponse. What that means is that something
          // failed in tus client before it even went out. This will result in a message starting
          // with 'tus: ' and having responseText and responseCode 'n/a'
          const message = error.message || noMessage; // <-- not sure that the latter can happen

          // Just return original tus error
          observer.error(new HttpErrorResponse({ url, status: HTTP_STATUS_CODE_WEB_TUS_CLIENT_ERROR, statusText: 'Tus client error', error: { message } }));
        },
        // Callback for reporting upload progress

        onProgress: (bytesUploaded, bytesTotal) => {
          if (options.reportProgress) {
            observer.next({ type: HttpEventType.UploadProgress, loaded: bytesUploaded, total: bytesTotal } as HttpProgressEvent);
          }
        },

        // Callback for once the upload is completed
        onSuccess: () => {
          completed = true;
          observer.next({ type: HttpEventType.Response } as HttpResponse<T>);
          observer.complete();
        },
        onShouldRetry: (err, retryAttempt, retryOptions) => {
          // Sometimes the error may be just a status code with no other context. Default to just the err param, whatever it may be
          // so we can check status value below.
          let status = err;
          let body;
          if (err.originalResponse) {
            try {
              status = err.originalResponse.getStatus();
            } catch (e) {
              this._loggerService.trackEvent('TusRetry - Failed to getStatus', e);
            }
            try {
              body = err.originalResponse.getBody();
            } catch (e) {
              this._loggerService.trackEvent('TusRetry - Failed to getBody', e);
            }
          }
          // Don't blow up trying to redact
          try {
            if (typeof err === 'object') {
              const regex = new RegExp(/"Bearer [^"]+("|$)/g); // Remove Bearer tokens from the logged data
              const replacement = JSON.parse(JSON.stringify(err).replace(regex, '"REDACTED"'));
              err = replacement;
            }
          } catch (redactionException) {
            this._loggerService.trackEvent('TusRetry - Failed to redact', redactionException);
          }
          this._loggerService.trackEvent('TusRetry', { body, err, status });
          // Do not retry these
          if (status == HTTP_STATUS_CODE_API_FORBIDDEN || status == HTTP_STATUS_CODE_API_NOTAUTHORIZED || status == HTTP_STATUS_CODE_API_TOOMANY) {
            // Also set "completed" flag so we don't call abort when the observable
            // restarts due to these errors
            completed = true;
            return false;
          }

          // For any other status code, we retry.
          return true;
        }
      } as any);
      // This will be called if either subscription is ended via calling complete OR it was cancelled
      observer.add(() => {
        // don't call abort on completed uploads. Weird things will happen
        // Also if not completed make sure to catch and report the error to the observer
        // rather than blowing up
        if (!completed) {
          upload.abort(true).catch(error => {
            observer.error(error);
          });
          // not sure if this is needed but let's do it anyway
          upload = null;
        }
      });

      upload.options.headers['Authorization'] = this._getAuthString();

      upload
        .findPreviousUploads()
        .then(previousUploads => {
          // set completed to false when we re-enter.
          completed = false;
          if (previousUploads.length > 0) {
            upload.resumeFromPreviousUpload(previousUploads[0]);
          }
          upload.start();
        })
        .catch(err => {
          this._loggerService.LogError(err, window.location.href);
        });

      observer.next({ type: HttpEventType.Sent } as HttpSentEvent);
    });
  }
}
