import { Injectable } from '@angular/core';
import { createFeatureSelector, createSelector, on, props } from '@ngrx/store';
import { SafariReduxObject } from '@safarilaw-webapp/shared/redux';

import { IRouterFeatureState, IRouterState, RouterNavigationInfo, RouterRouteChangeInfo } from '@safarilaw-webapp/shared/common-objects-models';

export const getFeatureState = createFeatureSelector<IRouterFeatureState>('navigation');

@Injectable({
  providedIn: 'root'
})
export class SafariRouterReduxObject extends SafariReduxObject<IRouterState, any> {
  private _defaultState: IRouterState = null;

  // TODO: Follow example in matterpage redux object to combine into action/selector and get rid of default
  default = {
    actions: {
      clearState: super.createAction('Clear State'),
      setState: super.createAction('Set State', props<{ payload: RouterNavigationInfo }>()),
      routerNavigate: super.createAction('Navigate', props<{ payload: RouterNavigationInfo }>()),
      routerForward: super.createAction('Forward'),
      routerBack: super.createAction('Back'),
      routerRouteChange: super.createAction('Route Change', props<{ payload: RouterRouteChangeInfo }>())
    },
    selectors: {
      routerState: () => createSelector(getFeatureState, (state: IRouterFeatureState) => state.routerState),
      checkDirty: () => createSelector(getFeatureState, (state: IRouterFeatureState) => (state.routerState.routerNavigationInfo == null ? true : state.routerState.routerNavigationInfo.checkDirty))
    },
    reducers: ons => {
      ons.push(
        on(this.default.actions.setState, (state: IRouterState, action) => {
          // This call should only be used by appcomponent.ts to set state when navigation is done directly by clicking on href
          if (state.routerNavigationInfo != null) {
            return { ...state };
          }
          return { ...state, routerNavigationInfo: { ...action.payload } };
        }),
        on(this.default.actions.clearState, (state: IRouterState, action) => ({ ...state, routerNavigationInfo: null })),
        on(this.default.actions.routerNavigate, (state: IRouterState, action) => {
          if (action.payload == null) {
            return { ...state };
          }
          if (action.payload.ignoreIfNavigationPending) {
            if (state.routerNavigationInfo != null) {
              return { ...state };
            }
          }
          return { ...state, routerNavigationInfo: { ...action.payload } };
        })
      );
    }
  };

  addState(defaultState: IRouterState) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- we can't say for sure what the state will look like
    this._defaultState = defaultState;

    return this;
  }

  constructor() {
    super('Navigation', 'Router');
    this.addState({ routerNavigationInfo: null }).finalize();
  }
}
