import { Injectable, ViewContainerRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HeaderComponentInjector {
  injectionSlot1Added = false;
  injectionSlot1: ViewContainerRef;

  constructor() {}

  registerInjectionSlot1(viewContainerRef: ViewContainerRef) {
    // Just in case - if somehow null is passed in , don't do anything
    if (viewContainerRef == null) {
      return;
    }
    this.injectionSlot1 = viewContainerRef;
  }
  clearInjectionSlot1() {
    if (this.injectionSlot1) {
      this.injectionSlot1.clear();
      this.injectionSlot1 = null;
      this.injectionSlot1Added = false;
    }
  }
  addDynamicComponent(component) {
    if (this.injectionSlot1 && component && !this.injectionSlot1Added) {
      this.injectionSlot1Added = true;
      this.injectionSlot1.createComponent(component);
    }
  }
}
