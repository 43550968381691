export {};

declare global {
  interface Array<T> {
    equals(str: Array<T>): boolean;
    distinct(keySelector?: (o: T) => any): Array<T>;
    // eslint-disable-next-line id-blacklist -- extension of array
    any(predicate?: (o: T) => boolean): boolean;
    all(predicate: (o: T) => boolean): boolean;
    ssort(selector?: (o: T) => any, comparer?: (a: any, b: any) => number);
  }
}

Array.prototype.distinct = function <T>(keySelector?: (o: T) => any) {
  if (!keySelector) {
    return this.filter((val, idx, arr) => arr.indexOf(val) === idx);
  } else {
    return this.filter((val, idx, arr) => {
      const keyVal = keySelector(val);
      const keyValIndex = arr.findIndex(o => keySelector(o) === keyVal);
      return idx === keyValIndex;
    });
  }
};

// eslint-disable-next-line id-blacklist -- extension of array
Array.prototype.any = function <T>(predicate?: (o: T) => boolean): boolean {
  if (!predicate) {
    return this.length > 0;
  }
  return this.find(predicate) !== undefined;
};

Array.prototype.all = function <T>(predicate: (o: T) => boolean): boolean {
  for (const item of this) {
    if (!predicate(item)) {
      return false;
    }
  }
  return true;
};

Array.prototype.equals = function (array) {
  // if the other array is a falsy value, return
  if (!array) {
    return false;
  }

  // compare lengths - can save a lot of time
  if (this.length != array.length) {
    return false;
  }

  for (let i = 0, l = this.length; i < l; i++) {
    // Check if we have nested arrays
    if (this[i] instanceof Array && array[i] instanceof Array) {
      // recurse into the nested arrays
      if (!this[i].equals(array[i])) {
        return false;
      }
    } else if (this[i] != array[i]) {
      // Warning - two different object instances will never be equal: {x:20} != {x:20}
      return false;
    }
  }
  return true;
};

Array.prototype.ssort = function (selector?: (o: any) => any, comparer?: (a: any, b: any) => number) {
  let compareFn;
  if (typeof selector === 'function') {
    if (typeof comparer !== 'function') {
      comparer = (a, b) => {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      };
    }

    compareFn = (a: any, b: any) => {
      const aSelected = selector(a);
      const bSelected = selector(b);
      return comparer(aSelected, bSelected);
    };
  } else {
    compareFn = comparer;
  }
  return this.sort(compareFn);
};
// Hide method from for-in loops
Object.defineProperty(Array.prototype, 'equals', { enumerable: false });
