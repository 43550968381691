import { createFeatureSelector } from '@ngrx/store';
import { IdName } from '@safarilaw-webapp/shared/common-objects-models';
import { IDropdownState, IFileUploadState, ISafariObjectState } from '@safarilaw-webapp/shared/redux';
import { User } from '../co-user/models/app/user';
import { CompanySettings } from '../company-settings/models/app/company-settings';
import { Company } from '../company/models/app/company';
import { InboundEmailSetting } from '../inbound-email-setting/models/app/inbound-email-setting';
import { NotificationPreference } from '../notification-preferences/models/app/notification-preference';
import { RegisteredAgentSettings } from '../registered-agent-settings/models/app/registered-agent-settings';
import { Team } from '../team/models/app/team';
import { MyCompanies } from '../my-companies/models/app/my-companies';

export interface ICoManageStateDropdown extends IDropdownState {
  countries: IdName[];
  states: IdName[];
  timezones: IdName[];
  userpermissions: IdName[];
  applications: IdName[];
  informationRequestTypes: IdName[];
}

export interface ICoManageState {
  companyCombinedState: {
    companyState: ISafariObjectState<Company>;
    companySettingsState: ISafariObjectState<CompanySettings>;
    inboundEmailSettingState: ISafariObjectState<InboundEmailSetting>;
    registeredAgentSettingsState: ISafariObjectState<RegisteredAgentSettings>;
  };
  myCombinedState: {
    userState: ISafariObjectState<User>;
    companiesState: ISafariObjectState<MyCompanies>;
  };
  userCombinedState: {
    userState: ISafariObjectState<User>;
  };
  dropdownCombinedState: {
    dropdownState: ICoManageStateDropdown;
  };
  notificationPreferenceCombinedState: {
    notificationPreferenceState: ISafariObjectState<NotificationPreference>;
  };

  teamCombinedState: {
    teamState: ISafariObjectState<Team>;
  };
  fileCombinedState: {
    fileState: IFileUploadState;
  };
}

export const FEATURE_STATE = 'data-access-co-manage';
export const getSharedFeatureState = createFeatureSelector<ICoManageState>(FEATURE_STATE);

export const PROJECT_NAME = 'CoManage';
