import { InjectOptions, ProviderToken, inject } from '@angular/core';
import { MixinConstructor } from '../mixin/mixin-base';

export const safariInjectorMixin = <B extends MixinConstructor>(Base: B) =>
  class SafariInjectorMixin extends Base {
    /**
   * This function wraps angular's inject function and is the only way we should be injecting
   * in smart components. 
   * 
   * To Mock: 
   * 
   * jest.spyOn(SafariSmartComponent.prototype, 'inject').mockImplementation() 
   * 
   * OR (if desiring to do partial injections)
   * 
   * jest.spyOn(SafariSmartComponent.prototype, 'inject').mockImplementation((token: ProviderToken<any>) => {
      
      if (token == Store || token == Actions) {
        return TestBed.inject(token); <-- assumes those are provided in the testbed
      }
      if (token == FeatureFlagsService) {
        return {
          ...somefakeclass <-- return some fake value
        }
      }
      return null;
    });
   * @param token Same as angular's inject function
   * @returns Same as angular's inject function
   */
    inject<T>(token: ProviderToken<T>, options: InjectOptions = { optional: false }): T {
      // eslint-disable-next-line no-restricted-syntax -- framework-level so it's ok
      return inject(token, options);
    }
  };

export class SafariInjector extends safariInjectorMixin(class {}) {}
