import { Injectable } from '@angular/core';
import { ApiDropdownAdapter } from '@safarilaw-webapp/shared/crud';
import { DropdownMatterField } from '../models/app/dropdown-matter-field';

@Injectable({
  providedIn: 'root'
})
export class DropdownMatterFieldAdapter extends ApiDropdownAdapter {
  fromListModel(model: any): Required<DropdownMatterField> {
    return {
      ...super.fromListModel(model),
      fieldType: model.fieldType,
      fieldTypeName: model.fieldTypeName,
      matterChild: model.matterChild,
      matterTypes: model.matterTypes,
      picklistType: model.picklistType,
      section: model.section,
      sectionName: model.sectionName,
      maxLength: model.maxLength
    };
  }
}
