import { Injectable } from '@angular/core';
import { ApiDataAdapter } from '@safarilaw-webapp/shared/crud';
import { NotificationPreferenceList } from '../models/api/notification-preference-list';
import { NotificationPreference } from '../models/app/notification-preference';

@Injectable({ providedIn: 'root' })
export class NotificationPreferenceAdapter extends ApiDataAdapter<NotificationPreference> {
  toUpdateListModel(o: NotificationPreference) {
    return {
      type: o.type,
      deliveryMethods: o.deliveryMethods
    };
  }
  fromListModel(o: NotificationPreferenceList): NotificationPreference {
    const retVal: NotificationPreference = {
      id: o.type.toString(),
      deliveryMethods: [...o.deliveryMethods],
      type: o.type
    } as NotificationPreference;

    return retVal;
  }
}
