import { InjectionToken, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationDialogComponent } from './dialog/components/confirmation-dialog/confirmation-dialog.component';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { DialogReducerService } from './state/state.reducer';
import { ISharedState } from './state/state.interface';
import { ReactiveFormsModule } from '@angular/forms';
const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<ISharedState>>('Dialog State Reducers');

@NgModule({
  imports: [StoreModule.forFeature('dialogs', REDUCER_TOKEN), CommonModule, ReactiveFormsModule],
  declarations: [ConfirmationDialogComponent],
  exports: [ConfirmationDialogComponent],
  providers: [
    {
      provide: REDUCER_TOKEN,
      deps: [DialogReducerService],
      useFactory: (service: DialogReducerService) => service.reducers
    }
  ]
})
export class SharedDialogModule {}
