import { HttpErrorResponse } from '@angular/common/http';
import { ErrorMessageParserService } from '../services/error-message-parser/error-message-parser.service';

export class ErrorHandlerBase {
  constructor(protected _errorParserService: ErrorMessageParserService) {}
  protected loadHeaders(error: HttpErrorResponse) {
    // This is very annoying - angular's headers are lazy loaded meaning they won't be loaded until
    // accessed. Since we serialize this via redux, that later gets read by error handler service
    // IF we don't "ping" headers they will not be serialized and we won't get them in our error handler.
    // Hence the seemingly usesless call to headers.keys
    if (error instanceof HttpErrorResponse && typeof error?.headers?.keys == 'function') {
      error.headers.keys();
    }
  }
  getErrorMessage(o: any, suppressUnknownErrors = false) {
    return this._errorParserService.getErrorMessageFromErrorObject(o, suppressUnknownErrors);
  }
}
