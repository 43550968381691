<div class="wrapper common-error-page">
    <!-- MAIN -->
    <main  >
        <div class="container-fluid my-3">
            <div class="justify-content-center">
                <div class="col-md-12 text-center">
                <h3>Your computer is having trouble reaching our website</h3>
                </div>
            </div>
            <div class="col-md-12 text-left">
                <div class="my-2">Please follow the steps below to troubleshoot:</div>
                <ul class="my-2">
                    <li>Use a supported browser: Make sure you are using the current version of a modern browser (Microsoft Edge, Chrome, or Firefox are recommended).</li>
                    <li>Add our website to trusted sites: Request that your IT group add our domains to trusted sites:<br>
                       <b>{{gatewayTimeoutUrls}}</b>
                    </li>
                    <li>
                        Turn off VPN: Depending on how your VPN is configured, it could prevent you from accessing the site. If on a VPN, turn off the VPN and then access the portal
                    </li>
                    <li>

                        Check Browser extensions: Sometimes, browser extensions will block your access to the portal. Try disabling any extensions that restrict access to websites to see if you can now access the portal.
                    </li>
                </ul>
            </div>
            <div class="col-md-12 text-left mt-5">
                <h5>Technical Details</h5>
                <div>
                    <hr>
                    <div class="row">
                        <div class="col-sm-2">Url:</div><div class="col-sm-4"><b>{{ errorObject?.originalError?.url }}</b> </div>
                    </div>
                    <div class="row my-2">
                        <div class="col-sm-2">Status Code:</div><div class="col-sm-4"><b>{{ errorObject?.originalError?.status }}</b> </div>
                    </div>
 
                <hr>
                </div>
              
            </div>
        </div>        
    </main>
</div><!-- END: wrapper -->