// Make sure these are strings and not numbers
// This will ensure that they will never collide with app roles from API
export enum UserPermissionType {
  Admin = '1',
  Creator = '2',
  Editor = '3',
  Viewer = '4',
  Uploader = '5',
  Owner = '6',
  // These are "special" values that are not passed as roles in the api,
  // but we can use these in things like PermissionGuardService when defining route restrictions
  // They will be translated by userpermissionsservice
  Self = '9997',
  GlobalMatterAccess = '9998',
  Coordinator = '9999'
}
