import { ChangeDetectorRef, Directive, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { User } from '@safarilaw-webapp/feature/co-manage/data-access';
import { AuthService } from '@safarilaw-webapp/shared/auth';
import { SafariInjector } from '@safarilaw-webapp/shared/common-objects-models';
import { FeatureFlagsService } from '@safarilaw-webapp/shared/environment';
import { LoggerService } from '@safarilaw-webapp/shared/logging';
import { dataAccessMixin } from '@safarilaw-webapp/shared/redux';
import { HeaderComponentInjector } from '@safarilaw-webapp/shared/utils';
import { Observable } from 'rxjs';
import { AppUiReduxObject } from '../../../state/actions/ui-actions';

/**
 * Base for header component.
 * DO NOT forget to declare the child in app.module of the app
 */
@Directive({
  // navbar component inherits from dataAccessMixin that doesn't have any decorators
  // but this component also implements OnDestroy which apparently makes ivy think it
  // needs a selector for some reason, so here it is....
  // eslint-disable-next-line @angular-eslint/directive-selector -- comments above
  selector: 'make-angular-happy'
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix -- ignore NG suggestions
export class HeaderComponent extends dataAccessMixin(SafariInjector) implements OnInit, OnDestroy {
  title = '';
  // Header HTML also appends "name" to the user so we'll include it here
  currentUserInfo$: Observable<User & { name?: string }>;
  navbarActive$: Observable<boolean>;
  protected cdr: ChangeDetectorRef;
  private _vcr: ViewContainerRef;
  @ViewChild('viewContainerRef', { read: ViewContainerRef }) set viewContainerRef(vcr: ViewContainerRef) {
    this._vcr = vcr;
    this._componentInjector.registerInjectionSlot1(this._vcr);
  }
  protected _featureFlagsService: FeatureFlagsService;
  protected _store: Store<any>;
  protected _actions: Actions<any>;
  public _authService: AuthService;
  protected _logger: LoggerService;
  protected _appUiReduxObject: AppUiReduxObject;

  private _componentInjector: HeaderComponentInjector;

  constructor() {
    super();
    this._featureFlagsService = this.inject(FeatureFlagsService);
    this._store = this.inject(Store);
    this._actions = this.inject(Actions);
    this._authService = this.inject(AuthService);
    this._logger = this.inject(LoggerService);
    this._appUiReduxObject = this.inject(AppUiReduxObject);
    this._componentInjector = this.inject(HeaderComponentInjector);
    this.cdr = this.inject(ChangeDetectorRef);
  }

  isAuthenticated() {
    return this._authService.isAuthenticated();
  }
  ngOnDestroy() {
    this._componentInjector.clearInjectionSlot1();
  }
  ngOnInit() {
    this._store.pipe(select(this._appUiReduxObject.default.selectors.getPageTitle)).subscribe(pageTitle => {
      this.title = pageTitle;
      this.cdr.detectChanges();
    });

    this.navbarActive$ = this._store.select(this._appUiReduxObject.default.selectors.getNavbarActive);
  }
  toggleSidebar() {
    this._store.dispatch(this._appUiReduxObject.default.actions.toggleNavbarActive());
  }

  helpCenterClicked() {
    this._logger.trackEvent('LPMS Help Center clicked from main page');
  }
  liveTrainingClicked() {
    this._logger.trackEvent('Live Training clicked from main page');
  }
  get showChangeCompanyMenuItem(): boolean {
    return this._authService.hasMultipleCompanies;
  }
}
