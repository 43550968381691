import { Observable } from 'rxjs';

import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SafariInjector } from '@safarilaw-webapp/shared/common-objects-models';
import { dataAccessMixin } from '@safarilaw-webapp/shared/redux';

export class UserVerificationService extends dataAccessMixin(SafariInjector) {
  constructor() {
    super();

    this._actions = this.inject(Actions);
    this._store = this.inject(Store);
  }

  /** Simple check for whether the system recognizes the user associated with the auth token */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- Shared service
  checkForUser(email?: string): Observable<boolean> {
    throw new Error('checkForUserNotImplemented');
  }

  /** Simple check for whether the user has multiple company access */
  checkForCompanies(): Observable<boolean> {
    throw new Error('checkForUser NotImplemented');
  }

  // TODO: Maybe we'll have some interface instead of any, if we find that companies between apps share some similarity
  getCompanies(): Observable<any[]> {
    throw new Error('getCompanies NotImplemented');
  }
}
