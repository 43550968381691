import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthReduxObject, ErrorRetryBuilder, HTTP_STATUS_CODE_API_NOTAUTHORIZED } from '@safarilaw-webapp/shared/common-objects-models';
import { AppConfigurationService } from '@safarilaw-webapp/shared/environment';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, delay, filter, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class HttpErrorInterceptorService implements HttpInterceptor {
  constructor(private _appConfig: AppConfigurationService, private _errorRetryBuilder: ErrorRetryBuilder, private _authRO: AuthReduxObject, private _store: Store<any>) {}

  authErrorHandler(this: void, request: HttpRequest<any>, next: HttpHandler, store: Store<any>, authRO: AuthReduxObject, appConfigService: AppConfigurationService, error, $caught) {
    store.dispatch(authRO.default.actions.refreshTokenForErrorHandler());
    return store.select(authRO.default.selectors.getAuthToken).pipe(
      // Note the delay followed by filter undefined.
      // When we dispatchRefreshTokenForErrorHandler the reducer will set token value to undefined
      // which will be followed by the effect requesting new token. We want to put a 0 delay though
      // to make sure that the reducer is done with setting to undefined, so we don't pull the old value).
      // After that filter will block until the new value is emitted
      delay(0),
      filter(o => o !== undefined),
      // IMPORTANT: Store select will be a long running observable that will clog
      // our rateLimit pipe if we don't release it. So we have to make sure we do take(1)
      // right at this spot
      take(1),
      concatMap((token: string) => {
        let updatedRequest = request;
        if (token) {
          /* Do not retry the old request, as it had a bad token. Instead, clone a new request with the new token and send it on */
          updatedRequest = updatedRequest.clone({
            headers: updatedRequest.headers.set('Authorization', `Bearer ${token}`)
          });
          return next.handle(updatedRequest).pipe(
            // Make sure to handle the case where even second call to cloned request failed.
            // Not sure why/how that would happen but if it did go to login page
            catchError(err => {
              // If we hit another 401 not much we can do - go back to login page
              if (err.status == HTTP_STATUS_CODE_API_NOTAUTHORIZED) {
                // Actually there are some exceptions to the above. There are calls we make that can not be resolved
                // with auth0. Those need to be excluded and throw the error instead, otherwise we'll end up in a loop
                if (appConfigService.statusApp?.apiRootUrl && request.url.startsWith(appConfigService.statusApp?.apiRootUrl)) {
                  return throwError(() => err);
                }
                store.dispatch(authRO.default.actions.login({ payload: null }));
                // Also return an empty observable instead of throwError so it doesn't spam with random errors
                // The fact that it doesn't do anything shouldn't matter because it will redirect anyway in login() call above
                return of(EMPTY);
              } else {
                return throwError(() => err);
              }
            })
          );
        } else {
          store.dispatch(authRO.default.actions.login({ payload: { redirectTargetRoute: window.location.pathname + window.location.search, startTimer: false } }));

          // Also return an empty observable instead of throwError so it doesn't spam with random errors
          // The fact that it doesn't do anything shouldn't matter because it will redirect anyway in login() call above
          return of(EMPTY);
        }
      })
    );
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /* In case config hasn't loaded yet, use a reasonable default */
    const retries: number = (this._appConfig && this._appConfig.errors && this._appConfig.errors.numRetries) || 3;
    const handleResult$ = next.handle(request).pipe(
      // After we call handle we first pipe the standard retry logic which will use standard backoff logic
      this._errorRetryBuilder.getRetryWhenLogic(retries),
      // And after this we pipe the function that returns catchError logic that handles AUTH errors for this interceptor
      // The first two params are always static - headers and the function. Remaining params
      // are expanded by catchErrorHandler and only have meaning inside of it
      this._errorRetryBuilder.getCatchErrorLogic(request.headers, this.authErrorHandler, request, next, this._store, this._authRO, this._appConfig)
    );

    return handleResult$ as any;
  }
}
