// Based on https://github.com/angular/angular/blob/main/aio/content/examples/service-worker-getting-started/src/app/log-update.service.ts

import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { AppUpdateSplashService } from '../../splash/services/app-update-splash.service';

// #docregion sw-update
@Injectable({
  providedIn: 'root'
})
export class SwHandleUpdateService {
  private _logErrorToProd(errorEvent) {
    // This is a bit of a hack. We are reusing the same code we have in index.html to issue raw elmah requests.
    // This is because we want to avoid injection of logger service which contains appinsights and which in turn
    // causes delayed isStable emission. We want SW to boot up as soon as possible and we already have the workaround written
    // around appinsights and auth (init.ts) so we don't want to undo that by injecting logger service here.
    const hostname = window.location.hostname.toLowerCase();
    const safariLawHostname = 'app.safarilaw.com';
    const serveportHostname = 'app.serveport.com';
    let prodLogId = '';
    if (hostname.startsWith(safariLawHostname)) {
      prodLogId = '378fb795-45ad-4a9d-9299-53df21a047d6';
    } else if (hostname.startsWith(serveportHostname)) {
      prodLogId = '3866225d-479d-4694-ba82-471c763c47d1';
    }

    const prodApiKey = '752d3d5a800541cd8dd1b5d747cc188a';

    if (prodLogId) {
      const xhr = new XMLHttpRequest();
      const url = 'https://api.elmah.io/v3/messages/' + prodLogId + '?api_key=' + prodApiKey;
      xhr.open('POST', url, true);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      const payload = {
        application: '', // Don't want to bother creating overrides of this service just to add appname. We should be able to conclude if from the error itself (should contain URL)
        title: 'Service worker update error',
        type: 'Error',
        severity: 'Error',
        detail: errorEvent
      };
      xhr.send(JSON.stringify(payload));
    }
  }

  constructor(updates: SwUpdate, appUpdateSplashService: AppUpdateSplashService) {
    window['swError'] = '';
    // NG examples (from which this was taken) don't check for isEnabled. That still works fine but
    // causes annoying network errors (404 on ngsw-json) in dev mode in console
    if (!updates.isEnabled) {
      return;
    }
    let location = '';
    updates.versionUpdates.subscribe(evt => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          window['swError'] = '';
          // As soon as we detect the new version we will save the location
          // This will be used later in VERSION_READY to redirect to that location.
          // We need to do this because between here and VERSION_READY the URL might already
          // change to /init
          // PS - It'd be really nice if instead we could simply signal end$ from either
          // VERSION_READY or NO_NEW_VERSION (via that AppSplashService) and then have the
          // code wait for that instead isStable. Unfortunately that would only work if SW is enabled.
          // It would cause permanent block for non-SW environment as there is no good , reliable
          // way for us to conslusively tell that there is no SW present (and hence we couldn't
          // broadcast end$ reliably in case when SW is not present)
          // We do have some UI code in QA/CI that checks for navigator.service but that's good enough
          // only for non-critical dev code. navigator.service could be NULL when SW exists but hasn't
          // spun up yet, so it wouldn't be reliable for something like this.
          // This seems to work fine however...
          location = window.location.href;
          appUpdateSplashService.start();
          // eslint-disable-next-line no-console -- We want to keep this console.log
          console.log(`SW Update: Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          window['swError'] = '';
          // eslint-disable-next-line no-console -- We want to keep this console.log
          console.log(`SW Update: Current app version: ${evt.currentVersion.hash}`);
          // eslint-disable-next-line no-console -- We want to keep this console.log
          console.log(`SW Update: New app version ready for use: ${evt.latestVersion.hash}`);
          updates
            .activateUpdate()
            .then(() => setTimeout(() => (location ? (window.location.href = location) : document.location.reload()), 1000))
            .catch(() => {});
          break;
        case 'VERSION_INSTALLATION_FAILED':
          // NOTE: If we want to re-enable logging to prod, we can do it here by doing the following:
          // try { this._logErrorToProd(evt.error) } catch {}

          // eslint-disable-next-line no-console -- We want to keep this console.log
          console.log(`SW Update: Failed to install app version '${evt.version.hash}': ${evt.error}`);
          // Dont want to use any clever NG interaction here. Just a simple window global object. app-update-splash will pick it up
          window['swError'] = evt.error;
          break;
      }
    });
  }
}
// #enddocregion sw-update
