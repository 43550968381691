import { v4 as uuidv4 } from 'uuid';

export abstract class ObjectHelper {
  static isNew(o: any): boolean {
    if (typeof o == 'string') {
      return o == '0';
    }
    return !Object.prototype.hasOwnProperty.call(o, '__etag');
  }
  static getNewId(): string {
    return uuidv4();
  }
}
