<div *ngIf="loggedOut" class="s_bg-logout common-error-page">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-12 text-center">
        <h4 class="s_header2 pt-5">
          You have succesfully logged out of the system. 
        </h4>
      </div>
    </div>
  </div>
</div>