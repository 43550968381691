import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Optional } from '@angular/core';
import { IdName } from '@safarilaw-webapp/shared/common-objects-models';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiDropdownAdapter } from '../../adapter/adapter';
import { Collection, CrudServiceBase } from '../crud-orchestrator/crud-base.service';

// This should probably inherit from crudservicebase ?
export class DropdownService {
  constructor(
    private _httpClient: HttpClient,
    protected _endpoint: string,
    @Optional() private _adapter: ApiDropdownAdapter = null
  ) {}
  private mapCollection(o: { items: IdName[] }, url: string): IdName[] {
    if (!this._adapter) {
      return o.items;
    } else {
      // eslint-disable-next-line @typescript-eslint/naming-convention -- special param
      return o.items.map(x => this._adapter.fromListModel(x, { __url: url }));
    }
  }

  protected getHeaders(additionalHeaders = {}) {
    return new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention -- header name
      ...{ 'Content-Type': 'application/json', Accept: 'application/json' },
      ...additionalHeaders
    });
  }
  protected getRetrieveUrl(url: string, baseEndpoint: string = null) {
    const endpoint = baseEndpoint == null ? this._endpoint : baseEndpoint;
    return endpoint + url;
  }
  retrieveByUrl(url: string, baseEndpoint: string = null): Observable<IdName[]> {
    url = this.getRetrieveUrl(url, baseEndpoint);

    return this._httpClient.get<any>(url, { observe: 'response', headers: this.getHeaders() }).pipe(
      map((o: HttpResponse<Collection<IdName>>) => {
        // TODO: When caching is renabled add result to localstorage service
        if (o.body == null) {
          const headers = CrudServiceBase.getHeadersAsString(o.headers);
          throw new Error(`BODY NULL ON SUCCESSFUL RESPONSE! URL: ${o.url || ''}, STATUS: ${o.status || ''}, STATUSTEXT: ${o.statusText || ''}, HEADERS: ${JSON.stringify(headers)}`);
        }
        return this.mapCollection(o.body, url);
      })
    );
  }
  retrieveInBulk(url: string): Observable<
    {
      type: string;
      values: IdName[];
    }[]
  > {
    url = this.getRetrieveUrl(url, null);

    return this._httpClient.get<any>(url, { observe: 'response', headers: this.getHeaders() }).pipe(
      map((o: HttpResponse<Collection<{ type: string; values: IdName[] }>>) => {
        // TODO: When caching is renabled add result to localstorage service
        if (o.body == null) {
          const headers = CrudServiceBase.getHeadersAsString(o.headers);
          throw new Error(`BODY NULL ON SUCCESSFUL RESPONSE! URL: ${o.url || ''}, STATUS: ${o.status || ''}, STATUSTEXT: ${o.statusText || ''}, HEADERS: ${JSON.stringify(headers)}`);
        }

        const returnValue: { type: string; values: IdName[] }[] = [];
        for (const dropdownTypeAndValues of o.body.items) {
          returnValue.push({
            type: dropdownTypeAndValues.type,
            values: this.mapCollection({ items: dropdownTypeAndValues.values }, 'picklists/' + dropdownTypeAndValues.type)
          });
        }
        return returnValue;
      })
    );
  }
}
