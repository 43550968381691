// Based on https://github.com/angular/angular/blob/main/aio/content/examples/service-worker-getting-started/src/app/check-for-update.service.ts

import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { AppConfigurationService } from '@safarilaw-webapp/shared/environment';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SwCheckForUpdateService {
  constructor(appRef: ApplicationRef, updates: SwUpdate, private _appConfigService: AppConfigurationService) {
    // NG examples (from which this was taken) don't check for isEnabled. That still works fine but
    // causes annoying network errors (404 on ngsw-json) in dev mode in console
    if (!updates.isEnabled) {
      return;
    }
    // Allow the app to stabilize first, before starting
    // polling for updates with `interval()`.
    const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));

    const updatePollFrequency = this._appConfigService?.uiSettings?.updatePollFrequency || 3600;
    // eslint-disable-next-line no-console -- We want to keep this console.log
    console.log('SW Update: Setting polling frequency to', updatePollFrequency);
    const timedPoll$ = interval(updatePollFrequency * 1000);
    const timedPollOnceAppIsStable$ = concat(appIsStable$, timedPoll$);

    // This will poll on the very first stable and only once, then after that on interval as defined above
    timedPollOnceAppIsStable$.subscribe(() => {
      // eslint-disable-next-line no-console -- We want to keep this console.log
      console.log('SW Update: Checking For Update');
      updates.checkForUpdate().catch(() => {});
    });
  }
}
