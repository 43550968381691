/* eslint-disable @typescript-eslint/no-unsafe-argument  -- ok for this file, a lot of arbitrary values possible */
/* eslint-disable @typescript-eslint/adjacent-overload-signatures  -- ok for this file, a lot of arbitrary values possible */
/* eslint-disable @typescript-eslint/no-unsafe-return -- ok for this file, a lot of arbitrary values possible */
/* eslint-disable @typescript-eslint/no-unsafe-call -- ok for this file, a lot of arbitrary values possible */
/* eslint-disable @typescript-eslint/no-unsafe-member-access -- ok for this file, a lot of arbitrary values possible */
/* eslint-disable @typescript-eslint/no-unsafe-argument -- ok for this file, a lot of arbitrary values possible */
/* eslint-disable @typescript-eslint/no-unsafe-assignment -- ok for this file, a lot of arbitrary values possible */
import { Actions, ofType } from '@ngrx/effects';
import { Action, DefaultProjectorFn, MemoizedSelector, Store, select } from '@ngrx/store';

import { CrudOperationType, CrudServiceParams, IdName, MixinConstructor, SafariObject, SafariObjectId } from '@safarilaw-webapp/shared/common-objects-models';

import { Observable, Subscription, combineLatest, distinctUntilChanged, filter, mergeMap, of, race, take, tap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import {
  ActionErrorBase,
  ActionOptions,
  DeleteMultipleObjectsPayload,
  LoadObjectActionFailInfo,
  LoadObjectActionInfo,
  LoadObjectListActionInfo,
  UpdateObjectListActionFailInfo,
  UpdateOrCreateActionFailInfo,
  UpdateOrCreateActionInfo
} from '../models/object';
import { ReduxDataAccessObject } from '../models/redux-data-access-object';
import { ISafariObjectState, SafariObjectList } from '../state/interface';
import { ActionExtensions } from '../state/reducer';
import { IReduxProvider, SafariReduxDropdownObject, SafariReduxSearchDefinition } from '../state/redux-generator';
import { SelectorState } from '../state/selectors/enums';

export class ActionOverrides {
  actionId?: string;
  options?: ActionOptions;
}

// Right now this looks exactly the same as SafariObjectListId but eventually SafariObjectId will become string
// again (with comma separated representing hierarchy). However for loadObject/list, etc we'll still allow
// requesting via array of ids
class SafariObjectListIdForDataAccessMixin {
  id?: SafariObjectId;
  filter?: CrudServiceParams;
}
export const abortOnError =
  () =>
  <T>(source: Observable<T>): Observable<T> =>
    new Observable(subscriber => {
      const subscription = source.subscribe({
        next: value => {
          // We are going to pipe this to either updateObject or fileUpload
          // objects get 'error' property appended if there is API failure
          // 'errors' if form validation error
          // and files have hadErrors (api error) or wasCancelled (user clicked cancel) property
          // We'll need to simplify these names at the very least to 'errors' and 'wasCancelled'
          if (value['error'] || value['errors'] || value['hadErrors'] || value['wasCancelled']) {
            subscriber.error(new AbortSave());
          } else {
            subscriber.next(value);
          }
        },
        error: error => {
          subscriber.error(error);
        },
        complete: () => {
          subscriber.complete();
        }
      });

      return () => subscription.unsubscribe();
    });
export class AbortSave {
  constructor(public returnValue: any = null) {}
}

/**
 * NOTE: All functions are public. This is to make TS happy which requires mixins to not
 * have protected/private functions. Strangely it works most of the time even with protected/private
 * but every once in a while it will cause compile or LINT errors on some components.
 *
 * At some point we might want to take a look into typescript-mix or some other similar npm
 * lib that supposedly helps overcome these restrictions
 */

type ObserveObjectOptions =
  | {
      filterNull: boolean;
      selectorState: SelectorState[];
    }
  | boolean
  | number;

export const dataAccessMixin = <B extends MixinConstructor>(Base: B) =>
  class DataAccessMixin extends Base {
    protected _actions: Actions<unknown>;
    protected _store: Store<unknown>;

    protected _searchReduxObject: SafariReduxSearchDefinition<any>;
    protected _pendingLoadListActions: Map<IReduxProvider<any, any>, any> = new Map<IReduxProvider<any, any>, any>();
    protected _pendingLoadObjectActions: Map<IReduxProvider<any, any>, any> = new Map<IReduxProvider<any, any>, any>();
    protected _pendingLoadObjectBlockUiActions: Map<IReduxProvider<any, any>, any> = new Map<IReduxProvider<any, any>, any>();
    protected _pendingDeleteObjectActions: Map<IReduxProvider<any, any>, any> = new Map<IReduxProvider<any, any>, any>();
    protected _pendingCreateUpdateObjectActions: Map<IReduxProvider<any, any>, any> = new Map<IReduxProvider<any, any>, any>();
    protected _pendingUpdateObjectListActions: Map<IReduxProvider<any, any>, any> = new Map<IReduxProvider<any, any>, any>();

    protected _loadListCompleteSubscriptions: Map<string | IReduxProvider<any, any>, Subscription> = new Map<string, Subscription>();
    protected _loadObjectCompleteSubscriptions: Map<string | IReduxProvider<any, any>, Subscription> = new Map<string, Subscription>();
    protected _updateObjectCompleteSubscriptions: Map<string | IReduxProvider<any, any>, Subscription> = new Map<string, Subscription>();
    protected _updateObjectListCompleteSubscriptions: Map<string | IReduxProvider<any, any>, Subscription> = new Map<string, Subscription>();
    protected _deleteObjectCompleteSubscriptions: Map<string | IReduxProvider<any, any>, Subscription> = new Map<string, Subscription>();

    protected _reduxDataAccessObjects: ReduxDataAccessObject[] = [];

    _addactionIdToActionOverrides<T>(overrides: UpdateOrCreateActionInfo<T> = null) {
      const actionId = overrides?.actionId || uuidv4();
      if (overrides == null) {
        overrides = {
          actionId
        };
      } else {
        overrides.actionId = actionId;
      }
      return overrides;
    }
    addToPendingListAction(list: Map<IReduxProvider<any, any>, any>, ctr, actionId) {
      let current = list.get(ctr);
      if (current == null) {
        list.set(ctr, []);
        current = list.get(ctr);
      }
      current.push(actionId);
    }
    removeFromPendingListAction(list: Map<IReduxProvider<any, any>, any>, ctr, actionId) {
      const current = list.get(ctr);
      if (current == null) {
        return;
      }
      const index = current.indexOf(actionId);
      if (index !== -1) {
        current.splice(index, 1);
      }
      if (current.length === 0) {
        list.delete(ctr);
      }
    }
    addToPendingLoadListAction(ctr, actionId) {
      this.addToPendingListAction(this._pendingLoadListActions, ctr, actionId);
    }
    addToPendingUpdateObjectListAction(ctr, actionId) {
      this.addToPendingListAction(this._pendingUpdateObjectListActions, ctr, actionId);
    }
    addToPendingLoadObjectAction(ctr, actionId) {
      this.addToPendingListAction(this._pendingLoadObjectActions, ctr, actionId);
    }
    addToPendingCreateUpdateObjectAction(ctr, actionId) {
      this.addToPendingListAction(this._pendingCreateUpdateObjectActions, ctr, actionId);
    }

    addToPendingDeleteObjectAction(ctr, actionId) {
      this.addToPendingListAction(this._pendingDeleteObjectActions, ctr, actionId);
    }
    removeFromPendingLoadListAction(ctr, actionId) {
      this.removeFromPendingListAction(this._pendingLoadListActions, ctr, actionId);
    }
    removeFromPendingLoadObjectAction(ctr, actionId) {
      this.removeFromPendingListAction(this._pendingLoadObjectActions, ctr, actionId);
    }

    removeFromPendingDeleteObjectAction(ctr, actionId) {
      this.removeFromPendingListAction(this._pendingDeleteObjectActions, ctr, actionId);
    }
    removeFromPendingCreateOrUpdateObjectAction(ctr, actionId) {
      this.removeFromPendingListAction(this._pendingCreateUpdateObjectActions, ctr, actionId);
    }
    removeFromPendingUpdateObjectListAction(ctr, actionId) {
      this.removeFromPendingListAction(this._pendingUpdateObjectListActions, ctr, actionId);
    }

    /**
     * @description This function lets us observe any standard data access object
     * It has three different overloads, as follows:
     *
     * 1. Loads object from its current state. This is the default version and is used
     *    most of the time. This overload allows you to also pass whether you want to
     *    filter nulls or not. Default is yes since we usually wait for the object to load
     *    Usually you call this like
     *
     *    this.observeObject$(this.CoManageObject.User, '12345') - blocks on nulls
     *
     *    OR
     *
     *    this.observeObject$(this.CoManageObject.User, '12345', false) - emits nulls as well
     * 2. Loads object from the state passed in. This function supports an extra parameter
     *    that decides whether you want to filterNulls or no.
     *
     *    this.observeObject$(this.CoManageObject.User, '12345', SelectorState.Failed) -
     *          - This only gets from the failed state , and blocks on nulls
     *
     *    OR
     *
     *    this.observeObject$(this.CoManageObject.User, '12345', SelectorState.Failed, false) - emits nulls as well
     *          - This only gets from the failed state , but also emits nulls
     *
     * 3. This overload is used for some more advanced scenarios, where we want to get any of the states that emit.
     *    You would usually use it like :
     *
     *    this.observeObject$(this.CoManageObject.User, {
     *        filterNulls: true,
     *        selectorStates: [SelectorState.Failed, SelectorState.Current, SelectorState.Saving]
     *    }) - blocks on nulls ONLY IF all 3 are null, otherwise it returns the one that emitted
     *
     *      this.observeObject$(this.CoManageObject.User, {
     *        filterNulls: false,
     *        selectorStates: [SelectorState.Failed, SelectorState.Current, SelectorState.Saving]
     *    }) - No null filtering
     *
     *    This will return whichever one of the three states emit first. Technically the order matters
     *    but in reality it shouldn't as the reducer always plucks the object from one state
     *    before putting it into another
     *
     *
     * @param object
     * @param id
     * @param options
     * @returns
     */
    observeObject$<T extends SafariObject>(
      object: IReduxProvider<ISafariObjectState<T>, T>,
      id: SafariObjectId,
      options: ObserveObjectOptions = true,
      selectorOptionFilterNull: boolean = true
    ): Observable<T & ActionExtensions> {
      const allSelectors: MemoizedSelector<object, T, DefaultProjectorFn<T>>[] = [];
      allSelectors[SelectorState.Current] = object.default.selectors.objectInCurrentState(SafariObject.id(id));
      allSelectors[SelectorState.Failed] = object.default.selectors.objectInFailedState(SafariObject.id(id), CrudOperationType.Retrieve);
      allSelectors[SelectorState.Saving] = object.default.selectors.objectInSavingState(SafariObject.id(id));
      allSelectors[SelectorState.Saved] = object.default.selectors.objectInSavedState(SafariObject.id(id));
      allSelectors[SelectorState.Loaded] = object.default.selectors.objectInLoadedState(SafariObject.id(id));
      allSelectors[SelectorState.Loading] = object.default.selectors.objectInLoadingState(SafariObject.id(id));
      if (typeof options === 'boolean') {
        return this._store.select(object.default.selectors.objectInCurrentState(SafariObject.id(id))).pipe(filter(o => (options ? o != null : true)));
      } else if (typeof options === 'number') {
        return this._store.select(allSelectors[options]).pipe(filter(o => (selectorOptionFilterNull ? o != null : true)));
      }
      const selectors: Observable<T & ActionExtensions>[] = [];
      options.selectorState.forEach(element => {
        // NOTE: We don't want to filter null here , regardless of filterNull parameter.
        // filterNull applies to the final result
        selectors.push(this._store.select(allSelectors[element as number]));
      });
      // At this point it will find the first one that is not null and return that OR undefined.
      // This is where we will check whether we should block based on filterrNull
      return combineLatest(selectors)
        .pipe(
          mergeMap(combo => {
            for (const element of combo) {
              if (element != null) {
                return of(element);
              }
            }

            return of(undefined);
          })
        )
        .pipe(filter(o => (options?.filterNull ? o != null : true)));
    }

    onLoadObjectFail(action: LoadObjectActionFailInfo & Action<string>) {}
    loadObject<T extends SafariObject>(object: IReduxProvider<ISafariObjectState<T>, T>, id: SafariObjectId, overrides: LoadObjectActionInfo<T> = null) {
      if (id != null) {
        overrides = this._addactionIdToActionOverrides(overrides);

        this.addToPendingLoadObjectAction(object, overrides.actionId);

        const sub = this._loadObjectCompleteSubscriptions.get(object);
        if (sub == null) {
          this._loadObjectCompleteSubscriptions.set(
            object,
            this._actions
              .pipe(
                ofType(object.default.actions.loadObjectFail, object.default.actions.loadObjectSuccess),
                tap(o => {
                  if (o.actionId != null) {
                    this.removeFromPendingLoadObjectAction(object, o.actionId);
                  }
                  if (o.type === object.default.actions.loadObjectFail.type) {
                    this.onLoadObjectFail(o as LoadObjectActionFailInfo & Action<string>);
                  }
                })
              )
              .subscribe()
          );
        }
        this._store.dispatch(object.default.actions.loadObject({ ...overrides, payload: { id: SafariObject.id(id) } }));
      }
    }

    /**
     *
     * @param object
     * @param id
     * @param overrides - read loadObject$ for more info
     * @returns
     */
    loadObjectOnce$<T extends SafariObject>(
      object: IReduxProvider<ISafariObjectState<T>, T>,
      // Do not convert this to SafariObjectId - SOI will at some point be string again, but this should support both
      id: SafariObjectId,
      overrides: LoadObjectActionInfo<T> & { observeOptions?: ObserveObjectOptions } = null
    ): Observable<T & ActionExtensions> {
      return this.loadObject$(object, id, overrides).pipe(take(1));
    }

    /**
     *
     * @param object
     * @param id
     * @param overrides - standard overides plus observeOptions
     * ObserveOptions is currently polymorphic. It can be either a boolean, number or an actual object
     * This was mostly to provide backwards compatibility - we need to review if there are still even
     * calls out there that need this.
     *
     * But basically - these are versions of loadObject
     *
     * loadObject(object,id) - simple default case, uses current state, filters for null
     * loadObject(object,id, true) - same as above. This stands in for current state, filter for null = true
     * loadObject(object,id, false) - uses current state but doesn't filter for null
     * loadObject(object, id, State, filter) - 4 param version for back compat. Specififies a state and filter for null
     * loadObject(object, id, {...}) - new object based version - fill
     * @returns
     */

    loadObject$<T extends SafariObject>(
      object: IReduxProvider<ISafariObjectState<T>, T>,
      id: SafariObjectId,
      // Do not convert this to SafariObjectId - SOI will at some point be string again, but this should support both
      overrides: LoadObjectActionInfo<T> & { observeOptions?: ObserveObjectOptions } = null
    ): Observable<T & ActionExtensions> {
      if (id == '0') {
        //   // If we are listening on a new object we shouldn't use a longrunning selector as they all have __priorId of '0'
        //   // Instead we'll take one-and-done approach and listen on the exact loadObjectSuccess action
        //   // that matches the actionId we generate here. Meaning - this and only this particular
        //   // new object.
        overrides = this._addactionIdToActionOverrides(overrides);
        return new Observable(observer => {
          setTimeout(() => {
            // Even though this calls loadObject this will not result in HTTP call
            // ('0' returns immediately from in-memory)
            // Therefore we have to issue this AFTER we return the listener below
            this.loadObject(object, id, { ...overrides });
          });
          observer.next();
          observer.complete();
        }).pipe(
          mergeMap(() =>
            this._actions.pipe(
              ofType(object.default.actions.loadObjectSuccess.type),
              filter(o => o.actionId == overrides.actionId),
              // New objects don't observe the state at all but they immediately return when they hear success action
              // and they do their own return of the object. Not sure exactly why that is at this time, something worth a second look,
              // but for the purpose of the bug we just need to do what the reducer usually does for existing objects
              mergeMap(o => of({ ...o.payload, correlationId: o.correlationId, context: o.context })),
              // Since we're completing the main observable this is probably not necessary but it can't hurt either
              take(1)
            )
          )
        );
      }
      // Regular existing object long running observable
      this.loadObject(object, id, overrides);
      // In general we don't observe any state other than current.
      // But sometimes you want to observe additional states. For example, most of our pages let errors bubble
      // through and let the framework deal with it. However, you might want to  suppress errors
      // and then usually pass an override to also observe failed state. That will cause the selector to re-emit if failure
      // occurs with the original object in addition to error property that the page can query and decide
      // whether it will do something with it or retrhow
      if (overrides?.observeOptions == null) {
        return this.observeObject$<T>(object, SafariObject.id(id));
      } else {
        return this.observeObject$<T>(object, SafariObject.id(id), overrides.observeOptions);
      }
    }

    getDefaultLoadListParams() {
      return {
        id: null,
        filter: {
          orderBy: null,
          skip: 0,
          top: 0,
          additionalFilters: new Map<string, string>()
        } as CrudServiceParams,
        additionalInfo: null
      };
    }

    setUpLoadListFailSuccessObservable<T extends SafariObject>(object: IReduxProvider<ISafariObjectState<T>, T>, actionId) {
      this.addToPendingLoadListAction(object, actionId);
      const sub = this._loadListCompleteSubscriptions.get(object);
      if (sub == null) {
        this._loadListCompleteSubscriptions.set(
          object,
          this._actions
            .pipe(
              ofType(object.default.actions.loadObjectListFail, object.default.actions.loadObjectListSuccess),
              tap(o => {
                if (o.actionId != null) {
                  this.removeFromPendingLoadListAction(object, o.actionId);
                }
                if (o.type === object.default.actions.loadObjectListFail.type) {
                  this.onLoadObjectListFail(o as ActionErrorBase & Action<string>);
                }
              })
            )
            .subscribe()
        );
      }
    }
    loadObjectListCount<T extends SafariObject>(
      object: IReduxProvider<ISafariObjectState<T>, T>,
      additionalParams: SafariObjectListIdForDataAccessMixin = null,
      // Do not convert this to SafariObjectId - SOI will at some point be string again, but this should support both
      id: SafariObjectId = null,
      overrides: ActionOverrides = null
    ) {
      if (!additionalParams) {
        additionalParams = this.getDefaultLoadListParams();
      }
      id = id || null;
      overrides = this._addactionIdToActionOverrides(overrides);
      this.setUpLoadListFailSuccessObservable(object, overrides.actionId);
      const options = overrides?.options != null ? { ...overrides.options, countOnly: true } : { countOnly: true };

      this._store.dispatch(object.default.actions.loadObjectList({ actionId: overrides.actionId, payload: { ...additionalParams, id: SafariObject.id(id) }, options }));
    }

    loadObjectList<T extends SafariObject>(object: IReduxProvider<ISafariObjectState<T>, T>, listIdAndFilter: SafariObjectListIdForDataAccessMixin = null, overrides: LoadObjectListActionInfo = null) {
      if (!listIdAndFilter) {
        listIdAndFilter = this.getDefaultLoadListParams();
      }
      overrides = this._addactionIdToActionOverrides(overrides);
      this.setUpLoadListFailSuccessObservable(object, overrides.actionId);
      this._store.dispatch(
        object.default.actions.loadObjectList({ actionId: overrides.actionId, payload: { ...listIdAndFilter, id: SafariObject.id(listIdAndFilter.id) }, options: overrides?.options ?? null })
      );
    }
    /**
     *
     * @param objectType
     * @param listIdAndFilter
     * @param overrides - read loadObject$ for more info
     * @returns
     */
    loadObjectList$<T extends SafariObject>(
      objectType: IReduxProvider<ISafariObjectState<T>, T>,
      listIdAndFilter: SafariObjectListIdForDataAccessMixin = null,
      overrides: LoadObjectListActionInfo & { observeOptions?: ObserveObjectOptions } = null
    ): Observable<SafariObjectList<T> & ActionExtensions> {
      this.loadObjectList(objectType, listIdAndFilter, overrides);
      if (overrides?.observeOptions == null) {
        return this.observeObjectList$<T>(objectType, listIdAndFilter);
      } else {
        return this.observeObjectList$<T>(objectType, listIdAndFilter, overrides.observeOptions);
      }
    }

    observeObjectListCount$<T extends SafariObject>(object: IReduxProvider<ISafariObjectState<T>, T>, listIdAndFilter: SafariObjectListIdForDataAccessMixin) {
      if (!listIdAndFilter) {
        listIdAndFilter = this.getDefaultLoadListParams();
      }
      return this._store.select(object.default.selectors.objectListInCurrentStateTotalCount({ ...listIdAndFilter, id: SafariObject.id(listIdAndFilter.id) })).pipe(filter(o => o != null));
    }
    observeObjectHistory$<T extends SafariObject>(
      object: IReduxProvider<ISafariObjectState<T>, T>,
      id: SafariObjectId
      // options: ObserveObjectOptions = true, <-- TODO: At some point we'll probably want to look at observeObject$ and allow selection of states to observe
      // selectorOptionFilterNull: boolean = true  <-- TODO: At some point we'll probably want to look at observeObject$ and allow selection of states to observe
    ) {
      return this._store.select(object.default.selectors.objectHistoryInCurrentState(id)).pipe(filter(objectHistory => objectHistory != null));
    }

    /**
     *
     * @param object
     * @param listIdAndFilter
     * @param options - read loadObject for more info
     * @param selectorOptionFilterNull
     * @returns
     */
    observeObjectList$<T extends SafariObject>(
      object: IReduxProvider<ISafariObjectState<T>, T>,
      listIdAndFilter: SafariObjectListIdForDataAccessMixin,
      options: ObserveObjectOptions = true,
      selectorOptionFilterNull = true
    ): Observable<SafariObjectList<T> & ActionExtensions> {
      if (!listIdAndFilter) {
        listIdAndFilter = this.getDefaultLoadListParams();
      }
      const allSelectors: MemoizedSelector<object, SafariObjectList<T>, DefaultProjectorFn<SafariObjectList<T>>>[] = [];
      allSelectors[SelectorState.Current] = object.default.selectors.objectListInCurrentState({ ...listIdAndFilter, id: SafariObject.id(listIdAndFilter.id) });
      allSelectors[SelectorState.Failed] = object.default.selectors.objectListInFailedState({ ...listIdAndFilter, id: SafariObject.id(listIdAndFilter.id) }, CrudOperationType.Retrieve);
      //allSelectors[SelectorState.Saving] = object.default.selectors.objectListInSavingState({ ...listIdAndFilter, id: SafariObject.id(listIdAndFilter.id) });
      //allSelectors[SelectorState.Saved] = object.default.selectors.objectListInSavedState({ ...listIdAndFilter, id: SafariObject.id(listIdAndFilter.id) });
      allSelectors[SelectorState.Loaded] = object.default.selectors.objectListInLoadedState({ ...listIdAndFilter, id: SafariObject.id(listIdAndFilter.id) });
      //allSelectors[SelectorState.Loading] = object.default.selectors.objectListInLoadingState({ ...listIdAndFilter, id: SafariObject.id(listIdAndFilter.id) });
      if (typeof options === 'boolean') {
        return this._store
          .select<SafariObjectList<T>>(object.default.selectors.objectListInCurrentState({ ...listIdAndFilter, id: SafariObject.id(listIdAndFilter.id) }))
          .pipe(filter(o => selectorOptionFilterNull == false || o != null));
      } else if (typeof options === 'number') {
        return this._store.select(allSelectors[options]).pipe(filter(o => (selectorOptionFilterNull ? o != null : true)));
      }
      const selectors: Observable<SafariObjectList<T> & ActionExtensions>[] = [];
      options.selectorState.forEach(element => {
        // NOTE: We don't want to filter null here , regardless of filterNull parameter.
        // filterNull applies to the final result
        selectors.push(this._store.select(allSelectors[element as number]));
      });

      // At this point it will find the first one that is not null and return that OR undefined.
      // This is where we will check whether we should block based on filterrNull
      return combineLatest(selectors)
        .pipe(
          mergeMap(combo => {
            for (const element of combo) {
              if (element != null) {
                return of(element);
              }
            }

            return of(undefined);
          })
        )
        .pipe(filter(o => (options?.filterNull ? o != null : true)));
    }
    observeDropdowns$<T extends IdName>(dropdown: SafariReduxDropdownObject<any>, id: SafariObjectId): Observable<T[]> {
      const idToObserve = SafariObject.id(id); //.replace(/,/g, "_");
      return this._store.select(dropdown.default.selectors.dropdownState(idToObserve)).pipe(
        filter((o: T[]) => o != null),
        // We shouldn't really have to do distinctUntilChanged here since we always get dropdowns from cache
        // if they have already been loaded, but somehow presence of matter search control is causing double
        // emission before it gets cached for the first time. For now we'll just use distcintUntilchanged here, shouldn't hurt.
        // There's another bug for the issue above
        distinctUntilChanged((prev, current) => JSON.stringify(prev) == JSON.stringify(current))
      );
    }
    observeSearchResult$(id: string): Observable<any> {
      return this._store.pipe(select(this._searchReduxObject.default.selectors.searchState(id)));
    }
    onLoadObjectListFail(action: ActionErrorBase & Action<string>) {}
    deleteObject<T extends SafariObject>(
      object: IReduxProvider<ISafariObjectState<T>, T>,
      // Do not convert this to SafariObjectId - SOI will at some point be string again, but this should support both
      idOrIdAndBody: SafariObjectId | { id: SafariObjectId; body: any },
      overrides: ActionOverrides = null
    ) {
      if (idOrIdAndBody != null) {
        const id = typeof idOrIdAndBody === 'string' || typeof idOrIdAndBody === 'number' ? idOrIdAndBody : idOrIdAndBody.id;
        const body = typeof idOrIdAndBody === 'string' || typeof idOrIdAndBody === 'number' ? null : idOrIdAndBody.body;
        overrides = this._addactionIdToActionOverrides(overrides);
        this.addToPendingDeleteObjectAction(object, overrides.actionId);
        const sub = this._deleteObjectCompleteSubscriptions.get(object);
        if (sub == null) {
          this._deleteObjectCompleteSubscriptions.set(
            object,
            this._actions
              .pipe(
                ofType(object.default.actions.deleteObjectFail, object.default.actions.deleteObjectSuccess),
                tap(o => {
                  if (o.type === object.default.actions.deleteObjectFail.type) {
                    this.onDeleteObjectFail(object, id);
                  } else if (o.type === object.default.actions.deleteObjectSuccess.type) {
                    this.onDeleteObjectSuccess(object, id);
                  }
                  if (o.actionId != null) {
                    this.removeFromPendingDeleteObjectAction(object, o.actionId);
                  }
                })
              )
              .subscribe()
          );
        }

        this._store.dispatch(object.default.actions.deleteObject({ payload: { id: SafariObject.id(id), body }, actionId: overrides.actionId, options: overrides?.options ?? null }));
      }
    }
    deleteObjects<T extends SafariObject>(
      object: IReduxProvider<ISafariObjectState<T>, T>,
      id: SafariObjectId,
      // Do not convert this to SafariObjectId - SOI will at some point be string again, but this should support both
      deletePayload: DeleteMultipleObjectsPayload,
      overrides: ActionOverrides = null
    ) {
      if (deletePayload != null) {
        const actionId = overrides == null || overrides.actionId == null ? uuidv4() : overrides.actionId;
        this.addToPendingDeleteObjectAction(object, actionId);
        const sub = this._deleteObjectCompleteSubscriptions.get(object);
        if (sub == null) {
          this._deleteObjectCompleteSubscriptions.set(
            object,
            this._actions
              .pipe(
                ofType(object.default.actions.deleteObjectFail, object.default.actions.deleteObjectSuccess),
                tap(o => {
                  // if (o.type === object.default.actions.deleteObjectFail.type) {
                  //   this.onDeleteObjectFail(object, id);
                  // } else if (o.type === object.default.actions.deleteObjectSuccess.type) {
                  //   this.onDeleteObjectSuccess(object, id);
                  // }
                  if (o.actionId != null) {
                    this.removeFromPendingDeleteObjectAction(object, o.actionId);
                  }
                })
              )
              .subscribe()
          );
        }

        this._store.dispatch(object.default.actions.deleteMultipleObjects({ payload: { id: SafariObject.id(id), deletePayload }, actionId, options: overrides?.options ?? null }));
      }
    }
    onDeleteObjectFail(object: IReduxProvider<any, any>, id: any) {}
    onDeleteObjectSuccess(object: IReduxProvider<any, any>, id: any) {}

    clearObjectState<T extends SafariObject>(object: IReduxProvider<ISafariObjectState<T>, T>) {
      this._store.dispatch(object.default.actions.clearState());
    }

    deleteMultipleObjectsOnce$<T extends SafariObject>(
      object: IReduxProvider<ISafariObjectState<T>, T>,
      id: SafariObjectId,
      // Do not convert this to SafariObjectId - SOI will at some point be string again, but this should support both
      deletePayload: DeleteMultipleObjectsPayload,
      overrides: LoadObjectActionInfo<T> = null,
      throwAbortOnError = true
    ): Observable<{ id: SafariObjectId; deletePayload: DeleteMultipleObjectsPayload } & { error?: any }> {
      overrides = this._addactionIdToActionOverrides(overrides);

      const ok = this._actions.pipe(
        ofType(object.default.actions.deleteMultipleObjectsSuccess),
        filter(o => o.actionId == overrides.actionId)
      );
      const fail = this._actions.pipe(
        ofType(object.default.actions.deleteMultipleObjectsFail),
        filter(o => o.actionId == overrides.actionId)
      );

      const func = new Observable(observer => {
        this.deleteObjects(object, id, deletePayload, { ...overrides, actionId: overrides.actionId });
        observer.next();
        observer.complete();
      }).pipe(
        mergeMap(() =>
          race(ok, fail).pipe(
            mergeMap(o => {
              if (o.type === object.default.actions.deleteMultipleObjectsFail.type) {
                return of({ id, deletePayload, ...{ error: (o as ActionErrorBase).error } });
              } else {
                return of({ id, deletePayload });
              }
            }),
            // Since we're completing the main observable this is probably not necessary but it can't hurt either
            take(1)
          )
        )
      );

      return throwAbortOnError ? func.pipe(abortOnError()) : func;
    }

    deleteObjectOnce$<T extends SafariObject>(
      object: IReduxProvider<ISafariObjectState<T>, T>,
      // Do not convert this to SafariObjectId - SOI will at some point be string again, but this should support both
      id: SafariObjectId,
      overrides: LoadObjectActionInfo<T> = null,
      throwAbortOnError = true
    ): Observable<{ id: SafariObjectId } & { error?: any }> {
      overrides = this._addactionIdToActionOverrides(overrides);
      const ok = this._actions.pipe(
        ofType(object.default.actions.deleteObjectSuccess),
        filter(o => SafariObject.idEqual(o.actionId, overrides.actionId))
      );
      const fail = this._actions.pipe(
        ofType(object.default.actions.deleteObjectFail),
        filter(o => SafariObject.idEqual(o.actionId, overrides.actionId))
      );

      const func = new Observable(observer => {
        this.deleteObject(object, id, overrides);
        observer.next();
        observer.complete();
      }).pipe(
        mergeMap(() =>
          race(ok, fail).pipe(
            mergeMap(o => {
              if (o.type === object.default.actions.deleteObjectFail.type) {
                return of({ id: SafariObject.id(id), ...{ error: (o as ActionErrorBase).error } });
              } else {
                return of(o.payload) as Observable<{ id: SafariObjectId }>;
              }
            }),
            // Since we're completing the main observable this is probably not necessary but it can't hurt either
            take(1)
          )
        )
      );

      return throwAbortOnError ? func.pipe(abortOnError()) : func;
    }
    /**
     *
     * @param object
     * @param appModel
     * @param overrides
     * @param throwAbortOnError - specifies whether the function will throw AbortSave error if any errors are found
     * Note that this is different than ignoring errors in the effect. This parameter is used to short circuit
     * multi-step saves in case of any errors, even if ignore errors is on. For example in a multi-step save
     * we may choose to ignore errors on one of the child saves so it doesn't go to error page but we may still
     * want to throw AbortSave so it shortcircuits and immediately goes to the end of the save call. There we can provide the user
     * with a toaster, dialog or something else, telling them that there were problems.
     * If this parameter is set to true (default) the caller needs to make sure to include the call to handleSaveError as the last function in the save pipe.
     * @returns
     *
     * creates/updates an object and returns either the object as returned by the API or the original payload
     * plus error returned from the api
     *
     * Note that unlike load/loadlist observables this one is not meant to be a long-running observable. In
     * other words - you will use it only when needed (response to save, etc), by explicitly subscribing to it
     * It has take(1) on it so it will be thrown away after it completes
     */
    createOrUpdateObjectOnce$<T extends SafariObject>(
      object: IReduxProvider<ISafariObjectState<T>, T>,
      appModel: T,
      overrides: UpdateOrCreateActionInfo<T> = null,
      throwAbortOnError = true
    ): Observable<T & { error?: any }> {
      overrides = this._addactionIdToActionOverrides(overrides);
      // This is a one-off take(1) observable, not a long running observable like loadObject$ etc.
      // However, unlike form submit this does not need setTimeout. The reason is that once effects are hooked to
      // actions dispatch method starts dispatching asyncroneously, meaning this will already have setTimeout internally
      // this._store.dispatch(object.default.actions.clearState());
      const ok = this._actions.pipe(
        ofType(object.default.actions.createObjectSuccess, object.default.actions.updateObjectSuccess),
        filter(o => SafariObject.idEqual(o.actionId, overrides.actionId))
      );
      const fail = this._actions.pipe(
        ofType(object.default.actions.createOrUpdateObjectFail),
        filter(o => SafariObject.idEqual(o.actionId, overrides.actionId))
      );
      // ---------------------------------------------------------
      // this.createOrUpdateObject(object, appModel, overrides); <-- bad (outside of returned observable)
      //
      // We used to call createOrUpdateObject outside of the observable (commented call above) and then immediately
      // returned observable to race(ok, fail). The problem with that is that it wouldn't work
      // well with sequential observable operators like concat. Concat waits for the previous observable to complete
      // before moving on to the next one but if you do  concat(createOrUpdateObjectOnce$(object1), createOrUpdateObjectOnce$(object2))
      // and createOrUpdateObjectOnce immediately issues this.createOrUpdateObject then it's possible that object2
      // will emit success before object1 and by the time object1 is done and concat moves forward to observe
      // object 2 there will be no actions to listen for on object2 as that is already done and gone.
      // (Remember - this observable listens for actions, not selectors)
      // So instead we are returning an observable whose first call is createOrUpdateObject. By returning an observable
      // createOrUpdateObject won't be called in the second concat UNTIL first concat is done, thus eliminating the problem
      // (since  concat won't subscribe to  the next observable until the first one is done)
      // ------------------------------------------------------------
      const func = new Observable(observer => {
        this.createOrUpdateObject(object, appModel, overrides);
        observer.next();
        observer.complete();
      }).pipe(
        mergeMap(() =>
          // TODO: 7965
          // This should listen on "Saved" object selector and filter on the exact action ID
          // instead of racing to hear success/fail action
          race(ok, fail).pipe(
            mergeMap(o => {
              if (o.type === object.default.actions.createOrUpdateObjectFail.type) {
                return of({ ...appModel, ...{ error: (o as ActionErrorBase).error } });
              } else {
                return of(o.payload) as Observable<T>;
              }
            }),
            // Since we're completing the main observable this is probably not necessary but it can't hurt either
            take(1)
          )
        )
      );

      return throwAbortOnError ? func.pipe(abortOnError()) : func;
    }

    updatePartialObjectOnce$<T extends SafariObject>(
      object: IReduxProvider<ISafariObjectState<T>, T>,
      appModel: T,
      original: T,
      overrides: LoadObjectActionInfo<T> = null,
      throwAbortOnError = true
    ): Observable<T & { error?: any }> {
      overrides = this._addactionIdToActionOverrides(overrides);
      // This is a one-off take(1) observable, not a long running observable like loadObject$ etc.
      // However, unlike form submit this does not need setTimeout. The reason is that once effects are hooked to
      // actions dispatch method starts dispatching asyncroneously, meaning this will already have setTimeout internally
      // this._store.dispatch(object.default.actions.clearState());
      const ok = this._actions.pipe(
        ofType(object.default.actions.updatePartialObjectSuccess),
        filter(o => SafariObject.idEqual(o.actionId, overrides.actionId))
      );
      const fail = this._actions.pipe(
        ofType(object.default.actions.updatePartialObjectFail),
        filter(o => SafariObject.idEqual(o.actionId, overrides.actionId))
      );
      // ---------------------------------------------------------
      // this.createOrUpdateObject(object, appModel, overrides); <-- bad (outside of returned observable)
      //
      // We used to call createOrUpdateObject outside of the observable (commented call above) and then immediately
      // returned observable to race(ok, fail). The problem with that is that it wouldn't work
      // well with sequential observable operators like concat. Concat waits for the previous observable to complete
      // before moving on to the next one but if you do  concat(createOrUpdateObjectOnce$(object1), createOrUpdateObjectOnce$(object2))
      // and createOrUpdateObjectOnce immediately issues this.createOrUpdateObject then it's possible that object2
      // will emit success before object1 and by the time object1 is done and concat moves forward to observe
      // object 2 there will be no actions to listen for on object2 as that is already done and gone.
      // (Remember - this observable listens for actions, not selectors)
      // So instead we are returning an observable whose first call is createOrUpdateObject. By returning an observable
      // createOrUpdateObject won't be called in the second concat UNTIL first concat is done, thus eliminating the problem
      // (since  concat won't subscribe to  the next observable until the first one is done)
      // ------------------------------------------------------------
      const func = new Observable(observer => {
        this.updatePartialObject(object, appModel, original, overrides);
        observer.next();
        observer.complete();
      }).pipe(
        mergeMap(() =>
          race(ok, fail).pipe(
            mergeMap(o => {
              if (o.type === object.default.actions.updatePartialObjectFail.type) {
                return of({ ...appModel, ...{ error: (o as ActionErrorBase).error } });
              } else {
                return of(o.payload) as Observable<T>;
              }
            }),
            // Since we're completing the main observable this is probably not necessary but it can't hurt either
            take(1)
          )
        )
      );

      return throwAbortOnError ? func.pipe(abortOnError()) : func;
    }

    updateObjectListOnce$<T extends SafariObject>(
      object: IReduxProvider<ISafariObjectState<T>, T>,
      originalList: T[],
      updatedList: T[],
      overrides: LoadObjectActionInfo<T> = null,
      throwAbortOnError = true
    ): Observable<{ originalList: T[]; updatedList: T[] } & { error?: any }> {
      // This is a one-off take(1) observable, not a long running observable like loadObject$ etc.
      // However, unlike form submit this does not need setTimeout. The reason is that once effects are hooked to
      // actions dispatch method starts dispatching asyncroneously, meaning this will already have setTimeout internally
      // this._store.dispatch(object.default.actions.clearState());
      const ok = this._actions.pipe(ofType(object.default.actions.updateObjectListSuccess));
      const fail = this._actions.pipe(ofType(object.default.actions.updateObjectListFail));

      const func = new Observable(observer => {
        this.updateObjectList(object, originalList, updatedList, overrides);
        observer.next();
        observer.complete();
      }).pipe(
        mergeMap(() =>
          race(ok, fail).pipe(
            mergeMap(o => {
              if (o.type === object.default.actions.updateObjectListFail.type) {
                return of({ ...{ originalList, updatedList }, ...{ error: (o as ActionErrorBase).error } });
              } else {
                return of(o.payload) as Observable<{ originalList: T[]; updatedList: T[] }>;
              }
            }),
            // Since we're completing the main observable this is probably not necessary but it can't hurt either
            take(1)
          )
        )
      );

      return throwAbortOnError ? func.pipe(abortOnError()) : func;
    }

    updateObjectList<T extends SafariObject>(object: IReduxProvider<ISafariObjectState<T>, T>, originalList: T[], updatedList: T[], overrides: LoadObjectActionInfo<T> = null) {
      if (overrides == null) {
        overrides = {};
      }
      const actionId = overrides.actionId ?? uuidv4();

      this.addToPendingUpdateObjectListAction(object, actionId);

      const sub = this._updateObjectListCompleteSubscriptions.get(object);
      if (sub == null) {
        this._updateObjectListCompleteSubscriptions.set(
          object,
          this._actions
            .pipe(
              ofType(object.default.actions.updateObjectListSuccess, object.default.actions.updateObjectListFail),
              tap(o => {
                if (o.actionId != null) {
                  this.removeFromPendingUpdateObjectListAction(object, o.actionId);
                }
                if (o.type === object.default.actions.updateObjectListFail.type) {
                  this.onUpdateObjectListFail(o as UpdateObjectListActionFailInfo<T> & Action<string>);
                }
              })
            )
            .subscribe()
        );
      }

      this._store.dispatch(object.default.actions.updateObjectList({ ...overrides, payload: { originalList, updatedList }, actionId }));
    }

    createOrUpdateObject<T extends SafariObject>(object: IReduxProvider<ISafariObjectState<T>, T>, appModel: T, overrides: UpdateOrCreateActionInfo<T> = null) {
      overrides = this._addactionIdToActionOverrides(overrides);

      this.addToPendingCreateUpdateObjectAction(object, overrides.actionId);

      const sub = this._updateObjectCompleteSubscriptions.get(object);
      if (sub == null) {
        this._updateObjectCompleteSubscriptions.set(
          object,
          this._actions
            .pipe(
              ofType(object.default.actions.createObjectSuccess, object.default.actions.updateObjectSuccess, object.default.actions.createOrUpdateObjectFail),
              tap(o => {
                if (o.actionId != null) {
                  this.removeFromPendingCreateOrUpdateObjectAction(object, o.actionId);
                }
                if (o.type === object.default.actions.createOrUpdateObjectFail.type) {
                  this.onCreateOrUpdateObjectFail(o as UpdateOrCreateActionFailInfo<T> & Action<string>);
                }
              })
            )
            .subscribe()
        );
      }

      this._store.dispatch(object.default.actions.createOrUpdateObject({ ...overrides, payload: appModel }));
    }

    updatePartialObject<T extends SafariObject>(object: IReduxProvider<ISafariObjectState<T>, T>, appModel: T, original: T, overrides: LoadObjectActionInfo<T> = null) {
      overrides = this._addactionIdToActionOverrides(overrides);

      this.addToPendingCreateUpdateObjectAction(object, overrides.actionId);

      const sub = this._updateObjectCompleteSubscriptions.get(object);
      if (sub == null) {
        this._updateObjectCompleteSubscriptions.set(
          object,
          this._actions
            .pipe(
              ofType(object.default.actions.updatePartialObjectSuccess, object.default.actions.updatePartialObjectFail),
              tap(o => {
                if (o.actionId != null) {
                  this.removeFromPendingCreateOrUpdateObjectAction(object, o.actionId);
                }
                if (o.type === object.default.actions.updatePartialObjectFail.type) {
                  this.onCreateOrUpdateObjectFail(o as UpdateOrCreateActionFailInfo<T> & Action<string>);
                }
              })
            )
            .subscribe()
        );
      }

      this._store.dispatch(object.default.actions.updatePartialObject({ ...overrides, payload: appModel }));
    }
    onCreateOrUpdateObjectFail(action: LoadObjectActionFailInfo & Action<string>) {}

    onUpdateObjectListFail(action: UpdateObjectListActionFailInfo<any> & Action<string>) {}

    /*
    abortActions  - It will abort all pending load actions for all registered objects
  */
    abortActions() {
      for (const key of Array.from(this._pendingLoadObjectActions.keys())) {
        const pendingLoadObjectactionIds = this._pendingLoadObjectActions.get(key);
        pendingLoadObjectactionIds.forEach(actionId => {
          this._store.dispatch(key.default.actions.loadObject({ abort: actionId }));
        });
      }
      for (const key of Array.from(this._pendingDeleteObjectActions.keys())) {
        const pendingDeleteObjectactionIds = this._pendingDeleteObjectActions.get(key);
        pendingDeleteObjectactionIds.forEach(actionId => {
          this._store.dispatch(key.default.actions.deleteObject({ abort: actionId }));
        });
      }

      for (const key of Array.from(this._pendingLoadListActions.keys())) {
        const pendingLoadObjectListactionIds = this._pendingLoadListActions.get(key);

        pendingLoadObjectListactionIds.forEach(actionId => {
          this._store.dispatch(key.default.actions.loadObjectList({ abort: actionId }));
        });
      }
      this._pendingLoadObjectActions.clear();
      this._pendingLoadObjectBlockUiActions.clear();
      this._pendingLoadListActions.clear();
      this._pendingDeleteObjectActions.clear();
    }

    /*
    dispatchDropdownLoadActions  - Dispatches load actions for one or more dropdowns inside this module

    PARAMETERS:

    ...args                      - Comma-separated list of dropdown enums inside this module that you wish to load.
                                   For example: DropdownType.Countries, DropdownType.States


  */
    dispatchDropdownLoadActions(dropdown: SafariReduxDropdownObject<any>, ...args: any[]) {
      const argsAsArray = [...args];
      argsAsArray.forEach(o => this._store.dispatch(dropdown.default.actions.loadDropdown({ id: o as SafariObjectId })));
    }
    dispatchDropdownBulkLoadActions(dropdown: SafariReduxDropdownObject<any>, id: SafariObjectId, ...args: SafariObjectId[]) {
      const dropdownIds = [...args];
      this._store.dispatch(dropdown.default.actions.loadBulkDropdown({ id, dropdownIds: dropdownIds.map(x => SafariObject.id(x)) }));
    }
    dispatchDropdownClearActions(dropdown: SafariReduxDropdownObject<any>, ...args: any[]) {
      const argsAsArray = [...args];
      argsAsArray.forEach(o => this._store.dispatch(dropdown.default.actions.clearDropdown({ id: o as SafariObjectId })));
    }
    dispatchSearchLoadActions(...args) {
      const argsAsArray = [...args];
      argsAsArray.forEach(o => this._store.dispatch(this._searchReduxObject.default.actions.loadSearch({ id: o.id, query: o.query })));
    }
    dispatchSearchLoadAbortActions(...args) {
      const argsAsArray = [...args];
      argsAsArray.forEach(o => this._store.dispatch(this._searchReduxObject.default.actions.loadSearch({ abort: o })));
    }
    dispatchSearchClearActions(...args) {
      const argsAsArray = [...args];
      argsAsArray.forEach(o => this._store.dispatch(this._searchReduxObject.default.actions.clearSearch({ id: o })));
    }
  };
