import { Injectable } from '@angular/core';
import { ApiDataAdapter } from '@safarilaw-webapp/shared/crud';
import { InboundEmailSettingList } from '../models/api/inbound-email-setting-list';
import { InboundEmailSetting } from '../models/app/inbound-email-setting';

@Injectable({
  providedIn: 'any'
})
export class InboundEmailSettingAdapter extends ApiDataAdapter<InboundEmailSetting> {
  fromListModel(o: InboundEmailSettingList): Required<InboundEmailSetting> {
    return {
      emailName: o.emailName,
      matterSubtypeId: o.matterSubtypeId,
      validDomains: o.validDomains,
      requireAttachments: o.requireAttachments,
      __base: undefined,
      id: undefined
    };
  }
}
