import { Injectable } from '@angular/core';
import { AppConfigurationService } from '../appconfiguration/appconfiguration.service';

export enum FeatureFlag {
  DemoPreviews = 'demoPreviews',
  DevTools = 'devTools',
  DevExceptions = 'devExceptions',
  EnableMatterSubtypePicklistSettingsEditing = 'enableMatterSubtypePicklistSettingsEditing',
  ShowMatterSubtypePicklistSettings = 'showMatterSubtypePicklistSettings',
  ShowCustomPicklistSettings = 'showCustomPicklistSettings',
  EnableCustomPicklistSettingsEditing = 'enableCustomPicklistSettingsEditing'
}
@Injectable({
  providedIn: 'root'
})
export class FeatureFlagsService {
  constructor(private appConfig: AppConfigurationService) {}
  isFeatureFlagEnabled(flag: FeatureFlag) {
    return this.appConfig.featureFlags[flag] === true;
  }

  get demoPreviews() {
    return this.appConfig.featureFlags.demoPreviews === true;
  }
  get devTools() {
    return this.appConfig.featureFlags.devTools === true;
  }
  get devExceptions() {
    return this.appConfig.featureFlags.devExceptions === true;
  }
  get enableMatterSubtypePicklistSettingsEditing() {
    return this.appConfig.featureFlags.enableMatterSubtypePicklistSettingsEditing === true;
  }
  get showMatterSubtypePicklistSettings() {
    return this.appConfig.featureFlags.showMatterSubtypePicklistSettings === true;
  }
  get showCustomPicklistSettings() {
    return this.appConfig.featureFlags.showCustomPicklistSettings === true;
  }
  get enableCustomPicklistSettingsEditing() {
    return this.appConfig.featureFlags.enableCustomPicklistSettingsEditing === true;
  }
}
