import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  HTTP_STATUS_CODE_API_CONFLICT,
  HTTP_STATUS_CODE_API_FORBIDDEN,
  HTTP_STATUS_CODE_API_NOTAUTHORIZED,
  HTTP_STATUS_CODE_API_NOTFOUND,
  HTTP_STATUS_CODE_API_TOOMANY,
  HTTP_STATUS_CODE_API_VALIDATION,
  HTTP_STATUS_CODE_GATEWAY_TIMEOUT,
  HTTP_STATUS_CODE_UNKNOWN_ERROR,
  HTTP_STATUS_CODE_WEB_VALIDATION_BASE
} from '@safarilaw-webapp/shared/common-objects-models';
import { AppDialogUiReduxObject, ConfirmationDialogButton } from '@safarilaw-webapp/shared/dialog';
import { AppConfigurationService } from '@safarilaw-webapp/shared/environment';
import { ErrorMessageParserService, ErrorObject, FileTransferError } from '@safarilaw-webapp/shared/error-handling-message-parser';
import { LoggerService } from '@safarilaw-webapp/shared/logging';
import { AbortSave, ReduxErrorUiOption } from '@safarilaw-webapp/shared/redux';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { ErrorDialogComponent } from '../../components/error-dialog/error-dialog.component';
// Generic class for handling error messages

declare function showErrorPage();

export const CHUNK_RETRY_COUNT_PARAM = '__chcn';
@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {
  private _showingError = false;
  private _sub: Subscription;
  private _lastErrorShown: string = null;
  private _lastErrorShownTime = 0;
  static retryChunkError(urlWithOrigin: string, throwErrorIfRetriesFail = false): number {
    const url = new URL(urlWithOrigin);

    if (url.pathname.toLowerCase() == '/error' || url.pathname.toLowerCase() == '/interstitial') {
      // Do not continue retrying on these two - chunk retry will eventually send to error
      // so we don't want to retry anymore
      return;
    }
    let chunkCount = parseInt(url.searchParams.get(CHUNK_RETRY_COUNT_PARAM), 10);
    if (isNaN(chunkCount)) {
      chunkCount = 0;
    }
    chunkCount++;
    if (chunkCount <= 3) {
      url.searchParams.set(CHUNK_RETRY_COUNT_PARAM, chunkCount.toString());

      setTimeout(() => {
        window.history.pushState({}, document.title, url.toString());

        window.location.href = url.toString();
      }, 500);
    } else if (throwErrorIfRetriesFail) {
      setTimeout(() => {
        throw new Error('App reinitialization failed.');
      });
    }
    return chunkCount;
  }

  constructor(private _injector: Injector, private _zone: NgZone) {}

  navigateWithRetry(url) {
    const router = this._injector.get(Router);
    this._zone.run(() => {
      router
        .navigate([url], {
          skipLocationChange: true
        })
        .catch(() => {
          // 3163 - If angular fails when navigating to one of the error pages user will end up with a blank page
          // This will happen if OnDestroy of the page that is causing an error also caused an error. What happens is:

          // 1. Page X throws an exception somewhere - let's say in ngOnInit
          // 2. Our error handler service gets called and tries to navigate to the error page
          // 3. Navigation starts. As part of the navigation to the new page the old page is getting torn down.
          // 4. Angular invokes ngOnDestroy of PageX.
          // 5. ngOnDestroy throws another exception. THIS is the part that angular hates. The exception is being thrown while it's trying to navigate.
          // 6. Angular stops

          // To deal with this we'll give it one more final shot, by dummy-navigating (otherwise NG will do nothing),
          // then immediately back to the page we wanted to go to.
          // If even that fails... just do full browser refresh not sure there's much more we can do... ¯\_(ツ)_/¯

          // P.S. Not sure that we need setTimeout , much less 25ms , but it won't
          // hurt and might be good just to make sure that any pending promises got cleared.

          // I m also wondering if we even need interstitial page anymore, now that our reload strategy is
          // to do full reload, but leaving that alone for now
          setTimeout(() => {
            void router
              .navigateByUrl('/interstitial', { skipLocationChange: true })
              .then(o => {
                if (o) {
                  router
                    .navigate([url], {
                      skipLocationChange: true
                    })
                    .catch(() => {
                      window.location.href = '/';
                    });
                } else {
                  window.location.href = '/';
                }
              })
              .catch(() => {
                // If this happens its' probably some hard error AFTER NG boostrapped but if failed
                // because it couldn't navigate to even this final call. This could happen if there
                // was a failure in spinning up a lazy loaded module. In this case call our global error page
                showErrorPage();
              });
          }, 25);
        });
    });
  }
  handleError(thrownError: any): void {
    if (typeof thrownError == 'boolean') {
      // Don't log error of type boolean - that's thrown by initializer when reloading service worker
      return;
    }

    const errorTimestamp = DateTime.now().toMillis();
    thrownError.uuid = uuidv4();
    const location = this._injector.get(LocationStrategy);
    const url = location instanceof PathLocationStrategy ? location.path() : '';

    if (thrownError instanceof AbortSave) {
      // AbortSave is a special kind of error used for short-circuiting multi-step saves and it can
      // be thrown by form validations, dialog cancellations or API actions (even if ignore errors is set to true)
      // For ease of calling our dialogs now default to throwing abortsave on cancel by default. While the dialogs
      // that are inside rxjs flows (save/delete/etc) will have AbortSave handled internally we still have
      // a number of standalone dialogs out there that will now start raising this error on cancel. We don't
      // want to log warnings for that and there is no real harm in simply ignoring it.
      return;
    }

    const errorMessageParser = this._injector.get(ErrorMessageParserService);
    const errorObject = errorMessageParser.getErrorObjectFromThrownError(thrownError);
    let errorObjectAsString = '';
    // Use errorObject.message, rather than stringified JSON error object. If we stringify the entire object
    // it will always be different since it will contain different unique error ID, even if everything else
    // is the same. However, we should also check for source and silent options in this comparison

    errorObjectAsString = errorObject.message + ':' + (thrownError.silent != null ? (thrownError.silent as boolean).toString() : 'false') + ':' + (thrownError.source as string);
    // throttle back to back errors
    if (
      !this._lastErrorShown || // if never showed the error
      this._lastErrorShown !== errorObjectAsString || // or the error is different than the last
      errorTimestamp - this._lastErrorShownTime > 250 // or more than quarter of a second expired since the error was shown
    ) {
      this._lastErrorShown = errorObjectAsString;
      this._lastErrorShownTime = errorTimestamp;
      this.showAndLogMessage(thrownError, errorObject, url);
    }
  }

  showAndLogMessage(thrownError: any, errorObject: ErrorObject, url: string) {
    // if a user is holding the dialog open don't spam the logs in case
    // repeated messages are coming in due to change detection kicking in.
    // (this will happen when there is a binding issue such as trying to access
    // property of a null object inside HTML)
    if (this._showingError) {
      return;
    }
    // Hack alert. We currently have a way to silence errors but they will always get logged
    // We don't have a way to also tell the request "don't log it". That's something we need to
    // work on in the future if we feel there's a need for that but for now we're just hardcoding
    // against sqrl URL.
    const appConfig = this._injector.get(AppConfigurationService);
    // I m not sure if its possible for appConfig to not get injected but maybe it is if this is some
    // other error thrown during early initialization. I know we do some weird stuff with appconfigservice
    // during init so let's just check for NULL to be safe
    if (appConfig) {
      // Be extra defensive and check that the URL property exists as well before checking for includes
      if (thrownError.status == HTTP_STATUS_CODE_API_NOTFOUND && thrownError.url && thrownError.url.includes(appConfig.urls.apiRootSquirrel)) {
        return;
      }
    }

    const logger = this._injector.get(LoggerService);

    // TODO: This is a quick hack to not log cancellation errors.
    // Long-term we should probably consider either
    // a) adding some 'noLog' property to error objects, check it here and also modify file transfer service to throw that
    // b) simply not throw error from dispatchcancel message but return of(null) or something
    // Both feel too risky to add at this time so maybe something to follow up on in the backend release.

    if (thrownError.status != FileTransferError.Cancelled) {
      logger.LogError(thrownError, url, errorObject.pageContext);
    }

    window['safari-error-state'] = {
      errorObject,
      url
    };

    if (!thrownError.silent) {
      if (thrownError.status == HTTP_STATUS_CODE_API_FORBIDDEN) {
        this._showingError = false;
        this.navigateWithRetry('/403');
        return;
      } else if (thrownError.status == HTTP_STATUS_CODE_GATEWAY_TIMEOUT || thrownError.status == HTTP_STATUS_CODE_UNKNOWN_ERROR) {
        this._showingError = false;
        this.navigateWithRetry('/504');
        return;
      } else if (thrownError.status == HTTP_STATUS_CODE_API_NOTFOUND) {
        this._showingError = false;
        this.navigateWithRetry('/404');
        return;
      }
    }
    if (thrownError.status == HTTP_STATUS_CODE_API_NOTAUTHORIZED || thrownError.status == HTTP_STATUS_CODE_API_TOOMANY) {
      thrownError.silent = true;
    }
    // CHUNKERROR COMMENTS
    // By the time we get here if a chunk error happened angular will flatten it to a regular JS error
    // but there are important differences. IF the error came from the main module (the one you pointed your URL
    // to and then it failed due to lazy loading) THEN the error will be of type "Uncaught in promise" and the message
    // will contain words "ChunkLoadError"
    // IF on the other hand the error came from background module then the error will be of type "ChunkLoadError" but will
    // not contain word ChunkLoadError in message. In some way this is to our advantage - basically we don't want this error
    // shown by the error page (silent = true) if it comes from main module loading. That's because we will handle that particular
    // case in app.component.ts of the main bootstrap module.
    // On the other hand - if the error comes from background module - app.component.ts will never see it SO in this case
    // we don't want it to be silent. Instead we will throw it and the error page itself will handle it in a similar way to
    // app.component
    // For better understanding of all the pieces just search for "CHUNKERROR COMMENTS" throughout the code.
    if (thrownError.message != null && thrownError.message.includes('ChunkLoadError')) {
      thrownError.silent = true;
    }
    // Ignore flexmonster redrawtoolbar error
    if (thrownError.stack != null && typeof thrownError.stack.toString == 'function' && thrownError.stack.toString().includes('_redrawToolbar ')) {
      thrownError.silent = true;
    }

    if (!thrownError.silent) {
      if (thrownError.uiOption != null) {
        this._showErrorUi(thrownError.uiOption, errorObject, url);
        return;
      }
      if (
        !thrownError.status ||
        (thrownError.status !== HTTP_STATUS_CODE_API_VALIDATION && thrownError.status < HTTP_STATUS_CODE_WEB_VALIDATION_BASE && thrownError.status !== HTTP_STATUS_CODE_API_CONFLICT)
      ) {
        this._showErrorUi(ReduxErrorUiOption.ErrorPage, errorObject, url);
        return;
      } else {
        this._showErrorUi(ReduxErrorUiOption.Dialog, errorObject, url);
        return;
      }
    }
  }
  private _showErrorUi(uiOption: ReduxErrorUiOption, errorObject, url) {
    if (uiOption == ReduxErrorUiOption.Dialog) {
      const initialState = {
        errorObject,
        url,
        list: [],
        title: 'Error'
      };
      // This is for validation errors and conflict errors - basically errors that shouldn't send you to the error page
      this.presentErrorDialog(initialState);
    } else {
      this._showingError = false;

      this.navigateWithRetry('/error');
    }
  }
  presentErrorDialog(parentData: any) {
    const appDialogUiObject = this._injector.get(AppDialogUiReduxObject);
    const store = this._injector.get(Store);

    store.dispatch(
      appDialogUiObject.default.actions.openModalDialog({
        payload: {
          component: ErrorDialogComponent.ClassId,
          buttons: ConfirmationDialogButton.Ok,
          okBtnName: 'OK',
          parentData
        }
      })
    );
  }
}
