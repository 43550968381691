import { Injectable } from '@angular/core';
import { SafariRequired } from '@safarilaw-webapp/shared/crud';
import cloneDeep from 'lodash-es/cloneDeep';

import { ApiCallContext } from '@safarilaw-webapp/shared/common-objects-models';
import { CreateSubmissionBatchModel, SubmissionBatchModel } from '../../_rms-api-models.generated';
import { RmsDataAdapter } from '../../shared/rms-base-adapter';
import { SubmissionAdapter } from '../../submission/adapters/submission-adapter';
import { SubmissionAdapterContext } from '../../submission/adapters/submission-context';
import { SubmissionBatch } from '../models/app/submission-batch';

@Injectable({
  providedIn: 'root'
})
export class SubmissionBatchAdapter extends RmsDataAdapter<SubmissionBatch> {
  constructor(private _submissionAdapter: SubmissionAdapter) {
    super();
  }
  fromGetModel(model: SubmissionBatchModel, context?: ApiCallContext<any>): SafariRequired<SubmissionBatch> {
    return {
      companyId: this.helpers.fromApiId(model.companyId),

      dateOfSubmission: this.helpers.fromApiDate(model.dateOfSubmission),
      id: this.helpers.fromApiId(model.id),
      intakeFormId: undefined,
      intakePortalId: this.helpers.fromApiId(model.intakePortalId),

      matterSubtypeDisplayName: model.matterSubtypeDisplayName,
      matterSubtypeId: this.helpers.fromApiId(model.matterSubtypeId),
      matterType: model.matterType,

      status: model.status,
      submitterName: model.submitterName,
      temporaryFileIds: undefined,
      values: undefined,
      children: undefined,
      consents: undefined,
      submissions: model.submissions ? model.submissions.map(x => this._submissionAdapter.fromGetModel(x)) : [],
      acknowledgmentOfReceipt: model.acknowledgmentOfReceipt
        ? {
            id: this.helpers.fromApiId(model.acknowledgmentOfReceipt.id),
            contentType: model.acknowledgmentOfReceipt.contentType,
            sizeInBytes: model.acknowledgmentOfReceipt.sizeInBytes,
            name: model.acknowledgmentOfReceipt.name
          }
        : null
    };
  }
  toCreateModel(model: SubmissionBatch, constext: SubmissionAdapterContext): SafariRequired<CreateSubmissionBatchModel> {
    const dateFieldNames: string[] = constext.dateFieldNames ?? ([] as string[]);
    const apiModel = {
      values: cloneDeep(model.values),
      submitterName: model.submitterName,
      consents: model.consents,
      children: cloneDeep(model.children),
      intakeFormId: this.helpers.toApiId(model.intakeFormId),
      intakePortalId: this.helpers.toApiId(model.intakePortalId),
      temporaryFileIds: model.temporaryFileIds
    };

    for (const value of apiModel.values) {
      if (dateFieldNames.includes(value.field.toString())) {
        value.value = this.helpers.toApiDate(value.value);
      }
    }

    for (const child of apiModel.children) {
      for (const value of child.values) {
        if (dateFieldNames.includes(value.field.toString())) {
          value.value = this.helpers.toApiDate(value.value);
        }
      }
    }

    return apiModel;
  }
}
