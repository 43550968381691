import { Injectable } from '@angular/core';
import { ApiCallContext, SafariObject, SafariObjectId } from '@safarilaw-webapp/shared/common-objects-models';
import { SafariRequired } from '@safarilaw-webapp/shared/crud';
import { v4 as uuidv4 } from 'uuid';
import { IntakeFormModel, UpdateIntakeFormModel } from '../../_rms-api-models.generated';
import { IntakeFormLayoutWidgetType } from '../../enums';
import { RmsDataAdapter } from '../../shared/rms-base-adapter';
import { IntakeFormLayoutField, IntakePortalForm } from '../models/app/intake-portal-form';
@Injectable({
  providedIn: 'root'
})
export class IntakePortalFormAdapter extends RmsDataAdapter<IntakePortalForm> {
  toCreateModel(model: IntakePortalForm, context: ApiCallContext = null): SafariRequired<UpdateIntakeFormModel> {
    return {
      consents: model.consents,
      childRelationships: model.childRelationships,
      fields: model.fields,
      matterSubtypeDisplayName: model.matterSubtypeDisplayName,
      allowBatchSubmission: model.allowBatchSubmission,
      matterSubtypeId: this.helpers.toApiId(model.matterSubtypeId),
      matterType: model.matterType,
      acknowledgmentCoverLetterTemplate: model.acknowledgmentCoverLetterTemplate
    };
  }
  toUpdateModel(model: IntakePortalForm, context: ApiCallContext = null): SafariRequired<UpdateIntakeFormModel> {
    return {
      consents: model.consents,
      childRelationships: model.childRelationships,
      fields: model.fields,
      allowBatchSubmission: model.allowBatchSubmission,
      matterSubtypeDisplayName: model.matterSubtypeDisplayName,
      matterSubtypeId: this.helpers.toApiId(model.matterSubtypeId),
      matterType: model.matterType,
      acknowledgmentCoverLetterTemplate: model.acknowledgmentCoverLetterTemplate
    };
  }

  fromGetModel(model: IntakeFormModel, context?: ApiCallContext<any>): SafariRequired<IntakePortalForm> {
    const retVal = {
      id: this.helpers.fromApiId(model.id),
      consents: model.consents,
      childRelationships: model.childRelationships,
      fields: model.fields,
      matterSubtypeDisplayName: model.matterSubtypeDisplayName,
      matterSubtypeId: this.helpers.fromApiId(model.matterSubtypeId),
      matterType: model.matterType,
      allowBatchSubmission: model.allowBatchSubmission,
      acknowledgmentCoverLetterTemplate: model.acknowledgmentCoverLetterTemplate,
      layout: null
    };

    return retVal;
  }
  _findLayoutFieldByIdAndType(id: SafariObjectId, type: IntakeFormLayoutWidgetType, layout: IntakeFormLayoutField, fields: IntakeFormLayoutField[]) {
    if (id != null) {
      if (SafariObject.idEqual(id, layout.id) && layout.type == type) {
        return layout;
      }
    } else {
      if (layout.type == type) {
        fields.push(layout);
      }
    }

    for (const field of layout.widgets) {
      const retVal = this._findLayoutFieldByIdAndType(id, type, field, fields);
      if (retVal && id != null) {
        return retVal;
      }
    }

    return null;
  }
  fromListModel(model: IntakeFormModel, context: ApiCallContext = null): SafariRequired<IntakePortalForm> {
    const retVal = {
      id: this.helpers.fromApiId(model.id),
      consents: model.consents,
      childRelationships: model.childRelationships,
      fields: model.fields,
      matterSubtypeDisplayName: model.matterSubtypeDisplayName,
      matterSubtypeId: this.helpers.fromApiId(model.matterSubtypeId),
      matterType: model.matterType,
      allowBatchSubmission: model.allowBatchSubmission,
      acknowledgmentCoverLetterTemplate: model.acknowledgmentCoverLetterTemplate,
      layout: model.layout
    };

    const allElements: IntakeFormLayoutField[] = [];
    this._findLayoutFieldByIdAndType(null, IntakeFormLayoutWidgetType.Element, retVal.layout, allElements);

    for (const element of allElements) {
      (element.id as number) += 9000;
    }
    const items = JSON.parse(localStorage.getItem('Safari_Dev_Form_Overrides'));

    if (items) {
      const formOverride = items.find(x => SafariObject.idEqual(x.id, retVal.id));
      if (formOverride != null) {
        retVal.layout = formOverride.layout;
      }
    }

    this._appendIds(retVal.layout.widgets);

    return retVal;
  }

  private _appendIds(list: IntakeFormLayoutField[], parentId = null) {
    list.forEach(element => {
      if (element) {
        if (!element.uuid) {
          element.uuid = uuidv4();
        }

        element.parentUuid = parentId;
        if (element.widgets?.length) {
          this._appendIds(element.widgets, element.uuid);
        }
      }
    });
  }
}
