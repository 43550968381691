import { Injectable } from '@angular/core';
import { ApiDropdownAdapter } from '@safarilaw-webapp/shared/crud';
import { FeatureFlagsService } from '@safarilaw-webapp/shared/environment';
import { DropdownMatterSubtype } from '../models/app/dropdown-matter-subtype';

@Injectable({
  providedIn: 'root'
})
export class DropdownMatterSubtypeAdapter extends ApiDropdownAdapter {
  constructor(private _featureFlagService: FeatureFlagsService) {
    super();
  }

  fromListModel(model: any): Required<DropdownMatterSubtype> {
    return {
      ...super.fromListModel(model),
      legalAuthorityTypeSettingsPicklists: model.legalAuthorityTypeSettingsPicklists,
      responseDocumentTypeSettingsPicklists: model.responseDocumentTypeSettingsPicklists,
      civilCaseSubtypeSettingsPicklists: model.civilCaseSubtypeSettingsPicklists,
      criminalCaseSubtypeSettingsPicklists: model.criminalCaseSubtypeSettingsPicklists,
      regulatoryCaseSubtypeSettingsPicklists: model.regulatoryCaseSubtypeSettingsPicklists,
      otherCaseSubtypeSettingsPicklists: model.otherCaseSubtypeSettingsPicklists,
      civilCaseSubtypeSettingsEnabled: model.civilCaseSubtypeSettingsEnabled,
      criminalCaseSubtypeSettingsEnabled: model.criminalCaseSubtypeSettingsEnabled,
      otherCaseSubtypeSettingsEnabled: model.otherCaseSubtypeSettingsEnabled,
      regulatoryCaseSubtypeSettingsEnabled: model.regulatoryCaseSubtypeSettingsEnabled
    };
  }
}
