import { Injectable } from '@angular/core';
import { ApiCallContext, AttachmentLink, SafariObjectId } from '@safarilaw-webapp/shared/common-objects-models';
import { ApiDataAdapter, SafariRequired } from './adapter';

class DownloadLinkModel {
  id?: SafariObjectId | undefined;
  name?: string | undefined;
  contentType?: string | undefined;
  sizeInBytes?: number | undefined;
  linkUrl?: string | undefined;
  dateLinkExpires?: string;
  durationInMinutes?: number | undefined;
  openInBrowser?: boolean | undefined;
}

/**
 * Having common AttachmentLinkAdapter in crud service is probably not the most ideal location
 * but it's the best place to put it for now.
 * Ideally it would be in something like common-object-models but that should be the lowest
 * project in hierarchy and we can't put it there without introducing reference back to crud service
 * since crud service defines API adapter that this one inherits from.
 * Being that this is the only case of shared adapter so far we can keep it here.
 * Should we have more in the future we might want to look into refactoring the base
 * ApiAdapter out of crud into commonobjectmodels , then shared adapters there as well,
 *
 */

@Injectable({
  providedIn: 'root'
})
export class AttachmentLinkAdapter extends ApiDataAdapter<AttachmentLink> {
  toCreateModel(model: AttachmentLink, context?: ApiCallContext<any>): SafariRequired<DownloadLinkModel> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- The filters model will vary depending on what report is being run
    const filters = {
      ...model.filters,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- The filters model will vary depending on what report is being run
      startDate: model.filters?.startDate ? this.helpers.toApiDate(model.filters.startDate) : undefined,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- The filters model will vary depending on what report is being run
      endDate: model.filters?.endDate ? this.helpers.toApiDate(model.filters.endDate) : undefined
    };

    return {
      ...filters,
      durationInMinutes: model.durationInMinutes,
      openInBrowser: model.openInBrowser,
      name: undefined,
      contentType: undefined,
      dateLinkExpires: undefined,
      linkUrl: undefined,
      sizeInBytes: undefined,
      attachmentIds: model.attachmentIds?.map(x => this.helpers.toApiId(x))
    } as SafariRequired<DownloadLinkModel>;
  }
  fromGetModel(model: DownloadLinkModel, context?: ApiCallContext<any>): SafariRequired<AttachmentLink> {
    return {
      contentType: model.contentType,
      dateLinkExpires: model.dateLinkExpires,
      durationInMinutes: undefined,
      id: this.helpers.fromApiId(model.id),
      linkUrl: model.linkUrl,
      name: model.name,
      sizeInBytes: model.sizeInBytes,
      openInBrowser: undefined,
      filters: undefined,
      attachmentIds: undefined
    };
  }
}
