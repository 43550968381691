import { NavbarGroup, NavbarHeader, NavbarItem, NavbarService } from '@safarilaw-webapp/shared/app-bootstrap';
import { map, Observable, of } from 'rxjs';

export class RmsNavbarService extends NavbarService {
  getNavbarItems(): Observable<(NavbarGroup | NavbarHeader | NavbarItem)[]> {
    return of(null).pipe(
      map(() => {
        const items: (NavbarGroup | NavbarHeader | NavbarItem)[] = [];
        this.filterNavbarCollectionByPermissions(this.navbarCollection, items);
        return items;
      })
    );
  }
}
