// TODO: This will eventually become an object that will be default for all API IDs in the system (story 4601)

import { SafariObjectId } from '../../base-object/models/app/safari-object-base';

// For now this is a stub that is referenced by some functions to fix a bug with converting a parent array to /x/y/z/ url string
export const ObjectId = (parentId: SafariObjectId): string => {
  if (Array.isArray(parentId)) {
    return parentId.join('/');
  }
  return parentId.toString();
};
