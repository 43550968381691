import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { SafariRouterReduxObject } from '@safarilaw-webapp/shared/routing-utility';

import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AuthReduxObject } from '@safarilaw-webapp/shared/common-objects-models';
import { AppDialogUiReduxObject, DialogBaseComponent } from '@safarilaw-webapp/shared/dialog';
import { AppConfigurationService } from '@safarilaw-webapp/shared/environment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LogoutReason, STORAGE_AUTH_LAST_ACTIVITY_TIMESTAMP, STORAGE_AUTH_LOGOUT_REASON } from '../../services/auth/auth.service';

@Component({
  standalone: true,
  providers: [AppDialogUiReduxObject, SafariRouterReduxObject, AuthReduxObject],
  selector: 'safarilaw-webapp-autologout-dialog',
  templateUrl: './autologout-dialog.component.html',
  styleUrls: ['./autologout-dialog.component.scss']
})
export class AutologoutDialogComponent extends DialogBaseComponent implements OnInit, OnDestroy {
  static ClassId = 'AUTOLOGOUT_DIALOG_7ca398f4-6af4-4053-8d50-df9d09881abe';
  isCollapsed = true;
  #logoutTimer: number;
  constructor(
    dialogReduxObject: AppDialogUiReduxObject,
    bsModalService: BsModalService,
    store: Store<any>,
    actions: Actions,
    bsModalRef: BsModalRef,
    private _appConfig: AppConfigurationService,
    private _safariRouterReduxObject: SafariRouterReduxObject,
    private _authReduxObject: AuthReduxObject,
    private _ngZone: NgZone
  ) {
    super(dialogReduxObject, bsModalService, store, actions, bsModalRef);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.scheduleLogoutTimeout();
  }

  scheduleLogoutTimeout() {
    // Poll to see whether the user has been active, if the dialog should no longer be shown (outside the warning period),
    // or if the timeout has expired and they should be logged out
    this._ngZone.runOutsideAngular(() => {
      clearTimeout(this.#logoutTimer);

      if (this._appConfig.auth.inactivityTimeout && this._appConfig.auth.inactivityWarning) {
        const lastActivityTimestamp = +localStorage.getItem(STORAGE_AUTH_LAST_ACTIVITY_TIMESTAMP) || Date.now();
        const timeoutTimestamp = lastActivityTimestamp + 1000 * 60 * this._appConfig.auth.inactivityTimeout;
        const warningTimestamp = timeoutTimestamp - 1000 * 60 * this._appConfig.auth.inactivityWarning;

        const shouldLogoutReason = localStorage.getItem(STORAGE_AUTH_LOGOUT_REASON);
        const isExpired = Date.now() >= timeoutTimestamp;

        if (isExpired || shouldLogoutReason) {
          this.logout(isExpired || shouldLogoutReason === LogoutReason.Inactivity.toString());
        } else if (Date.now() >= warningTimestamp) {
          // In the warning period - Continue to show the dialog and check back in a second
          this.#logoutTimer = setTimeout(() => {
            this.scheduleLogoutTimeout();
          }, 1000) as unknown as number;
        } else {
          // We're not expired or in the warning period, so just close the dialog.
          this.dismissModal();
        }
      }
    });
  }

  override ngOnDestroy(): void {
    clearTimeout(this.#logoutTimer);
    super.ngOnDestroy();
  }

  get timeoutMinutes(): number {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access -- Untyped input data
    return this.data['timeout'];
  }

  dismissModal() {
    this._store.dispatch(this._authReduxObject.default.actions.updateLastActivityTimestamp());
    clearTimeout(this.#logoutTimer);
    super.closeModal();
  }

  logout(isAutomaticLogout: boolean = false) {
    // Signal to other tabs that they should also log out
    localStorage.setItem(STORAGE_AUTH_LOGOUT_REASON, isAutomaticLogout ? LogoutReason.Inactivity.toString() : LogoutReason.Manual.toString());

    clearTimeout(this.#logoutTimer);
    super.closeModal();
    // Set checkDiry to false. Right after this there will be a global unload check
    // that will take a look at this flag first in order to decide whether it should pop
    // browsers "are you sure you want to leave". We don't want to give that choice here.
    this._store.dispatch(
      this._safariRouterReduxObject.default.actions.routerNavigate({
        payload: {
          path: ['/logout'],
          checkDirty: false
        }
      })
    );
  }
}
