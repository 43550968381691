/**
 * We'll eventually cut the one out of redux and repoint here - don't want to get bogged down
 * with that now
 */

import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ApiCallContext } from '../crud/models/context';

export enum ActionSilenceErrorMode {
  All,
  InList,
  NotInList
}

export class ActionSilenceErrorOption {
  /** Silence error mode -
   * All - No errors will be raised
   * InList - Silence only the errors whose status matches the one in errors[] array of these option
   * NotInList - Silence only errors that are not found in errors[] array of these option
   * */
  mode: ActionSilenceErrorMode;
  errors?: number[];
}
export class ActionOptions {
  silenceErrors?: ActionSilenceErrorOption;
  maxValidationErrors?: number;
}
class CommonActionProps {
  /** Used for correlated effects only */
  correlationId?: string;
  /** Unique ID of this action (not payload!)*/
  actionId?: string;
}
export class ActionInfoBase extends CommonActionProps {
  abort?: string;
  /** I think his needs to be replaced with dedicated abort actions. Maybe ?  */
  /** Loosely typed property bag. Usually used to pass something special to the service that deals with this object */
  context?: ApiCallContext;
  /** Advanced options for this action */
  options?: ActionOptions;
}
/** Base error action - thrown from effects usually  */
export class ActionErrorBase<T = any> extends CommonActionProps {
  /** Error that came back */
  error?: HttpErrorResponse;
  /** Original payload */
  payload?: T & { __onErrorMergeWith?: any };

  /** Error options - show to the user, must be resolved, etc  */
  reduxErrorOptions?: ReduxError;

  /** Original payload as sent to the initiating action  */
  originalPayload?: T;
  /** Original additional info as sent to the initiating action  */
  context?: any;
  /** Original options as sent to the initiating action  */
  originalOptions?: ActionOptions;
}

export enum ReduxErrorUiOption {
  Dialog,
  ErrorPage
}
export class ReduxError {
  source: string;
  url?: string;
  silent?: boolean;
  mustResolve?: boolean;
  uiOption?: ReduxErrorUiOption;
  maxValidationErrors?: number;
}

export const reduxDebugAction = createAction('[Redux] Redux Debug Action', props<{ message: string }>());

export enum GenericReduxActions {
  ReduxActionFail = '[Shared] Action Error',

  PurgeAllObjectErrors = '[Shared] Purge All Object Errors',
  ObjectNotFoundError = '[Shared] Object Not Found Error',
  ClearObjectSavingCounter = '[Shared] Clear Object Saving Counter',
  ClearAllObjectStates = '[Shared] Clear All Object States',
  DeserializeFromState = '[Shared] Deserialize From State'
}

export const reduxActionFail = createAction(GenericReduxActions.ReduxActionFail, props<ActionErrorBase>());
export const purgeAllObjectErrors = createAction(GenericReduxActions.PurgeAllObjectErrors);
export const objectNotFoundError = createAction(GenericReduxActions.ObjectNotFoundError);
export const clearAllObjectStates = createAction(GenericReduxActions.ClearAllObjectStates);
export const deserializeFromState = createAction(GenericReduxActions.DeserializeFromState);
