import { Injectable } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { UI_REDUCER } from '@safarilaw-webapp/shared/redux';
import { SafariRouterReduxObject } from '../router/redux/state';
import { IRouterFeatureState } from '@safarilaw-webapp/shared/common-objects-models';

@Injectable({
  providedIn: 'root'
})
export class RouterReducerService {
  constructor(private _routerReduxObject: SafariRouterReduxObject) {}
  get reducers() {
    const reducers: ActionReducerMap<IRouterFeatureState> = {
      routerState: UI_REDUCER(this._routerReduxObject)
    };
    return reducers;
  }
}
