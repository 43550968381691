import { Injectable } from '@angular/core';
import { ApiCallContext } from '@safarilaw-webapp/shared/common-objects-models';
import { SafariRequired } from '@safarilaw-webapp/shared/crud';
import { ProfileModel, UpdateProfileModel } from '../../_rms-api-models.generated';
import { RmsDataAdapter } from '../../shared/rms-base-adapter';
import { Profile } from '../models/app/profile';

@Injectable({
  providedIn: 'root'
})
export class ProfileAdapter extends RmsDataAdapter<Profile> {
  toCreateModel(model: Profile, context: ApiCallContext = null): SafariRequired<UpdateProfileModel> {
    return {
      name: model.name,
      orgAddress: model.orgAddress,
      orgName: model.orgName,
      orgType: model.orgType,
      phoneNumbers: model.phoneNumbers
    };
  }
  toUpdateModel(model: Profile, context: ApiCallContext = null): SafariRequired<UpdateProfileModel> {
    return {
      name: model.name,
      orgAddress: model.orgAddress,
      orgName: model.orgName,
      orgType: model.orgType,
      phoneNumbers: model.phoneNumbers
    };
  }

  fromGetModel(model: ProfileModel, context?: ApiCallContext<any>): SafariRequired<Profile> {
    return {
      email: model.email,
      name: model.name,
      orgAddress: this.helpers.fromApiAddress(model.orgAddress),
      orgName: model.orgName,
      orgType: model.orgType,
      authUserId: undefined,
      id: this.helpers.fromApiId(model.id),
      phoneNumbers: model.phoneNumbers as any
    };
  }
}
