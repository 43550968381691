import { combineReducers, createSelector } from '@ngrx/store';
import { IFileUploadState, safariReduxFileTransferGenerator } from '@safarilaw-webapp/shared/redux';
import { getSharedFeatureState, PROJECT_NAME } from './state.interface';

const getMyState = createSelector(getSharedFeatureState, state => state.fileCombinedState.fileState);

const initStateFileUpload: IFileUploadState = {
  files: [],
  filePreview: null,
  filePreviewResponse: null
};

export const fileTransferReduxObject = safariReduxFileTransferGenerator(PROJECT_NAME).addAllActions().addState(initStateFileUpload).addSelectors(getMyState).finalize();

export const combinedReducers = combineReducers({
  fileState: fileTransferReduxObject.reducer
});
