import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, mergeMap, of } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token$: Observable<string | boolean>;
    if (this._authService.isAuthenticated() && this._authService.isTokenExpiring()) {
      token$ = this._authService.requestNewTokenForErrorHandler$();
    } else {
      token$ = of(this._authService.getAccessToken());
    }

    return token$.pipe(
      mergeMap(accessToken => {
        let authString: string;
        if (accessToken != null) {
          authString = `Bearer ${accessToken.toString()}`;
        } else {
          // This should never happen - if it did we'd be getting "cannot read length" from NG
          // due to headers being undefined
          authString = 'N-A';
        }
        let headers = req.headers.set('Cache-Control', 'no-cache').set('Pragma', 'no-cache').set('Expires', 'Thu, 01 Jan 1970 00:00:00 GMT').set('If-Modified-Since', '0');
        // Only add auth header if we are not asking not to (X-NoAuth) AND there isn't one present already
        if (!req.headers.has('X-NoAuth-Injection') && !req.headers.has('Authorization')) {
          headers = headers.set('Authorization', authString);
        }
        req = req.clone({
          headers
        });
        return next.handle(req);
      })
    );
  }

  constructor(private _authService: AuthService) {}
}
