// prettier-ignore
export class NavbarItem {
  constructor(
    public id: number,
    public parentId: number,
    public title: string,
    public link: string,
    public permissionFunction: string[] = null,
    /** Setting this to true fully removes the item (link, text, etc) and replaces it completely with
     * an HR divider. Not to be combined with normal Navbar Items.
     */
    public isDivider: boolean = false
  ) {}
}
