import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AnyFn } from '@ngrx/store/src/selector';
import { SafariObject } from '@safarilaw-webapp/shared/common-objects-models';
import { catchError, map, mergeMap, Observable, of, Subscriber, throwError } from 'rxjs';

export class FileDownloadInfo {
  blobParts: ArrayBuffer[] = [];
  pctDone = 0;
  error = null;
  blobUrl: string = null;
  fileType = '';
  filePreview: SafeResourceUrl = null;
  filePreviewSrc: string = null;
  additionalInfo?: any = null;
}
@Injectable({
  providedIn: 'root'
})
export class FileDownloadService {
  constructor(private _sanitizer: DomSanitizer) {}

  isChrome() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- JS stuff
    return !!window['chrome'] && (!!window['chrome']['webstore'] || !!window['chrome']['runtime']);
  }

  revokeUrl(fileDownloadInfo: FileDownloadInfo) {
    if (fileDownloadInfo && fileDownloadInfo.blobUrl) {
      URL.revokeObjectURL(fileDownloadInfo.blobUrl);
    }
  }

  resetFileProperties(fileDownloadInfo: FileDownloadInfo) {
    if (!fileDownloadInfo) {
      return;
    }
    fileDownloadInfo.blobParts = [];
    fileDownloadInfo.pctDone = 0;
    fileDownloadInfo.error = null;
    fileDownloadInfo.fileType = '';
    this.revokeUrl(fileDownloadInfo);
    fileDownloadInfo.filePreview = null;
    fileDownloadInfo.blobUrl = null;
  }
  fileBodyFromFileObservable(fileObservable$: Observable<HttpResponse<Blob> | HttpErrorResponse>) {
    return fileObservable$.pipe(
      mergeMap(response => {
        if (response && response instanceof HttpResponse) {
          return this.loadFileAsDataUrlObservable(response.body, null);
        } else {
          return throwError(() => response);
        }
      }),
      map((o: FileDownloadInfo) => o.filePreview as File),

      catchError(() => of(SafariObject.EMPTY as File))
    );
  }
  loadFileAsDataUrlObservable(file: Blob, additionalInfo, preventHtmlExecution = true): Observable<FileDownloadInfo> {
    return new Observable((observer: Subscriber<FileDownloadInfo>) => {
      let fileDownloadInfo: FileDownloadInfo = null;

      const onSuccess = (o: FileDownloadInfo) => {
        fileDownloadInfo = o;
      };

      const onFail = (o: FileDownloadInfo) => {
        fileDownloadInfo = o;
      };
      this.loadFileAsDataUrl(file, this, onSuccess, onFail, additionalInfo, preventHtmlExecution);
      const interval = setInterval(() => {
        if (fileDownloadInfo != null) {
          if (fileDownloadInfo.error != null) {
            observer.error(fileDownloadInfo.error);
            clearInterval(interval);
          } else {
            observer.next(fileDownloadInfo);
            observer.complete();
            clearInterval(interval);
          }
        }
      });
    });
  }
  loadFileAsDataUrl(file: Blob, thisPtr: any, successCallback: AnyFn, errorCallback: AnyFn, additionalInfo: unknown = null, preventHtmlExecution = true) {
    if (file == null) {
      if (errorCallback != null) {
        if (thisPtr != null) {
          errorCallback.call(thisPtr, { error: 'File is null' });
        } else {
          errorCallback({ error: 'File is null' });
        }
      }
      return;
    }
    const fileDownloadInfo = {
      blobParts: [],
      pctDone: 0,
      error: null,
      fileType: '',
      additionalInfo
    } as FileDownloadInfo;

    let fileType = file.type;
    // prevent HTML/JS execution for plain files (text/html, text/js, etc)
    // Just convert all text files to plain.
    if (preventHtmlExecution && fileType.startsWith('text/')) {
      fileType = 'text/plain';
    }
    // Any missing file types will be treated as octet stream as this is what they utlimately
    // will end up with once they get saved
    if (fileType == null || fileType == '') {
      fileType = 'application/octet-stream';
    }
    fileDownloadInfo.pctDone = 100;
    fileDownloadInfo.fileType = fileType;
    //  const blob = new Blob(fileDownloadInfo.blobParts, { type: fileType });
    fileDownloadInfo.blobUrl = URL.createObjectURL(file);
    fileDownloadInfo.filePreview = this._sanitizer.bypassSecurityTrustResourceUrl(fileDownloadInfo.blobUrl);
    fileDownloadInfo.filePreviewSrc = this._sanitizer.sanitize(SecurityContext.RESOURCE_URL, fileDownloadInfo.filePreview);

    if (successCallback && thisPtr != null) {
      successCallback.call(thisPtr, fileDownloadInfo);
    } else {
      successCallback(fileDownloadInfo);
    }
    return;
  }

  parseFile(file: Blob, callback: (number, unknown) => void) {
    const fileSize = file.size;
    const chunkSize = 64 * 1024; // bytes
    let offset = 0;

    const chunkReaderBlock = (_offset: number, length: number, _file: Blob) => {
      const r = new FileReader();
      const blob = _file.slice(_offset, length + _offset);
      r.onload = readEventHandler;
      r.readAsArrayBuffer(blob);
    };

    const readEventHandler = (evt: ProgressEvent<EventTarget & { error: Error; result: unknown }>) => {
      if (evt.target.error == null) {
        offset += (evt.target.result as { byteLength: number }).byteLength;
        const pct: number = fileSize > 0 ? (offset / fileSize) * 100 : 100;
        callback(pct, evt.target.result);
      } else {
        callback(100, { error: evt.target.error });
        return;
      }
      if (offset >= fileSize) {
        return;
      }
      chunkReaderBlock(offset, chunkSize, file);
    };

    // now let's start the read with the first block
    chunkReaderBlock(offset, chunkSize, file);
  }
}
