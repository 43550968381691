import { Injectable } from '@angular/core';
import { createSelector, on, props } from '@ngrx/store';
import { SafariObject, SafariObjectId } from '@safarilaw-webapp/shared/common-objects-models';
import { FromActionBehavior, ObjectWithActionInfo, SafariReduxCustomObject, SafariReduxUiObject, getObjectInArray } from '@safarilaw-webapp/shared/redux';
import { Accordion, AccordionController } from '../../forms/components/safari-accordion/safari-accordion/safari-accordion.component';
import { TableItem } from '../../list/components/safari-list/safari-list.component';
import {
  FormSubmitInfo,
  IAccordionControllerState,
  IAccordionState,
  IAppControlState,
  IComponentState,
  IFilePreviewState,
  IFormState,
  ITabState,
  ITableState,
  Table,
  TableSubmitInfo
} from '../interfaces/layout-interface';
import { getAppControlState } from '../state.interface';

export class UiActionInfo {
  id: SafariObjectId;
  value?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SafariUiFilePreviewReduxObject extends SafariReduxCustomObject<IFilePreviewState> {
  default = {};
  requestFilePreview = super.addMessage('Preview File Request', state => state.filePreviewRequest, { filePreviewRequest: this.fromAction(FromActionBehavior.NoNullExpansion) });
  previewFileEdit = super.addMessage('Preview File Edit', state => state.filePreviewEdit, { filePreviewEdit: this.fromAction() });
  previewFileClose = super.addMessage('Preview File Close', state => state.filePreviewClose, { filePreviewClose: this.fromAction() });

  constructor() {
    super(
      'UiKit',
      'FileViewer',
      createSelector(getAppControlState, (state: IAppControlState) => state.filePreviewState)
    );

    this.addState({
      filePreviewClose: null,
      filePreviewEdit: null,
      filePreviewRequest: null
    }).finalize();
  }
  protected getReducers(ons) {
    super.getReducers(ons);
  }
}
@Injectable({
  providedIn: 'root'
})
export class SafariUiDataTableReduxObject extends SafariReduxUiObject<ITableState, Table> {
  // TODO: Follow example in matterpage redux object to combine into action/selector and get rid of default
  default = {
    actions: {
      tableUpdateFilter: super.createAction('Update Filter', props<{ payload: { id: string; filter: any } }>()),
      tableClearFilter: super.createAction('Clear Filter', props<{ id: string }>()),
      tableUpdateSort: super.createAction('Update Sort', props<{ payload: { id: string; sort: string } }>()),
      tableUpdateSelected: super.createAction('Update Selected', props<{ payload: { id: string; selected: any[] } }>()),
      tableClearSelected: super.createAction('Clear Selected', props<{ id: string }>()),
      tableClearCount: super.createAction('Clear Count', props<{ id: string }>()),
      tableClearSort: super.createAction('Clear Sort', props<{ id: string }>()),
      tableUpdatePage: super.createAction('Update Page', props<{ payload: { id: string; page: number } }>()),
      tableUpdateInit: super.createAction('Update Init', props<{ payload: { id: string; initialized: boolean } }>()),
      tableUpdateTotalCount: super.createAction('Update Total Count', props<{ payload: { id: string; totalCount: number } }>()),
      tableClearPage: super.createAction('Clear Page', props<{ id: string }>()),
      tableParameterUserChange: super.createAction('Params User Change', props<{ payload: { id: string; page: number; sort: string; filter: any } }>()),
      tableGoToByRowId: super.createAction('Go To By Id', props<{ payload: { id: string; rowId: string } }>()),
      tableItemStatusChanged: super.createAction('Item Status Changed', props<{ payload: { id: string; item: TableItem<any> } }>()),
      tableRequestSubmit: super.createAction('Request Submit', props<{ id: string; additionalInfo: any }>()),
      tableSubmit: super.createAction('Submit', props<{ payload: TableSubmitInfo }>()),
      tableSubmitSuccess: super.createAction('Submit Success', props<{ payload: TableSubmitInfo }>()),
      tableSubmitFail: super.createAction('Submit Fail', props<{ error: any; payload: TableSubmitInfo }>()),
      setReadyToDisplay: super.createAction('Set Ready To Display', props<{ payload: { id: string; readyToDisplay: boolean } }>()),
      tableClearState: super.createAction('Clear State', props<{ id: string }>())
    },
    selectors: {
      getListState: (id: string) => createSelector(getAppControlState, (state: IAppControlState) => state.listState.tables.find(o => o.id === id)),
      getListFilterState: (id: string) =>
        createSelector(getAppControlState, (state: IAppControlState) => {
          const list = state.listState.tables.find(o => o.id === id);
          if (list == null) {
            return null;
          }
          return list.filter;
        }),
      getListPageState: (id: string) =>
        createSelector(getAppControlState, (state: IAppControlState) => {
          const list = state.listState.tables.find(o => o.id === id);
          if (list == null) {
            return null;
          }
          return list.page;
        }),
      getListSortState: (id: string) =>
        createSelector(getAppControlState, (state: IAppControlState) => {
          const list = state.listState.tables.find(o => o.id === id);
          if (list == null) {
            return null;
          }
          return list.sort;
        }),
      getListTotalCount: (id: string) =>
        createSelector(getAppControlState, (state: IAppControlState) => {
          const list = state.listState.tables.find(o => o.id === id);
          if (list == null) {
            return undefined;
          }
          return list.totalCount;
        }),
      getListInitStatus: (id: string) =>
        createSelector(getAppControlState, (state: IAppControlState) => {
          const list = state.listState.tables.find(o => o.id === id);
          if (list == null) {
            return null;
          }
          return list.initialized;
        }),
      getListSelectedItems: (id: string) =>
        createSelector(getAppControlState, (state: IAppControlState) => {
          const list = state.listState.tables.find(o => o.id === id);
          if (list == null) {
            return undefined;
          }
          return list.selected;
        })
    },
    reducers: ons => {
      ons.push(
        on(this.default.actions.tableUpdateFilter, (state: ITableState, action) => {
          if (action.payload.id == null) {
            return { ...state };
          }
          const tables = this.modifyObjectInArray(
            state.tables,
            {
              ...action.payload,
              page: 0
            } as ObjectWithActionInfo,
            null,
            true,
            true
          );
          return { ...state, tables };
        }),
        on(this.default.actions.tableClearFilter, (state: ITableState, action) => {
          const tables = this.modifyObjectInArray(
            state.tables,
            {
              id: action.id,
              page: 0,
              filter: null
            } as ObjectWithActionInfo,
            null,
            false,
            true
          );
          return { ...state, tables };
        }),
        on(this.default.actions.tableUpdateSort, (state: ITableState, action) => {
          const tables = this.modifyObjectInArray(state.tables, action.payload, null, true, true);
          return { ...state, tables };
        }),
        on(this.default.actions.tableUpdateSelected, (state: ITableState, action) => {
          const tables = this.modifyObjectInArray(state.tables, action.payload, null, true, true);
          return { ...state, tables };
        }),
        on(this.default.actions.tableClearSelected, (state: ITableState, action) => {
          const tables = this.modifyObjectInArray(
            state.tables,
            {
              id: action.id,
              selected: []
            } as ObjectWithActionInfo,
            null,
            false,
            true
          );
          return { ...state, tables };
        }),
        on(this.default.actions.tableClearCount, (state: ITableState, action) => {
          const tables = this.modifyObjectInArray(
            state.tables,
            {
              id: action.id,
              totalCount: undefined
            } as ObjectWithActionInfo,
            null,
            false,
            true
          );
          return { ...state, tables };
        }),
        on(this.default.actions.tableClearSort, (state: ITableState, action) => {
          const tables = this.modifyObjectInArray(
            state.tables,
            {
              id: action.id,
              sort: null
            } as ObjectWithActionInfo,
            null,
            false,
            true
          );
          return { ...state, tables };
        }),
        on(this.default.actions.tableUpdatePage, (state: ITableState, action) => {
          const tables = this.modifyObjectInArray(state.tables, action.payload, null, true, true);
          return { ...state, tables };
        }),
        on(this.default.actions.tableUpdateTotalCount, (state: ITableState, action) => {
          const tables = this.modifyObjectInArray(state.tables, action.payload, null, true, true);
          return { ...state, tables };
        }),
        on(this.default.actions.tableUpdateInit, (state: ITableState, action) => {
          const tables = this.modifyObjectInArray(state.tables, action.payload, null, true, true);
          return { ...state, tables };
        }),
        on(this.default.actions.tableClearPage, (state: ITableState, action) => {
          const tables = this.modifyObjectInArray(
            state.tables,
            {
              id: action.id,
              page: 0
            } as ObjectWithActionInfo,
            null,
            false,
            true
          );
          return { ...state, tables };
        }),
        on(this.default.actions.tableClearState, (state: ITableState, action) => {
          const tables = this.removeObjectFromArray(
            state.tables,
            {
              id: action.id
            } as ObjectWithActionInfo,
            null
          );
          return { ...state, tables };
        })
      );
    }
  };
  constructor() {
    super('UiKit', 'Table');
    super.addState({ tables: [] }).finalize();
  }
}
@Injectable({
  providedIn: 'root'
})
export class SafariUiFormReduxObject<T = any> extends SafariReduxUiObject<IFormState, FormSubmitInfo<T>> {
  // TODO: Follow example in matterpage redux object to combine into action/selector and get rid of default
  default = {
    actions: {
      formRequestPatch: super.createAction('Request Patch', props<{ id: string; formValues: any }>()),
      formRequestSubmit: super.createAction('Request Submit', props<{ id: string; additionalInfo: any }>()),
      /** INTERNAL FORM ACTIONS
       * We'll probably need to find some way to hide them eventually so people don't try to call them by mistake
       */
      formUpdate: super.createAction('Update', props<{ payload: FormSubmitInfo<T> }>()),
      formReadyToPerist: super.createAction('Ready To Persist', props<{ payload: FormSubmitInfo<T> }>()),
      formSubmit: super.createAction('Submit', props<{ payload: FormSubmitInfo<T> }>()),
      formSubmitSuccess: super.createAction('Submit Success', props<{ payload: FormSubmitInfo<T> }>()),
      formSubmitFail: super.createAction('Submit Fail', props<{ error: any; payload: FormSubmitInfo<T> }>()),
      formClearFormData: super.createAction('Clear Form Data', props<{ id: string }>()),
      /** END INTERNAL FORM ACTIONS */

      cancelEdit: super.createAction('Cancel Requested')
    },
    selectors: {
      formState: (id: string) => createSelector(getAppControlState, (state: IAppControlState) => state.formState.forms.find(o => o.id === id))
    },
    reducers: ons => {
      ons.push(
        on(this.default.actions.formUpdate, (state: IFormState, action) => {
          const current = getObjectInArray(state.forms, action.payload, null);
          const forms = this.modifyObjectInArray(state.forms, { ...action.payload, readyToDisplay: current?.readyToDisplay } as ObjectWithActionInfo, null);
          return { ...state, forms };
        }),

        on(this.default.actions.formClearFormData, (state: IFormState, payload) => {
          const forms = this.removeObjectFromArray(state.forms, payload, null);
          return { ...state, forms };
        })
        // on(this.default.actions.formSubmitSuccess, (state: IFormState, payload) => {
        //   console.log('submit success')
        //   return { ...state };
        // })
      );
    }
  };
  constructor() {
    super('UiKit', 'Form');
    super.addState({ forms: [] }).finalize();
  }
}
@Injectable({
  providedIn: 'root'
})
export class SafariUiTabReduxObject extends SafariReduxUiObject<ITabState, any> {
  // TODO: Follow example in matterpage redux object to combine into action/selector and get rid of default
  default = {
    actions: {
      clearData: super.createAction('Clear', props<{ payload: { id: SafariObjectId } }>()),
      initialize: super.createAction('Initialize', props<any>()),
      setCurrentTab: super.createAction('Set Current Tab', props<{ payload: { id: SafariObjectId; tabId: SafariObjectId } }>())
    },
    selectors: {
      getCurrentTab: (id: SafariObjectId) => createSelector(getAppControlState, (state: IAppControlState) => state.tabState.tabs.find(o => SafariObject.idEqual(o.id, id))?.currentTab),
      state: (id: SafariObjectId) => createSelector(getAppControlState, (state: IAppControlState) => state.tabState.tabs.find(o => SafariObject.idEqual(o.id, id)))
    },
    reducers: ons => {
      ons.push(
        on(this.default.actions.initialize, (state: ITabState, action) => {
          const tabs = this.modifyObjectInArray(state.tabs, { ...action.payload } as ObjectWithActionInfo, null);
          return { ...state, tabs };
        }),

        on(this.default.actions.clearData, (state: ITabState, action: { payload: { id: SafariObjectId } }) => {
          const forms = this.removeObjectFromArray(state.tabs, action.payload, null);
          return { ...state, forms };
        }),
        on(this.default.actions.setCurrentTab, (state: ITabState, action: { payload: { id: SafariObjectId; tabId: SafariObjectId } }) => {
          const tabs = this.modifyObjectInArray(state.tabs, { id: action.payload.id, currentTab: action.payload.tabId } as ObjectWithActionInfo, null, true, true);

          return { ...state, tabs };
        })
      );
    }
  };
  constructor() {
    super('UiKit', 'Tab');
    super.addState({ tabs: [] }).finalize();
  }
}
@Injectable({
  providedIn: 'root'
})
export class SafariUiAccordionGroupReduxObject extends SafariReduxUiObject<IAccordionControllerState, AccordionController> {
  default = {
    actions: {
      accordionControllerToggled: super.createAction('Toggled', props<{ payload: UiActionInfo }>()),
      accordionControllerExpanded: super.createAction('Expanded', props<{ payload: UiActionInfo }>()),
      accordionControllerCollapsed: super.createAction('Collapsed', props<{ payload: UiActionInfo }>())
    },
    reducers: ons => {
      ons.push(
        on(this.default.actions.accordionControllerExpanded, (state: IAccordionControllerState, action) => {
          const accordionControllers = this.modifyObjectInArray(state.accordionControllers, { ...action.payload, isExpanded: true } as ObjectWithActionInfo, null, true, true);
          return { ...state, accordionControllers };
        }),
        on(this.default.actions.accordionControllerCollapsed, (state: IAccordionControllerState, action) => {
          const accordionControllers = this.modifyObjectInArray(state.accordionControllers, { ...action.payload, isExpanded: false } as ObjectWithActionInfo, null, true, true);
          return { ...state, accordionControllers };
        })
      );
    },
    selectors: {
      getState: (id: string) => createSelector(getAppControlState, (state: IAppControlState) => state.accordionControllerState.accordionControllers.find(o => o.id === id)),
      getIsExpanded: (id: string) => createSelector(getAppControlState, (state: IAppControlState) => state.accordionControllerState.accordionControllers.find(o => o.id === id)?.isExpanded == true),
      getIsCollapsed: (id: string) => createSelector(getAppControlState, (state: IAppControlState) => state.accordionControllerState.accordionControllers.find(o => o.id === id)?.isExpanded == false)
    }
  };

  constructor() {
    super('UiKit', 'AccordionController');
    super.addState({ accordionControllers: [] }).finalize();
  }
}
@Injectable({
  providedIn: 'root'
})
export class SafariUiAccordionReduxObject extends SafariReduxUiObject<IAccordionState, Accordion> {
  // TODO: Follow example in matterpage redux object to combine into action/selector and get rid of default
  default = {
    actions: {
      clearState: super.createAction('Clear State', props<{ payload: { id: string } }>()),
      accordionToggled: super.createAction('Toggled', props<{ payload: UiActionInfo }>()),
      accordionExpanded: super.createAction('Expanded', props<{ payload: UiActionInfo }>()),
      accordionCollapsed: super.createAction('Collapsed', props<{ payload: UiActionInfo }>()),
      accordionToggle: super.createAction('Toggle', props<{ payload: UiActionInfo }>()),
      accordionExpand: super.createAction('Expand', props<{ payload: UiActionInfo }>()),
      accordionCollapse: super.createAction('Collapse', props<{ payload: UiActionInfo }>()),
      setActive: super.createAction('Active', props<{ payload: { id: string; active: boolean } }>())
    },
    selectors: {
      getState: (id: string) => createSelector(getAppControlState, (state: IAppControlState) => state.accordionState.accordions.find(o => o.id === id)),
      getIsExpanded: (id: string) => createSelector(getAppControlState, (state: IAppControlState) => state.accordionState.accordions.find(o => o.id === id)?.isExpanded == true),
      getIsActive: (id: string) => createSelector(getAppControlState, (state: IAppControlState) => state.accordionState.accordions.find(o => o.id === id)?.isActive == true),
      getIsCollapsed: (id: string) => createSelector(getAppControlState, (state: IAppControlState) => state.accordionState.accordions.find(o => o.id === id)?.isExpanded == false)
    },

    reducers: ons => {
      ons.push(
        on(this.default.actions.accordionToggle, (state: IAccordionState, action) => {
          const current = getObjectInArray(state.accordions, action.payload, null);
          const isExpanded = current?.isExpanded;
          const accordions = this.modifyObjectInArray(state.accordions, { ...action.payload, isExpanded: !isExpanded } as ObjectWithActionInfo, null, true, true);
          return { ...state, accordions };
        }),
        on(this.default.actions.accordionExpanded, (state: IAccordionState, action) => {
          const accordions = this.modifyObjectInArray(state.accordions, { ...action.payload, isExpanded: true } as ObjectWithActionInfo, null, true, true);
          return { ...state, accordions };
        }),
        on(this.default.actions.accordionCollapsed, (state: IAccordionState, action) => {
          const accordions = this.modifyObjectInArray(state.accordions, { ...action.payload, isExpanded: false } as ObjectWithActionInfo, null, true, true);
          return { ...state, accordions };
        }),
        on(this.default.actions.clearState, (state: IAccordionState, action: { payload: { id: string } }) => {
          const accordions = this.removeObjectFromArray(state.accordions, action.payload, null);
          return { ...state, accordions };
        }),
        on(this.default.actions.setActive, (state: IAccordionState, action: { payload: { id: string; active: boolean } }) => {
          const accordions = this.modifyObjectInArray(state.accordions, { id: action.payload.id, isActive: action.payload.active } as ObjectWithActionInfo, null, true, true);

          return { ...state, accordions };
        })
      );
    }
  };
  constructor() {
    super('UiKit', 'Accordion');
    super.addState({ accordions: [] }).finalize();
  }
}

@Injectable({
  providedIn: 'root'
})
export class SafariUiComponentReduxObject extends SafariReduxUiObject<IComponentState, any> {
  // TODO: Follow example in matterpage redux object to combine into action/selector and get rid of default
  default = {
    selectors: {
      getComponentState: (id: string) => createSelector(getAppControlState, (state: IAppControlState) => state.componentState.components.find(o => o.id === id)),
      readyToDisplay: (id: string) => createSelector(getAppControlState, (state: IAppControlState) => state.componentState.components.find(o => o.id === id)?.readyToDisplay)
    },
    actions: {
      setReadyToDisplay: super.createAction('Set Ready To Display', props<{ payload: { id: string; readyToDisplay: boolean } }>())
    },
    reducers: ons => {
      ons.push(
        on(this.default.actions.setReadyToDisplay, (state: IComponentState, action) => {
          const current = getObjectInArray(state.components, action.payload, null);
          const components = this.modifyObjectInArray(state.components, { ...current, readyToDisplay: action.payload.readyToDisplay, id: action.payload.id } as ObjectWithActionInfo, null, true, true);
          return { ...state, components };
        })
      );
    }
  };
  constructor() {
    super('UiKit', 'Form');
    super.addState({ components: [] }).finalize();
  }
}
