export {};

declare global {
  interface StringConstructor {
    isNullOrEmpty(str: any): boolean;
    areEquivalent(str1: string, str2: string): boolean;
  }
  // eslint-disable-next-line id-blacklist -- this is a valid extension of String
  interface String {
    equals(str: string): boolean;
    htmlEncode(): string;
    capitalizeFirst(): string;
  }
}

String.isNullOrEmpty = (str: any): boolean => str == null || str.trim() === '';

/** Indicates whether two strings are semantically equivalent. Nulls and blank and empty strings are treated as equivalent, but does not ignore case. */
String.areEquivalent = (str1: string, str2: string): boolean =>
  (String.isNullOrEmpty(str1) && String.isNullOrEmpty(str2)) || (!String.isNullOrEmpty(str1) && !String.isNullOrEmpty(str2) && str1.trim() == str2.trim());

String.prototype.equals = function (str) {
  return this == str;
};
String.prototype.htmlEncode = function () {
  return this.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/'/g, '&#39;').replace(/"/g, '&quot;');
};
String.prototype.capitalizeFirst = function () {
  return (this as string).charAt(0).toUpperCase() + (this as string).slice(1);
};
