import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ConfirmationDialogComponent } from '../../components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogRequest } from '../../models/confirmation-dialog-payload';

@Injectable({
  providedIn: 'root'
})
export class ModalDialogService {
  protected _bsModalRef: BsModalRef;
  private _stringToComponentMap = new Map<string, any>();
  constructor(private modalService: BsModalService) {}
  registerComponent(key: string, component: any) {
    this._stringToComponentMap.set(key, component);
  }
  showModal(request: ConfirmationDialogRequest) {
    const config: ModalOptions<any> = {
      backdrop: 'static',
      keyboard: false,
      animated: true,
      ignoreBackdropClick: true,
      class: request.modalClass,
      initialState: {
        noUiBlock: request.noUiBlock,
        id: request.id,
        data: request.parentData,
        title: request.title,
        content: request.content,
        buttons: request.buttons,

        okBtnName: request.okBtnName,
        okBtnSubtext: request.okBtnSubtext,
        cancelBtnName: request.cancelBtnName,
        cancelBtnSubtext: request.cancelBtnSubtext,
        aux1BtnName: request.aux1BtnName,
        aux1BtnSubtext: request.aux1BtnSubtext,
        okBtnClass: request.okBtnClass,
        cancelBtnClass: request.cancelBtnClass,
        aux1BtnClass: request.aux1BtnClass
      }
    };
    let componentToInstantiate = ConfirmationDialogComponent;
    if (request.component) {
      // If we want a custom dialog component we can either pass a string that points to
      // a type previously registered via call to registerComponent OR we can just pass the type itself.
      // Just passing the class type is obviously more straightforward, however....
      // IF this dialog is being instantiated as part of ngrx flow (for example in a response from
      // page state selectors, and then piped somewhere within it) then passing the actual component would fail,
      // as NGRX locks everything that passes
      // through it and NG does some behind-the-scenes magic with the type and tries to inject some hidden props into
      // the prototype. If we were passing the actual proto then that would be violation of immutability as we call
      // modalService.show(this.request.component...) where component is ref to the prototype that came from NGRX
      // action. When ngrx tries to change it , it is effectively changing a property of the action and that will blow
      // up.
      // To work around this we pass a STRING that represents a component and then get the actual prototype from
      // the map. This plays well with action immutability as now NGRX is not modifying anything that was passed
      // in the NGRX action
      if (typeof request.component === 'string' || request.component instanceof String) {
        componentToInstantiate = this._stringToComponentMap.get(request.component as string);
      } else {
        componentToInstantiate = request.component;
      }
    }
    this._bsModalRef = this.modalService.show(componentToInstantiate, request.config ?? config);
    this._bsModalRef.content.closeBtnName = 'Close';
  }
}
