import { Injectable } from '@angular/core';
import { PermissionsSet } from '@safarilaw-webapp/shared/common-objects-models';
import { UserPermissionType } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  constructor() {}

  isInRole(userInfo: PermissionsSet, role: string) {
    if (userInfo == null) {
      return false;
    }
    // Special check for coordinator
    if (role == UserPermissionType.Coordinator) {
      return userInfo.isCoordinator == true;
    }
    // Special check for matter access
    if (role == UserPermissionType.GlobalMatterAccess) {
      return userInfo.hasAccessToAllMatterSubtypes == true;
    }
    return userInfo.roles != null && userInfo.roles.includes(role);
  }
}
