import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppUpdateSplashService } from '@safarilaw-webapp/shared/app-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  standalone: true,
  selector: 'sl-rms-update-splash',
  templateUrl: './app-update-splash.html',
  styleUrls: ['./app-update-splash.scss']
})
export class AppUpdateSplashComponent implements OnInit, OnDestroy {
  showSplash = false;
  private _appUpdateSplashServiceSubscription: Subscription = null;
  constructor(private _appUpdateSplashService: AppUpdateSplashService) {}
  ngOnInit(): void {
    this._appUpdateSplashServiceSubscription = this._appUpdateSplashService.subscribe(() => {
      document.body.style.overflow = 'hidden';
    });
  }
  ngOnDestroy(): void {
    if (this._appUpdateSplashServiceSubscription) {
      this._appUpdateSplashServiceSubscription.unsubscribe();
      this._appUpdateSplashServiceSubscription = null;
    }
  }
  get errorMessage() {
    return window['swError'];
  }
}
