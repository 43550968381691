import { createSelector, DefaultProjectorFn, MemoizedSelector, Selector } from '@ngrx/store';
import { DefaultFileSelectorConverter, FileOperationInfo } from '@safarilaw-webapp/shared/common-objects-models';

import { IFileUploadState } from '../interface';

// const createGetPreviewFile = (fileUploadState: Selector<object, IFileUploadState>): MemoizedSelector<object, FilePreviewRequest, DefaultProjectorFn<FilePreviewRequest>> =>
//   createSelector(fileUploadState, state => state.filePreview);
// const createGetPreviewFileClosed = (fileUploadState: Selector<object, IFileUploadState>): MemoizedSelector<object, FilePreviewResponse, DefaultProjectorFn<FilePreviewResponse>> =>
//   createSelector(fileUploadState, state => {
//     if (state.filePreviewResponse != null && state.filePreviewResponse.__responseType == FilePreviewResponseType.Closed) {
//       return state.filePreviewResponse;
//     }
//     return null;
//   });
// const createGetPreviewFileEdit = (fileUploadState: Selector<object, IFileUploadState>): MemoizedSelector<object, FilePreviewResponse, DefaultProjectorFn<FilePreviewResponse>> =>
//   createSelector(fileUploadState, state => {
//     if (state.filePreviewResponse != null && state.filePreviewResponse.__responseType == FilePreviewResponseType.Edit) {
//       return state.filePreviewResponse;
//     }
//     return null;
//   });
const createGetFileOperationSelector = (fileUploadState: Selector<object, IFileUploadState>): MemoizedSelector<object, FileOperationInfo[], DefaultProjectorFn<FileOperationInfo[]>> =>
  createSelector(fileUploadState, state => state.files);

export const createDefaultFileOperationSelectors = (myFileUploadState: Selector<object, IFileUploadState>) =>
  new DefaultFileSelectorConverter({
    fileOperations: createGetFileOperationSelector(myFileUploadState)
    // previewFile: createGetPreviewFile(myFileUploadState),
    // previewFileClosed: createGetPreviewFileClosed(myFileUploadState),
    // previewFileEdit: createGetPreviewFileEdit(myFileUploadState)
  });
