import { CommonModule } from '@angular/common';
import { InjectionToken, NgModule } from '@angular/core';
import { EffectsModule, USER_PROVIDED_EFFECTS } from '@ngrx/effects';
import { ActionReducerMap, StoreModule } from '@ngrx/store';

import { SharedEnvironmentModule } from '@safarilaw-webapp/shared/environment';
import { SharedLocalStorageModule } from '@safarilaw-webapp/shared/crud';

import { fileTransferReduxObject } from './redux/file-transfer';
import { getEffectTokens } from './redux/injectable-effect-tokens';

import { ReducerService } from './redux/reducer.service';
import { FEATURE_STATE, ICoManageState } from './redux/state.interface';
import { SafariReduxFileTransferObjectDefinition } from '@safarilaw-webapp/shared/common-objects-models';
const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<ICoManageState>>('CoManage DataAccess Reducers');
export const COMANAGE_FILE_TRANSFER_OBJECT = new InjectionToken<SafariReduxFileTransferObjectDefinition>('CoManage FileTransfer Object', {
  factory: () => fileTransferReduxObject,
  providedIn: 'root'
});

@NgModule({
  imports: [StoreModule.forFeature(FEATURE_STATE, REDUCER_TOKEN), EffectsModule.forFeature(), CommonModule, SharedEnvironmentModule, SharedLocalStorageModule],
  providers: [
    ...[
      {
        provide: REDUCER_TOKEN,
        deps: [ReducerService],
        useFactory: (service: ReducerService) => service.reducers
      },
      {
        provide: USER_PROVIDED_EFFECTS,
        useValue: getEffectTokens(),
        multi: true
      }
    ]
  ],
  exports: [SharedLocalStorageModule]
})
export class FeatureCoManageDataAccessModule {}
