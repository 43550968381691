import { SafariRequired } from '@safarilaw-webapp/shared/crud';

import { Injectable } from '@angular/core';
import { ApiCallContext } from '@safarilaw-webapp/shared/common-objects-models';
import { DeliveryPortalAcknowledgeInstructionsModel } from '../../_rms-api-models.generated';
import { RmsDataAdapter } from '../../shared/rms-base-adapter';
import { Acknowledgment } from '../models/app/acknowledgment';

@Injectable({
  providedIn: 'root'
})
export class AcknowledgmentAdapter extends RmsDataAdapter<Acknowledgment> {
  toCreateModel(model: Acknowledgment, context?: ApiCallContext<any>): SafariRequired<DeliveryPortalAcknowledgeInstructionsModel> {
    return {
      email: model.email,
      name: model.name
    };
  }
}
