import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SafariPollingService {
  constructor(private _ngZone: NgZone) {}
  setTimeout(fn: any, miliseconds: number) {
    let id = null;
    this._ngZone.runOutsideAngular(() => {
      id = setTimeout(() => {
        this._ngZone.run(fn);
      }, miliseconds);
    });
    return id;
  }
  setInterval(fn: any, miliseconds: number) {
    let id = null;
    this._ngZone.runOutsideAngular(() => {
      id = setInterval(() => {
        this._ngZone.run(fn);
      }, miliseconds);
    });
    return id;
  }
  setWebhook(webhookObservable: Observable<any>, functionToRun: any) {
    let sub = null;
    this._ngZone.runOutsideAngular(() => {
      sub = webhookObservable.subscribe(() => {
        this._ngZone.run(functionToRun);
      });
    });
    return sub;
  }
}
