import { Injectable } from '@angular/core';
import { ApiCallContext, FileObject } from '@safarilaw-webapp/shared/common-objects-models';
import { ApiDataAdapter, SafariRequired } from './adapter';

@Injectable({
  providedIn: 'root'
})
export class FileObjectAdapter extends ApiDataAdapter<FileObject> {
  toCreateModel(model: FileObject, context?: ApiCallContext<any>): SafariRequired<any> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- The filters model will vary depending on what report is being run
    return model;
  }
  toDeleteModel(model: FileObject, context?: ApiCallContext<any>): SafariRequired<any> {
    return model;
  }
}
