import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, RouterOutlet } from '@angular/router';

import {
  AppUpdateSplashService,
  NavbarService,
  SafariRootAppComponent,
  SwCheckForUpdateService,
  SwHandleUnrecoverableStateService,
  SwHandleUpdateService
} from '@safarilaw-webapp/shared/app-bootstrap';
import { FeatureFlagsService } from '@safarilaw-webapp/shared/environment';
import { ReduxWrapperService } from '@safarilaw-webapp/shared/redux';
import { Subscription } from 'rxjs';

import { NavbarItemCollection } from './navbar-item-collection';
import { MaintenanceSplashComponent } from './site-maintenance/components/maintenance-splash/maintenance-splash';
import { AppUpdateSplashComponent } from './app-update-splash/components/app-update-splash/app-update-splash';
import { MaintenanceHeaderComponent } from './site-maintenance/components/maintenance-header/maintenance-header';
import { NgClass, NgIf } from '@angular/common';
import { RmsHeaderComponent, RmsNavbarComponent } from '@safarilaw-webapp/feature/rms/shared';
import { BlockUIModule } from 'ng-block-ui';

@Component({
  standalone: true,
  selector: 'sl-rms-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [MaintenanceSplashComponent, AppUpdateSplashComponent, MaintenanceHeaderComponent, RouterOutlet, NgClass, NgIf, RmsHeaderComponent, RmsNavbarComponent, BlockUIModule]
})
export class AppComponent extends SafariRootAppComponent implements OnInit, OnDestroy {
  showSplash = false;
  private _appUpdateSplashServiceSubscription: Subscription = null;
  constructor(
    injector: Injector,

    navbarService: NavbarService,
    private _featureFlagService: FeatureFlagsService,

    private _reduxWrapperService: ReduxWrapperService,

    // NOTE: When using these four services make sure they are added in this order. Their constructors
    // will fire in order and we want the ones that subscribe to SW to construct before the one that
    // actually issues the update check. They look unused but they are not - leave them here
    private _handleRecovery: SwHandleUnrecoverableStateService,
    private _logUpdate: SwHandleUpdateService,
    private _checkUpdate: SwCheckForUpdateService,
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    private _appUpdateSplashService: AppUpdateSplashService
  ) {
    super(injector, navbarService);

    this._navbarItems = [...NavbarItemCollection];
  }

  ngOnInit(): void {
    this._appUpdateSplashServiceSubscription = this._appUpdateSplashService.subscribe(() => {
      this.showNavbar = this.showHeader = false;
      this.showSplash = true;
    });

    super.ngOnInit();
  }
  ngOnDestroy(): void {
    if (this._appUpdateSplashServiceSubscription) {
      this._appUpdateSplashServiceSubscription.unsubscribe();
      this._appUpdateSplashServiceSubscription = null;
    }
    super.ngOnDestroy();
  }
  OnNavigationEnd(evt: NavigationEnd) {
    super.OnNavigationEnd(evt);
  }

  get canShowHeader() {
    return this.showHeader;
  }
  get canShowNavbar() {
    return this.showNavbar;
  }

  get showDivider(): boolean {
    return this._router.url?.toLowerCase() != '/profile';
  }
}
