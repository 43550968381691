import { Injectable } from '@angular/core';
import { ApiDropdownAdapter } from '@safarilaw-webapp/shared/crud';
import { DropdownSection } from '../models/app/dropdown-section';
import { DropdownSection as ApiDropdownSection } from '../models/api/dropdown-section';

@Injectable({
  providedIn: 'root'
})
export class DropdownSectionAdapter extends ApiDropdownAdapter {
  fromListModel(model: ApiDropdownSection): Required<DropdownSection> {
    return {
      ...super.fromListModel(model),
      description: model.description
    };
  }
}
