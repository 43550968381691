import { Injectable } from '@angular/core';
import { CrudGenericService } from '@safarilaw-webapp/shared/crud';

import { AppConfigurationService } from '@safarilaw-webapp/shared/environment';
import { Observable } from 'rxjs';
import { AuthClaim, AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CoUserSsoService {
  constructor(private _crudGenericService: CrudGenericService, private _authService: AuthService, private _appConfig: AppConfigurationService) {}

  getSsoUser(): Observable<any> {
    const connectionName = this._authService.getIdClaim(AuthClaim.ConnectionName);
    const userEmail = this._authService.getIdClaim(AuthClaim.Email);
    return this._crudGenericService.retrieve(`${this._appConfig.urls.apiRootCoManage}users/${connectionName}/${userEmail}`);
  }

  hookupSsoUser(id: string): Observable<any> {
    return this._crudGenericService.create({}, `${this._appConfig.urls.apiRootCoManage}users/${id}/setupAuth`);
  }
}
