@if(hasNavbarHeaders()) {
<!-- NOTE: Keep user observable even though it's not use. The observable is tapped to set user context for logging -->

@if(
{
user: user$ | async,
company: company$ | async,
allowNavigation: allowNavigation$ | async,
itemBadges: itemBadges$ | async,
recentIntakePortals: recentIntakePortals$ | async}; as sharedPipes
) {
<nav id="sidebar" [class.active]="active" [class.disabled]="!sharedPipes.allowNavigation">
  <!-- BRAND -->
  <div class="sidebar-header" id="sidebarCollapse" (click)="toggleSidebar()">
    <div class="s_brand-expanded"></div>
    <div class="s_brand-collapsed"></div>
  </div>


  <!-- Navbar Groups -->
  <ul class="list-unstyled components">

    <ng-container *ngFor="let navbarGroup of navbarGroups">
      @if(hasGroupTitle(navbarGroup)) {
        <li  class="s_mnu-header">
          {{ navbarGroup.title }}
        </li>
      }
     
      <!-- SPACER -->
      <li class="s_mnu-hr">
        <hr>
      </li>

      <!-- Navbar Headers -->
      <li *ngFor="let navbarHeader of getNavbarHeaders(navbarGroup.id)">
        @if(hasHeaderLink(navbarHeader)) {
          <a [routerLink]="[navbarHeader.link]" class="nav-link" [class.active]="isHeaderActive(navbarHeader.id)"
            [attr.id]="navbarHeader.elementId">
            <ins></ins>
            <div class="nav-item">
              <div class="nav-item-title">{{ navbarHeader.title }}</div>
              <div class="nav-item nav-item-subtitle">{{ navbarHeader.subtitle }}</div>
            </div>

            @if (itemBadgeValue(navbarHeader.id.toString(), sharedPipes.itemBadges) != null) {
              <span  
              class="badge badge-light float-right">{{ itemBadgeValue(navbarHeader.id.toString(),
              sharedPipes.itemBadges) }}</span>
            }
       
          </a>

          <!-- Content specific Subitems -->
           @if(navbarHeader.title == 'Serve') {
            <ul class="list-unstyled mru-intake-portals">
              <li *ngFor="let recentIntakePortal of sharedPipes.recentIntakePortals">
                <a [routerLink]="'serve/' + recentIntakePortal.urlPath" class="nav-subitem"
                  [class.active]="isSubheaderActive(recentIntakePortal.urlPath)">
                  <!-- TODO #8139 - Logic for active class if we want it-->
                  {{ recentIntakePortal.shortName }}
                </a>
              </li>
            </ul>
           }
       

        }
    
        @if(!hasHeaderLink(navbarHeader)) {

        <a style="cursor:pointer" [attr.id]="navbarHeader.elementId"
          (click)="$event.stopPropagation(); $event.preventDefault(); navbarHeader.expanded = !navbarHeader.expanded;"
          class="dropdown-toggle"
          [class]="{ active: isActive(navbarHeader.id), collapsed: !isActive(navbarHeader.id) }">



          <ins></ins>
          <div class="nav-item">
            <div class="nav-item-title">{{ navbarHeader.title }}</div>
            <div class="nav-item nav-item-subtitle">{{ navbarHeader.subtitle }}</div>
          </div>
        </a>


        <!-- Navbar Items -->
        <ul class="list-unstyled" [collapse]="!navbarHeader.expanded && !isActive(navbarHeader.id)" [isAnimated]="true"
          [attr.id]="getChildId(+navbarHeader.elementId)">

          <!-- Hardcoded Subitems -->
          <ng-container *ngFor="let navbarSubItem of getNavbarItems(navbarHeader.id)">
            @if(!isDivider(navbarSubItem)) {
              <li  >
                <a [id]='getSubItemId(navbarSubItem.link)' [routerLink]="[navbarSubItem.link]" class="nav-subitem"
                  [class.active]="isActive(navbarSubItem.id)">
                  {{ navbarSubItem.title }}
                </a>
              </li>
            } @else {
              <li   class="s_sub-hr">
                <hr>
              </li>
            }
           
            
          </ng-container>
        </ul>

        }

      </li>
    </ng-container>

  </ul>
 
    @if(showCompanyName()) {
      <div class="s_mnu-header s_mnu-company">
        @if(sharedPipes.company) {
          {{ sharedPipes.company.name }}
        } @else {
          <span style="color:transparent">_</span>
        }
       
      </div>
    }

 

</nav>
}


}