import { SafariRequired } from '@safarilaw-webapp/shared/crud';

import { Injectable } from '@angular/core';
import { ApiCallContext } from '@safarilaw-webapp/shared/common-objects-models';
import { EnsureUserAuthModel } from '../../_rms-api-models.generated';
import { RmsDataAdapter } from '../../shared/rms-base-adapter';
import { MyAuth } from '../models/my-auth';

@Injectable({
  providedIn: 'root'
})
export class AuthAdapter extends RmsDataAdapter<MyAuth> {
  toUpdateModel(model: EnsureUserAuthModel, context?: ApiCallContext<any>): SafariRequired<MyAuth> {
    return {
      email: model.email,
      id: undefined
    };
  }
}
