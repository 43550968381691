export enum NavbarItemId {
  ActionAlerts = 100,
  Unassigned = 101,
  AssignedToMe = 103
}
export enum FileOperationActions {
  CancelTransfer = 0,
  UpdateFileUploadProgress = 1,
  ProcessFileFail = 2,
  ProcessFileSuccess = 3,
  ClearFileInfoFromStore = 4,
  UpdateFileDownloadProgress = 5,
  PrepareForBulkTransfer = 6,
  PreviewFile = 7,
  PreviewFileClosed = 8,
  PreviewFileEdit = 9
}
