import { InjectionToken, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterReducerService } from './state/state.reducer';
import { ActionReducerMap, StoreModule } from '@ngrx/store';

import { EffectsModule } from '@ngrx/effects';
import { RouterEffects } from './router/redux/effect';
import { IRouterFeatureState } from '@safarilaw-webapp/shared/common-objects-models';

const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<IRouterFeatureState>>('Router State Reducers');

@NgModule({
  imports: [EffectsModule.forRoot([]), StoreModule.forFeature('navigation', REDUCER_TOKEN), EffectsModule.forFeature([RouterEffects]), CommonModule],
  providers: [
    {
      provide: REDUCER_TOKEN,
      deps: [RouterReducerService],
      useFactory: (service: RouterReducerService) => service.reducers
    }
  ]
})
export class SharedRoutingUtilityModule {}
