import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigurationService } from '@safarilaw-webapp/shared/environment';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor(private _httpClient: HttpClient, private _appConfig: AppConfigurationService) {}

  private get _versionUrl(): string {
    return `${this._appConfig.urls.apiRootCoManage}schema-version`;
  }

  // only used for global - need to refactor something maybe ?

  nextId(): number {
    const item = localStorage.getItem('safari-id');
    let itemAsNumber = 100;
    if (item) {
      itemAsNumber = +item;
      itemAsNumber++;
    }
    localStorage.setItem('safari-id', itemAsNumber.toString());
    return itemAsNumber;
  }
  checkVersion() {
    const schemaKey = 'safari-schema';

    const schema = {
      version: 0
    };

    this._httpClient.get<string>(this._versionUrl).subscribe(o => {
      const safariVariablesMap = JSON.parse(localStorage.getItem(schemaKey)) as { version: number };
      if (safariVariablesMap) {
        if (!safariVariablesMap.version || safariVariablesMap.version < +o) {
          localStorage.removeItem(schemaKey);
          schema.version = +o;
          localStorage.setItem(schemaKey, JSON.stringify(schema));
        }
      } else {
        schema.version = +o;
        localStorage.setItem(schemaKey, JSON.stringify(schema));
      }
    });
  }
  removeItem(schemaKey: string, keyName: string) {
    const safariSchema = JSON.parse(localStorage.getItem(schemaKey)) as unknown[];
    delete safariSchema[keyName];
  }
  getItem(schemaKey: string, keyName: string): unknown {
    const safariSchema = JSON.parse(localStorage.getItem(schemaKey)) as unknown;
    if (safariSchema && safariSchema[keyName]) {
      return safariSchema[keyName];
    }
    return null;
  }
  setItem(schemaKey: string, keyName: string, item: unknown): void {
    let safariSchema = JSON.parse(localStorage.getItem(schemaKey)) as unknown[];
    if (!safariSchema) {
      localStorage.setItem(schemaKey, '{}');
      safariSchema = JSON.parse(localStorage.getItem(schemaKey)) as unknown[];
    }
    if (safariSchema) {
      safariSchema[keyName] = item;
      localStorage.setItem(schemaKey, JSON.stringify(safariSchema));
    }
  }
}
