import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash-es';
import { filter, tap, withLatestFrom } from 'rxjs/operators';
import { SafariRouterReduxObject } from './state';
@Injectable()
export class RouterEffects {
  routerNavigate$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(this._routerReduxObject.default.actions.routerNavigate),
        withLatestFrom(this._store.select(this._routerReduxObject.default.selectors.routerState())),
        tap(([action, routerState]) => {
          // Navigate if either there is nothing in routerState (this shouldn't even happen, but won't hurt to check) OR
          // There is router state AND what's in it is the same as the requested path (basically meaning it comes from the same request)
          // OR the paths are different (which could be case if there are two or more navigation calls in sequence and this was the last one)
          // BUT we have "ignoreIfNavigationPending" flag set.

          if (action.payload != null && (routerState == null || isEqual(routerState.routerNavigationInfo.path, action.payload.path) || action.payload.ignoreIfNavigationPending != true)) {
            if (Array.isArray(action.payload.path)) {
              void this._router.navigate(action.payload.path, action.payload.extras);
            } else {
              void this._router.navigateByUrl(action.payload.path, action.payload.extras);
            }
          }
        })
      ),
    { dispatch: false }
  );
  routerForward$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(this._routerReduxObject.default.actions.routerForward),
        tap(() => this._location.forward())
      ),
    { dispatch: false }
  );
  routerBack$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(this._routerReduxObject.default.actions.routerBack),
        tap(() => this._location.back())
      ),
    { dispatch: false }
  );

  constructor(
    private _actions: Actions,
    private _store: Store<any>,
    private _routerReduxObject: SafariRouterReduxObject,
    private _router: Router,
    private _location: Location
  ) {
    this.subscribeToRouterEvents();
  }

  private subscribeToRouterEvents() {
    this._router.events.pipe(filter(event => event instanceof ActivationEnd)).subscribe((event: ActivationEnd) => {
      this._store.dispatch(
        this._routerReduxObject.default.actions.routerRouteChange({
          payload: {
            params: { ...event.snapshot.params },
            path: event.snapshot.routeConfig.path
          }
        })
      );
    });
  }
}
