import { NavbarGroup, navbarGroupIdDashboard, NavbarHeader, NavbarItem } from '@safarilaw-webapp/shared/app-bootstrap';
import { environmentImports } from './navbar-item-collection.env.imports';

export const NavbarItemCollection = [
  new NavbarGroup(navbarGroupIdDashboard, '', []),

  new NavbarHeader({ id: 100, groupId: navbarGroupIdDashboard, title: 'Home', subtitle: 'ServePort Overview', elementId: 's_mnu-link-library', link: '/home' }),
  new NavbarHeader({ id: 200, groupId: navbarGroupIdDashboard, title: 'Serve', subtitle: 'Submit New Service', elementId: 's_mnu-link-intake', link: '/serve' }),
  new NavbarHeader({ id: 300, groupId: navbarGroupIdDashboard, title: 'Response', subtitle: 'Download Documents', elementId: 's_mnu-link-cabinet', link: '/portal' }),
  // new NavbarHeader({ id: 400, groupId: navbarGroupIdDashboard, title: 'Legal', elementId: 's_mnu-link-globe' }),
  new NavbarItem(401, 400, 'Privacy Policy', '/legal#privacy-policy'),
  new NavbarItem(402, 400, 'Terms of Use', '/legal#terms-of-use'),
  ...environmentImports
];
