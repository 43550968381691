import { createSelector, DefaultProjectorFn, MemoizedSelector, Selector } from '@ngrx/store';
import { CrudOperationType, SafariObject, SafariObjectId } from '@safarilaw-webapp/shared/common-objects-models';
import { SafariObjectListId } from '../../models/object';
import { ISafariObjectState, SafariObjectList } from '../interface';
import { getObjectInArray } from '../reducer';

// OLD CODE - leaving here for now for ease of reference and compare
// export const createGetObjectInCurrentStateSelector =
//   <T extends SafariObject>(objectStateSelector: Selector<object, ISafariObjectState<T>>) =>
//   (id: SafariObjectId) =>
//     createSelector(objectStateSelector, state => {
//       if (SafariObject.idIsCompound(id)) {
//         const objectID = id[id.length - 1];
//         const parentIds = id.slice(0, id.length - 1);

//         return state.object.current.find(
//           o => JSON.stringify(o.id) == JSON.stringify(id) || o.id == objectID || (o['__priorId'] == objectID && JSON.stringify(parentIds) == JSON.stringify(o['__parentIds']))
//         );
//       }
//       return state.object.current.find(o => JSON.stringify(o.id) == JSON.stringify(id) || JSON.stringify(o['__priorId']) == JSON.stringify(id));
//     });

// Currently we pick userID from the state. Eventually we want to pass the userId parameter instead

// TODO: There are a lot of direct checks against arrays - these selectors should just use getObjectInArray
// but starting slow with currentstateselector change only
export const createGetObjectInCurrentStateSelector =
  <T extends SafariObject>(objectStateSelector: Selector<object, ISafariObjectState<T>>) =>
  (id: SafariObjectId) =>
    createSelector(objectStateSelector, state => getObjectInArray(state.object.current, { id }, null));
export const createGetObjectInSavingStateSelector =
  <T extends SafariObject>(objectStateSelector: Selector<object, ISafariObjectState<T>>) =>
  (id: SafariObjectId) =>
    createSelector(objectStateSelector, state => state.object.saving.find(o => JSON.stringify(o.id) == JSON.stringify(id) || JSON.stringify(o['__priorId']) == JSON.stringify(id)));
export const createGetObjectInCurrentOrFailedStateSelector =
  <T extends SafariObject>(objectStateSelector: Selector<object, ISafariObjectState<T>>) =>
  (id: SafariObjectId, operationType: CrudOperationType) =>
    createSelector(objectStateSelector, state => {
      // If we are currently saving return
      if (state.object.saving.find(o => JSON.stringify(o.id) == JSON.stringify(id) || JSON.stringify(o['__priorId']) == JSON.stringify(id))) {
        return null;
      }
      let array: T[] = [];
      if (operationType == CrudOperationType.Retrieve) {
        array = state.object.failedToLoad;
      } else if (operationType == CrudOperationType.Create || operationType == CrudOperationType.Update) {
        array = state.object.failedToSave;
      } else if (operationType == CrudOperationType.Delete) {
        array = state.object.failedToDelete;
      }
      return (
        array.find(o => JSON.stringify(o.id) == JSON.stringify(id) || JSON.stringify(o['__priorId']) == JSON.stringify(id)) ||
        state.object.current.find(o => JSON.stringify(o.id) == JSON.stringify(id) || JSON.stringify(o['__priorId']) == JSON.stringify(id))
      );
    });

export const createGetObjectInFailedStateSelector =
  <T extends SafariObject>(objectStateSelector: Selector<object, ISafariObjectState<T>>) =>
  (id: SafariObjectId, operationType: CrudOperationType) =>
    createSelector(objectStateSelector, state => {
      let array: T[] = [];
      if (operationType == CrudOperationType.Retrieve) {
        array = state.object.failedToLoad;
      } else if (operationType == CrudOperationType.Create || operationType == CrudOperationType.Update) {
        array = state.object.failedToSave;
      } else if (operationType == CrudOperationType.Delete) {
        array = state.object.failedToDelete;
      }
      return array.find(o => JSON.stringify(o.id) == JSON.stringify(id) || JSON.stringify(o['__priorId']) == JSON.stringify(id));
    });

// Currently we pick userID from the state. Eventually we want to pass the userId parameter instead
export const createGetObjectInLoadedStateSelector =
  <T extends SafariObject>(objectStateSelector: Selector<object, ISafariObjectState<T>>) =>
  (id: SafariObjectId) =>
    createSelector(objectStateSelector, state => state.object.loaded.find(o => JSON.stringify(o.id) == JSON.stringify(id) || JSON.stringify(o['__priorId']) == JSON.stringify(id)));
export const createGetObjectInSavedStateSelector =
  <T extends SafariObject>(objectStateSelector: Selector<object, ISafariObjectState<T>>) =>
  (id: SafariObjectId) =>
    createSelector(objectStateSelector, state => state.object.saved.find(o => JSON.stringify(o.id) == JSON.stringify(id) || JSON.stringify(o['__priorId']) == JSON.stringify(id)));

export const createGetObjectInLoadingStateSelector =
  <T extends SafariObject>(objectStateSelector: Selector<object, ISafariObjectState<T>>) =>
  (id: SafariObjectId) =>
    createSelector(objectStateSelector, state => state.object.loading.find(o => JSON.stringify(o.id) == JSON.stringify(id) || JSON.stringify(o['__priorId']) == JSON.stringify(id)));

export const createGetObjectsSavingSelector = <T>(objectStateSelector: Selector<object, ISafariObjectState<T>>) =>
  createSelector(objectStateSelector, state => (state.list.saving.length > 0 ? state.list.saving[0] : null));

export const createGetObjectListInCurrentStateSelector =
  <T extends SafariObject>(objectStateSelector: Selector<object, ISafariObjectState<T>>) =>
  (listIdAndFilter: SafariObjectListId) =>
    createSelector(objectStateSelector, state => {
      if (listIdAndFilter == null) {
        listIdAndFilter = {
          id: null,
          filter: null
        };
      }

      const current = getObjectInArray(state.list.current, { ...listIdAndFilter, countOnly: false }, null, true);

      return current;
    });
export const createGetObjectListInLoadedStateSelector =
  <T extends SafariObject>(objectStateSelector: Selector<object, ISafariObjectState<T>>) =>
  (listIdAndFilter: SafariObjectListId) =>
    createSelector(objectStateSelector, state => {
      if (listIdAndFilter == null) {
        listIdAndFilter = {
          id: null,
          filter: null
        };
      }

      const loaded = getObjectInArray(state.list.loaded, { ...listIdAndFilter, countOnly: false }, null, true);
      return loaded ? loaded : null;
    });
export const createGetObjectListInFailedStateSelector =
  <T extends SafariObject>(objectStateSelector: Selector<object, ISafariObjectState<T>>) =>
  (listIdAndFilter: SafariObjectListId, operationType: CrudOperationType) =>
    createSelector(objectStateSelector, state => {
      if (listIdAndFilter == null) {
        listIdAndFilter = {
          id: null,
          filter: null
        };
      }

      let array: SafariObjectList<T>[] = [];
      if (operationType == CrudOperationType.Retrieve) {
        array = state.list.failedToLoad;
      } else if (operationType == CrudOperationType.Create || operationType == CrudOperationType.Update) {
        array = state.list.failedToSave;
      } else if (operationType == CrudOperationType.Delete) {
        array = state.list.failedToDelete;
      }

      const failed = getObjectInArray(array, { ...listIdAndFilter, countOnly: false }, null, true);
      return failed ? failed : null;
    });

export const createGetObjectHistoryInCurrentStateSelector =
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- history is "any" at this time


    <T>(objectStateSelector: Selector<object, ISafariObjectState<T>>) =>
    (id: string) =>
      createSelector(objectStateSelector, state => {
        const currentHistory = state.auditHistory.current.find(o => o.id == id);
        if (currentHistory != null) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- history is "any" at this time
          return currentHistory.history;
        }
        return null;
      });
export const createGetObjectListInCurrentStateTotalCountSelector =
  <T>(objectStateSelector: Selector<object, ISafariObjectState<T>>) =>
  (listIdAndFilter: SafariObjectListId) =>
    createSelector(objectStateSelector, state => {
      if (listIdAndFilter == null) {
        listIdAndFilter = {
          id: null,
          filter: null
        };
      }

      const current = getObjectInArray(state.list.current, { ...listIdAndFilter }, null, true);

      return current ? current.totalCount : null;
    });
export const createDefaultSelectors = <T extends SafariObject>(myState: Selector<object, ISafariObjectState<T>>) => {
  const selectors = new DefaultSelectorConverter<T>({
    objectInCurrentState: createGetObjectInCurrentStateSelector<T>(myState),
    // NOTE: I think objectInCurrentOrFailedState should replace the default selector called objectInCurrentState.
    // But at this time I m not too eager to make system-wide change like that and introduce risk
    objectInCurrentOrFailedState: createGetObjectInCurrentOrFailedStateSelector<T>(myState),
    objectInFailedState: createGetObjectInFailedStateSelector<T>(myState),
    objectInSavingState: createGetObjectInSavingStateSelector<T>(myState),
    objectInSavedState: createGetObjectInSavedStateSelector<T>(myState),
    objectInLoadedState: createGetObjectInLoadedStateSelector<T>(myState),
    objectInLoadingState: createGetObjectInLoadingStateSelector<T>(myState),
    objectHistoryInCurrentState: createGetObjectHistoryInCurrentStateSelector<T>(myState),

    objectListInCurrentState: createGetObjectListInCurrentStateSelector<T>(myState),
    objectListInLoadedState: createGetObjectListInLoadedStateSelector<T>(myState),
    objectListInFailedState: createGetObjectListInFailedStateSelector<T>(myState),
    objectListInCurrentStateTotalCount: createGetObjectListInCurrentStateTotalCountSelector<T>(myState),

    objectListInSavingState: createGetObjectsSavingSelector<T>(myState)
  });

  return selectors;
};

export class DefaultSelectorConverter<T> {
  constructor(
    private _selectors: {
      // NOTE: These are selectors that are currently used by the app. If you need some other selectors feel
      // free to add. You can follow the ones we already have , just make sure that names stay consistent.
      // For example objectInLoadingState or objectListInFailedToSaveState, etc.

      // Single Object
      objectInLoadedState?: (id: SafariObjectId) => MemoizedSelector<object, T, DefaultProjectorFn<T>>;
      objectInLoadingState?: (id: SafariObjectId) => MemoizedSelector<object, T, DefaultProjectorFn<T>>;
      objectInCurrentState?: (id: SafariObjectId) => MemoizedSelector<object, T, DefaultProjectorFn<T>>;
      objectInFailedState?: (id: SafariObjectId, operationType: CrudOperationType) => MemoizedSelector<object, T, DefaultProjectorFn<T>>;
      objectInSavingState?: (id: SafariObjectId) => MemoizedSelector<object, T, DefaultProjectorFn<T>>;
      objectInSavedState?: (id: SafariObjectId) => MemoizedSelector<object, T, DefaultProjectorFn<T>>;
      objectInCurrentOrFailedState?: (id: SafariObjectId, operationType: CrudOperationType) => MemoizedSelector<object, T, DefaultProjectorFn<T>>;

      // Object List
      objectListInCurrentState?: (listIdAndFilter: SafariObjectListId) => MemoizedSelector<object, SafariObjectList<T>, DefaultProjectorFn<SafariObjectList<T>>>;
      objectListInLoadedState?: (listIdAndFilter: SafariObjectListId) => MemoizedSelector<object, SafariObjectList<T>, DefaultProjectorFn<SafariObjectList<T>>>;
      objectListInFailedState?: (listIdAndFilter: SafariObjectListId, operationType: CrudOperationType) => MemoizedSelector<object, SafariObjectList<T>, DefaultProjectorFn<SafariObjectList<T>>>;
      objectListInSavingState?: MemoizedSelector<object, SafariObjectList<T>, DefaultProjectorFn<SafariObjectList<T>>>;
      objectListInCurrentStateTotalCount?: (listIdAndFilter: SafariObjectListId) => MemoizedSelector<object, number, DefaultProjectorFn<number>>;

      // Object history
      objectHistoryInCurrentState?: (id: SafariObjectId) => MemoizedSelector<object, any, DefaultProjectorFn<any>>;
    }
  ) {}

  get objectInLoadedState() {
    return this._selectors.objectInLoadedState;
  }
  get objectInLoadingState() {
    return this._selectors.objectInLoadingState;
  }
  get objectInCurrentState() {
    return this._selectors.objectInCurrentState;
  }
  get objectInSavingState() {
    return this._selectors.objectInSavingState;
  }
  get objectInSavedState() {
    return this._selectors.objectInSavedState;
  }
  get objectInCurrentOrFailedState() {
    return this._selectors.objectInCurrentOrFailedState;
  }
  get objectInFailedState() {
    return this._selectors.objectInFailedState;
  }
  get objectListInCurrentState() {
    return this._selectors.objectListInCurrentState;
  }
  get objectListInFailedState() {
    return this._selectors.objectListInFailedState;
  }
  get objectListInLoadedState() {
    return this._selectors.objectListInLoadedState;
  }
  get objectListInSavingState() {
    return this._selectors.objectListInSavingState;
  }

  get objectListInCurrentStateTotalCount() {
    return this._selectors.objectListInCurrentStateTotalCount;
  }

  get objectHistoryInCurrentState() {
    return this._selectors.objectHistoryInCurrentState;
  }
}
