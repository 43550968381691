import { Injectable } from '@angular/core';
import { IdToken } from '@auth0/auth0-spa-js';
import { Action, ActionCreator, createAction, createFeatureSelector, createReducer, createSelector, MemoizedSelector, on, props } from '@ngrx/store';
import { ActionReducer, ActionReducerMap } from '@ngrx/store/src/models';
import { UserCompany } from '../company/user-company';

export class IAuth0State {
  token: string;
  claims: IdToken;
  refreshedOn: Date;
  lastActivityTimestamp: number;
}
export interface IRequestedPasswordlessUser {
  email: string;
  error?: string | Error;
}
export class IAuthSafariState {
  currentUserCompanies: UserCompany[];
  requestedPasswordlessUser: IRequestedPasswordlessUser;
}
export class IAuthState {
  auth0: IAuth0State;
  safari: IAuthSafariState;
}

enum AuthActions {
  Login = '[Auth] Login',
  LoginSuccess = '[Auth] Login Success',
  LoginFailed = '[Auth] Login Failed',
  RefreshToken = '[Auth] RefreshToken',
  RefreshTokenSuccess = '[Auth] RefreshToken Success',
  RefreshTokenFailed = '[Auth] RefreshToken Failed',
  // Don't confuse these three with the ones from above. These are specifically
  // for refreshing token when error occurs and are slightly different than regular refresh token
  RefreshTokenForErrorHandler = '[Auth] RefreshToken For Error Handler',
  RefreshTokenForErrorHandlerSuccess = '[Auth] RefreshToken For Error Handler Success ',
  RefreshTokenForErrorHandlerFailed = '[Auth] RefreshToken For Error Handler Failed',
  RefreshCompanies = '[Auth] RefreshCompanies',
  RefreshCompaniesSuccess = '[Auth] RefreshCompanies Success',
  RefreshCompaniesFailed = '[Auth] RefreshCompanies Failed',

  EnsurePasswordlessUserExists = '[Auth] EnsurePasswordlessUserExists',
  EnsurePasswordlessUserExistsSuccess = '[Auth] EnsurePasswordlessUserExists Success',
  EnsurePasswordlessUserExistsFailed = '[Auth] EnsurePasswordlessUserExists Failed',
  ClearRequestedPasswordlessUser = '[Auth] ClearRequestedPasswordlessUser Failed',

  UpdateLastActivityTimestamp = '[Auth] Update Last Activity Timestamp',
  UpdateLastActivityTimestampSuccess = '[Auth] Update Last Activity Timestamp Success',

  Logout = '[Auth] Logout'
}

export enum AuthLoginType {
  INITIAL = 'INITIAL',
  REFRESH = 'REFRESH'
}
const getAuthState = createFeatureSelector<IAuthState>('auth');
class LoginSuccessPayload {}
class LoginFailedPayload {}
class LoginPayload {
  redirectTargetRoute?: string;
  prefilledEmail?: string;
  connection?: string;
  startTimer = false;
}
class RefreshTokenSuccessPayload {
  token: string;
  claims: IdToken;
}
class RefreshTokenFailedPayload {}
class RefreshCompaniesSuccessPayload {
  companies: UserCompany[];
}
class RefreshCompaniesFailedPayload {}

class EnsurePasswordlessUserExistsPayload {
  email: string;
}
class EnsurePasswordlessUserExistsSuccessPayload extends EnsurePasswordlessUserExistsPayload {}
class EnsurePasswordlessUserExistsFailedPayload extends EnsurePasswordlessUserExistsPayload {
  error: any;
}

@Injectable({ providedIn: 'root' })
export class AuthReduxObjectActions {
  login: ActionCreator<AuthActions.Login, (props: { payload: LoginPayload }) => { payload: LoginPayload } & Action<AuthActions.Login>>;
  loginSuccess: ActionCreator<AuthActions.LoginSuccess, (props: { payload: LoginSuccessPayload }) => { payload: LoginSuccessPayload } & Action<AuthActions.LoginSuccess>>;
  loginFailed: ActionCreator<AuthActions.LoginFailed, (props: { payload: LoginFailedPayload }) => { payload: LoginFailedPayload } & Action<AuthActions.LoginFailed>>;
  logout: ActionCreator<AuthActions.Logout, () => Action<AuthActions.Logout>>;

  refreshToken: ActionCreator<AuthActions.RefreshToken, () => Action<AuthActions.RefreshToken>>;
  refreshTokenSuccess: ActionCreator<
    AuthActions.RefreshTokenSuccess,
    (props: { payload: RefreshTokenSuccessPayload }) => { payload: RefreshTokenSuccessPayload } & Action<AuthActions.RefreshTokenSuccess>
  >;
  refreshTokenFailed: ActionCreator<AuthActions.RefreshTokenFailed, (props: { payload: RefreshTokenFailedPayload }) => { payload: RefreshTokenFailedPayload } & Action<AuthActions.RefreshTokenFailed>>;

  refreshTokenForErrorHandler: ActionCreator<AuthActions.RefreshTokenForErrorHandler, () => Action<AuthActions.RefreshTokenForErrorHandler>>;
  refreshTokenForErrorHandlerSuccess: ActionCreator<
    AuthActions.RefreshTokenForErrorHandlerSuccess,
    (props: { payload: RefreshTokenSuccessPayload }) => { payload: RefreshTokenSuccessPayload } & Action<AuthActions.RefreshTokenForErrorHandlerSuccess>
  >;
  refreshTokenForErrorHandlerFailed: ActionCreator<
    AuthActions.RefreshTokenForErrorHandlerFailed,
    (props: { payload: RefreshTokenFailedPayload }) => { payload: RefreshTokenFailedPayload } & Action<AuthActions.RefreshTokenForErrorHandlerFailed>
  >;

  ensurePasswordlessUserExists: ActionCreator<
    AuthActions.EnsurePasswordlessUserExists,
    (props: { payload: EnsurePasswordlessUserExistsPayload }) => { payload: EnsurePasswordlessUserExistsPayload } & Action<AuthActions.EnsurePasswordlessUserExists>
  >;
  ensurePasswordlessUserExistsSuccess: ActionCreator<
    AuthActions.EnsurePasswordlessUserExistsSuccess,
    (props: { payload: EnsurePasswordlessUserExistsSuccessPayload }) => { payload: EnsurePasswordlessUserExistsSuccessPayload } & Action<AuthActions.EnsurePasswordlessUserExistsSuccess>
  >;
  ensurePasswordlessUserExistsFailed: ActionCreator<
    AuthActions.EnsurePasswordlessUserExistsFailed,
    (props: { payload: EnsurePasswordlessUserExistsFailedPayload }) => { payload: EnsurePasswordlessUserExistsFailedPayload } & Action<AuthActions.EnsurePasswordlessUserExistsFailed>
  >;
  clearRequestedPasswordlessUser: ActionCreator<AuthActions.ClearRequestedPasswordlessUser, () => Action<AuthActions.ClearRequestedPasswordlessUser>>;

  refreshCompanies: ActionCreator<AuthActions.RefreshCompanies, () => Action<AuthActions.RefreshCompanies>>;
  refreshCompaniesSuccess: ActionCreator<
    AuthActions.RefreshCompaniesSuccess,
    (props: { payload: RefreshCompaniesSuccessPayload }) => { payload: RefreshCompaniesSuccessPayload } & Action<AuthActions.RefreshCompaniesSuccess>
  >;
  refreshCompaniesFailed: ActionCreator<
    AuthActions.RefreshCompaniesFailed,
    (props: { payload: RefreshCompaniesFailedPayload }) => { payload: RefreshCompaniesFailedPayload } & Action<AuthActions.RefreshCompaniesFailed>
  >;

  updateLastActivityTimestamp: ActionCreator<AuthActions.UpdateLastActivityTimestamp, () => Action<AuthActions.UpdateLastActivityTimestamp>>;
  updateLastActivityTimestampSuccess: ActionCreator<AuthActions.UpdateLastActivityTimestampSuccess, () => Action<AuthActions.UpdateLastActivityTimestampSuccess>>;
}
@Injectable({ providedIn: 'root' })
export class AuthReduxObjectSelectors {
  getAuthToken: MemoizedSelector<object, string, (s1: IAuthState) => string>;
  getCurrentUserCompanies: MemoizedSelector<object, UserCompany[], (s1: IAuthState) => UserCompany[]>;
  getRequestedPasswordlessUser: MemoizedSelector<object, IRequestedPasswordlessUser, (s1: IAuthState) => IRequestedPasswordlessUser>;
  getLastActivityTimestamp: MemoizedSelector<object, number, (s1: IAuthState) => number>;
}
@Injectable({ providedIn: 'root' })
export class AuthReduxObject {
  private _reducerAuth0: ActionReducer<IAuth0State, Action>;
  private _reducerAuthSafari: ActionReducer<IAuthSafariState, Action>;

  // TODO: Follow example in matterpage redux object to combine into action/selector and get rid of default
  default = {
    actions: this._actions,
    selectors: this._selectors
  };

  constructor(
    private _actions: AuthReduxObjectActions,
    private _selectors: AuthReduxObjectSelectors
  ) {
    this._actions.login = createAction(
      AuthActions.Login,
      props<{
        payload: LoginPayload;
      }>()
    );
    this._actions.loginSuccess = createAction(
      AuthActions.LoginSuccess,
      props<{
        payload: LoginSuccessPayload;
      }>()
    );
    this._actions.loginFailed = createAction(
      AuthActions.LoginFailed,
      props<{
        payload: LoginFailedPayload;
      }>()
    );
    this._actions.logout = createAction(AuthActions.Logout);
    this._actions.refreshToken = createAction(AuthActions.RefreshToken);
    this._actions.refreshTokenSuccess = createAction(
      AuthActions.RefreshTokenSuccess,
      props<{
        payload: RefreshTokenSuccessPayload;
      }>()
    );
    this._actions.refreshTokenFailed = createAction(
      AuthActions.RefreshTokenFailed,
      props<{
        payload: RefreshTokenFailedPayload;
      }>()
    );
    this._actions.refreshTokenForErrorHandler = createAction(AuthActions.RefreshTokenForErrorHandler);
    this._actions.refreshTokenForErrorHandlerSuccess = createAction(
      AuthActions.RefreshTokenForErrorHandlerSuccess,
      props<{
        payload: RefreshTokenSuccessPayload;
      }>()
    );
    this._actions.refreshTokenForErrorHandlerFailed = createAction(
      AuthActions.RefreshTokenForErrorHandlerFailed,
      props<{
        payload: RefreshTokenFailedPayload;
      }>()
    );

    this._actions.refreshCompanies = createAction(AuthActions.RefreshCompanies);
    this._actions.refreshCompaniesSuccess = createAction(
      AuthActions.RefreshCompaniesSuccess,
      props<{
        payload: RefreshCompaniesSuccessPayload;
      }>()
    );
    this._actions.refreshCompaniesFailed = createAction(
      AuthActions.RefreshCompaniesFailed,
      props<{
        payload: RefreshCompaniesFailedPayload;
      }>()
    );
    this._actions.ensurePasswordlessUserExists = createAction(
      AuthActions.EnsurePasswordlessUserExists,
      props<{
        payload: EnsurePasswordlessUserExistsPayload;
      }>()
    );
    this._actions.ensurePasswordlessUserExistsSuccess = createAction(
      AuthActions.EnsurePasswordlessUserExistsSuccess,
      props<{
        payload: EnsurePasswordlessUserExistsSuccessPayload;
      }>()
    );
    this._actions.ensurePasswordlessUserExistsFailed = createAction(
      AuthActions.EnsurePasswordlessUserExistsFailed,
      props<{
        payload: EnsurePasswordlessUserExistsFailedPayload;
      }>()
    );
    this._actions.clearRequestedPasswordlessUser = createAction(AuthActions.ClearRequestedPasswordlessUser);

    this._actions.updateLastActivityTimestamp = createAction(AuthActions.UpdateLastActivityTimestamp);
    this._actions.updateLastActivityTimestampSuccess = createAction(AuthActions.UpdateLastActivityTimestampSuccess);

    this._selectors.getAuthToken = createSelector(getAuthState, state => state.auth0.token);
    this._selectors.getCurrentUserCompanies = createSelector(getAuthState, state => state.safari.currentUserCompanies);
    this._selectors.getRequestedPasswordlessUser = createSelector(getAuthState, state => state.safari.requestedPasswordlessUser);
    this._selectors.getLastActivityTimestamp = createSelector(getAuthState, state => state.auth0.lastActivityTimestamp);

    this._createAuthSafariReducer();
    this._createAuth0Reducer();
  }
  private _createAuthSafariReducer() {
    const onArray = [];
    onArray.push(
      on(this._actions.refreshCompaniesSuccess, (state: IAuth0State, action: { payload: RefreshCompaniesSuccessPayload }) => ({
        ...state,
        currentUserCompanies: action.payload.companies
      })),
      on(this._actions.ensurePasswordlessUserExistsSuccess, (state: IAuth0State, action: { payload: EnsurePasswordlessUserExistsSuccessPayload }) => ({
        ...state,
        requestedPasswordlessUser: { ...action.payload }
      })),
      on(this._actions.ensurePasswordlessUserExistsFailed, (state: IAuth0State, action: { payload: EnsurePasswordlessUserExistsFailedPayload }) => ({
        ...state,
        requestedPasswordlessUser: { ...action.payload }
      })),
      on(this._actions.clearRequestedPasswordlessUser, (state: IAuth0State) => ({
        ...state,
        requestedPasswordlessUser: undefined
      }))
    );
    this._reducerAuthSafari = createReducer(
      {
        currentUserCompanies: undefined,
        requestedPasswordlessUser: undefined
      },
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- on array can be anything
      ...onArray
    );
  }
  private _createAuth0Reducer() {
    const onArray = [];

    // Note the difference in values set between request to refreshToken and refreshTokenFailed
    // message. This is not a typo. refreshToken specifically sets to undefined, clearing the token
    // (which filters will block on). Failure resuls in setting the token to NULL which is the signal
    // in the current auth code and various existing observers/checks that something has failed.
    // Same with refreshTokenForErrorHandler and refreshTokenForErrorHandlerFailed
    onArray.push(
      on(this._actions.refreshToken, (state: IAuth0State) => ({
        ...state,
        token: undefined,
        claims: undefined,
        refreshedOn: undefined
      }))
    );

    onArray.push(
      on(this._actions.refreshTokenFailed, (state: IAuth0State) => ({
        ...state,
        token: null,
        claims: null,
        refreshedOn: null
      }))
    );

    onArray.push(
      on(this._actions.refreshTokenSuccess, (state: IAuth0State, action: { payload: RefreshTokenSuccessPayload }) => ({
        ...state,
        token: action.payload.token,
        claims: action.payload.claims,
        refreshedOn: new Date()
      }))
    );

    onArray.push(
      on(this._actions.refreshTokenForErrorHandler, (state: IAuth0State) => ({
        ...state,
        token: undefined,
        claims: undefined,
        refreshedOn: undefined
      }))
    );
    onArray.push(
      on(this._actions.refreshTokenForErrorHandlerFailed, (state: IAuth0State) => ({
        ...state,
        token: null,
        claims: null,
        refreshedOn: null
      }))
    );

    onArray.push(
      on(this._actions.refreshTokenForErrorHandlerSuccess, (state: IAuth0State, action: { payload: RefreshTokenSuccessPayload }) => ({
        ...state,
        token: action.payload.token,
        claims: action.payload.claims,
        refreshedOn: new Date()
      }))
    );

    onArray.push(
      on(this._actions.updateLastActivityTimestampSuccess, (state: IAuth0State) => ({
        ...state,
        lastActivityTimestamp: Date.now()
      }))
    );

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- we just don't know what this will be. Allow any
    this._reducerAuth0 = createReducer(
      {
        token: null,
        claims: null,
        refreshedOn: null,
        lastActivityTimestamp: null
      },
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- on array can be anything
      ...onArray
    );
  }
  get reducerAuth0() {
    return this._reducerAuth0;
  }
  get reducerAuthSafari() {
    return this._reducerAuthSafari;
  }
}
@Injectable({
  providedIn: 'root'
})
export class AuthReducerService {
  constructor(private _authRO: AuthReduxObject) {}
  get reducers() {
    const reducers: ActionReducerMap<IAuthState> = {
      auth0: this._authRO.reducerAuth0,
      safari: this._authRO.reducerAuthSafari
    };
    return reducers;
  }
}
