import { Component } from '@angular/core';
// this seems to be a bug in lint parser. It thinks we are importing the whole library and throws 'Static imports of lazy-loaded libraries are forbidden' error.
// But we're not importing the whole library, just the definittion of the base PageNotFoundComponent, which is a standalone index file.
// eslint-disable-next-line @nx/enforce-module-boundaries -- comments above
import { LogoutComponent as CommonLogoutComponent } from '@safarilaw-webapp/common-pages/logout';

@Component({
  selector: 'sl-rms-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent extends CommonLogoutComponent {}
