import { CrudOperationType } from '@safarilaw-webapp/shared/common-objects-models';
import { concat, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, last, map, toArray } from 'rxjs/operators';
import { LoggerService } from '@safarilaw-webapp/shared/logging';
import { FailedObjectsService } from '@safarilaw-webapp/shared/failed-objects';
export const seqJoin = (sources: Observable<any>[]) => {
  const sourcesLastEmission = [];
  for (const source of sources) {
    sourcesLastEmission.push(source.pipe(last()));
  }
  return concat(...sourcesLastEmission).pipe(toArray());
};
// TODO: module
export const childError = (
  failedObjectsService: FailedObjectsService,
  loggerService: LoggerService,
  operationType: CrudOperationType,
  objectType: string,
  originalContent: any,
  rethrowError = false
) =>
  catchError(error => {
    failedObjectsService.addFailedObject(objectType, {
      originalContent,
      error,
      operation: operationType
    });
    if (rethrowError) {
      return throwError(() => error);
    } else {
      if (loggerService) {
        loggerService.LogError(error, window.location.href, null, 'Handled Child Error');
      }
    }
    return of(null);
  });

/** Takes an object with observables assigned to keys and returns new observable that emits an object with values assigned to the
 * corresponding key names. This avoids the issue with fork join that it accepts and returns an indexed array, where values can be swapped
 * and mismap too easily.
 */
export const safeForkJoin = <T extends { [index: string]: any }, U extends { [key in keyof T]: any }>(observables: T): Observable<U> => {
  const observablesAsArray: Observable<any>[] = [];
  const observableKeys = Object.keys(observables);
  for (const key of observableKeys) {
    observablesAsArray.push(observables[key]);
  }

  return forkJoin(observablesAsArray).pipe(
    map(arrayOfResults => {
      const resultObj = {};
      for (const [index, key] of observableKeys.entries()) {
        resultObj[key] = arrayOfResults[index];
      }
      return resultObj as U;
    })
  );
};
