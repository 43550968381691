export interface NavbarHeaderOptions {
  id: number;
  groupId: number;
  title: string;
  subtitle?: string;
  elementId: string;
  permissionFunction?: string[];
  link?: string;
  expanded?: boolean;
  /** A list of child paths that are not navbar items themselves, but are pages underneath this heading nonetheless.
   * Navigating to one of these paths of the children of these paths will cause this navbar header item to show as active.
   */
  activeChildPaths?: string[];
}

export class NavbarHeader implements Required<NavbarHeaderOptions> {
  id: number;
  groupId: number;
  title: string;
  subtitle: string;
  elementId: string;
  permissionFunction: string[];
  link: string;
  expanded: boolean;
  activeChildPaths: string[];

  constructor(
    idOrOptions: number | NavbarHeaderOptions,
    groupId?: number,
    title?: string,
    elementId?: string,
    permissionFunction?: string[],
    link: string = '',
    expanded: boolean = false,
    /** A list of child paths that are not navbar items themselves, but are pages underneath this heading nonetheless.
     * Navigating to one of these paths of the children of these paths will cause this navbar header item to show as active.
     */
    activeChildPaths: string[] = [],
    subtitle = null
  ) {
    if (typeof idOrOptions === 'object') {
      const options: NavbarHeaderOptions = idOrOptions;
      this.id = options.id;
      this.groupId = options.groupId;
      this.title = options.title;
      this.subtitle = options.subtitle;
      this.elementId = options.elementId;
      this.permissionFunction = options.permissionFunction ?? [];
      this.link = options.link ?? '';
      this.expanded = options.expanded ?? false;
      this.activeChildPaths = options.activeChildPaths ?? [];
    } else {
      this.id = idOrOptions;
      this.groupId = groupId;
      this.title = title;
      this.subtitle = subtitle;
      this.elementId = elementId;
      this.permissionFunction = permissionFunction;
      this.link = link;
      this.expanded = expanded;
      this.activeChildPaths = activeChildPaths;
    }
  }
}
