import { Injectable } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { UI_REDUCER } from '@safarilaw-webapp/shared/redux';

import {
  SafariUiAccordionGroupReduxObject,
  SafariUiAccordionReduxObject,
  SafariUiComponentReduxObject,
  SafariUiDataTableReduxObject,
  SafariUiFilePreviewReduxObject,
  SafariUiFormReduxObject,
  SafariUiTabReduxObject
} from './actions/layout-actions';
import { IAppControlState } from './interfaces/layout-interface';

@Injectable({
  providedIn: 'root'
})
export class UiReducerService {
  constructor(
    private _accordionReduxObject: SafariUiAccordionReduxObject,
    private _accordionControllerReduxObject: SafariUiAccordionGroupReduxObject,
    private _formReduxObject: SafariUiFormReduxObject,
    private _tableReduxObject: SafariUiDataTableReduxObject,
    private _tabReduxObject: SafariUiTabReduxObject,
    private _uiComponentReduxObject: SafariUiComponentReduxObject,
    private _filePreviewObject: SafariUiFilePreviewReduxObject
  ) {}
  get reducers() {
    const reducers: ActionReducerMap<IAppControlState> = {
      componentState: UI_REDUCER(this._uiComponentReduxObject),
      formState: UI_REDUCER(this._formReduxObject),
      listState: UI_REDUCER(this._tableReduxObject),
      filePreviewState: UI_REDUCER(this._filePreviewObject),
      tabState: UI_REDUCER(this._tabReduxObject),
      accordionState: UI_REDUCER(this._accordionReduxObject),
      accordionControllerState: UI_REDUCER(this._accordionControllerReduxObject)
    };
    return reducers;
  }
}
