import { Injectable } from '@angular/core';
import { ActionReducerMap, combineReducers } from '@ngrx/store';
import { UI_REDUCER } from '@safarilaw-webapp/shared/redux';
import { IRmsUiState } from './state.interface';
import { RmsPortalPageUiReduxObject } from '../portal/redux/state';
import { RmsIntakePageUiReduxObject } from '../serve/redux/state';

/* __SAFARI_SCHEMATICS (IMPORTS)__ */

// Technically this service only exists for LPMS feature, but because its used during the initialization
// it can't be declared as provided in FeatureLpmsUiModule since it would result in a dependency to itself before initializing
@Injectable()
export class RmsUiReducerService {
  constructor(private _rmsPortalPageUiReduxObject: RmsPortalPageUiReduxObject, private _rmsIntakePageUiReduxObject: RmsIntakePageUiReduxObject) {}
  get reducers() {
    /* EXAMPLE OF ADDING NEW MODEL FROM API - LOCATIONS THAT NEED TO BE UPDATED */
    const reducers: ActionReducerMap<IRmsUiState> = {
      pages: combineReducers({
        portalPageUiState: UI_REDUCER(this._rmsPortalPageUiReduxObject),
        intakePageUiState: UI_REDUCER(this._rmsIntakePageUiReduxObject)
      })
    };
    return reducers;
  }
}
