import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { ApplicationInsights, ICustomProperties, IExceptionTelemetry } from '@microsoft/applicationinsights-web';
import { ICrudLogger } from '@safarilaw-webapp/shared/common-objects-models';
import { AppConfigurationService } from '@safarilaw-webapp/shared/environment';
import { AppContext } from '../../../context/models/models';

@Injectable({
  providedIn: 'root'
})
export class ApplicationInsightsService implements ICrudLogger {
  private _appInsights: ApplicationInsights;
  private _invalidKey = true;
  private _userId: string;
  private _companyId: string;
  constructor(private _ngZone: NgZone, private _appConfig: AppConfigurationService, private _router: Router) {}
  public initialize() {
    // Check to see if we have a semi-valid key.  If not, don't continue with the setup.
    // Local developers will not have a key by default (see appconfiguration.json).
    if (this._appConfig.loggers?.appInsights?.instrumentationKey != null && this._appConfig.loggers.appInsights.instrumentationKey.trim() !== '') {
      this._invalidKey = false;
      this._appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: this._appConfig.loggers.appInsights.instrumentationKey,
          enableAutoRouteTracking: true,
          // Increasing this value will make appinsights less chatty but will also potentially (and most likely)
          // delay appStable event by that much. One second seems to be OK for now
          maxBatchInterval: 1000
        }
      });
      this._ngZone.runOutsideAngular(() => this._appInsights.loadAppInsights());

      this._appInsights.context.application.ver = this._appConfig.sourceVersion;
      this._loadCustomTelemetryProperties();
      // auth.ts probably already called setAuthenticatedUser but at the time appinsights
      // wasn't initialized, so nothing would happen. Here we'll call it again with the values
      // from the previous call
      if (this._userId) {
        this.setAuthenticatedUser(this._userId, this._companyId);
      }
    }
  }

  private lastChild(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    if (route.firstChild) {
      return this.lastChild(route.firstChild);
    } else {
      return route;
    }
  }
  private _loadCustomTelemetryProperties() {
    this._appInsights.addTelemetryInitializer(envelope => {
      const root = this._router.routerState.snapshot.root;
      const child = this.lastChild(root);

      if (child?.data['operationName']) {
        envelope.tags['ai.operation.name'] = child?.data['operationName'];
      }
      const item = envelope.baseData;
      item.properties = item.properties || {};
    });
  }

  setAuthenticatedUser(userId: string, companyId: string) {
    // We need to store these as setAuthenticatedUser can be called by auth
    // BEFORE intialize() call is made. In this case key is not set yet so
    // the call would be ignored
    this._userId = userId;
    this._companyId = companyId;
    if (this._invalidKey) {
      return;
    }
    this._appInsights.setAuthenticatedUserContext(userId, companyId, false);
  }

  logSwNotAvailable(context: AppContext) {
    if (this._invalidKey) {
      return;
    }
    this.trackEvent('SW NOT ENABLED');
  }
  logListUsingApi(id: string, params: unknown, url: string) {
    if (this._invalidKey) {
      return;
    }
    this.trackEvent('List using API to filter and page', { id, url, params });
  }
  logListReachingApiLimit(id: string, params: unknown, url: string) {
    if (this._invalidKey) {
      return;
    }
    this.trackEvent('List reaching in-memory cutoff limit', { id, url, params });
  }
  logAutoMergeEvent(id: string, message: string) {
    if (this._invalidKey) {
      return;
    }
    this.trackEvent(message, { id });
  }
  trackEvent(name: string, customProperties?: ICustomProperties) {
    if (this._invalidKey) {
      return;
    }
    this._appInsights.trackEvent({ name }, customProperties);
  }
  logError(context: AppContext, ex: IExceptionTelemetry) {
    if (this._invalidKey) {
      return;
    }
    this._appInsights.trackException(ex);
  }
}
