import { Injectable } from '@angular/core';

import { AppContext, APP_COMPANY_KEY, APP_USER_KEY } from '../../models/models';

// A thin wrapper around local storage service that retrieves current user and company info
// In the future if/when angular allows us to query for current component that caused an error
// we could have this service reach into it and extract some sort of component context.
// Unfortunately today component context during error processing is only available in debug mode
@Injectable({
  providedIn: 'root'
})
export class AppContextService {
  getUserContext(): any {
    const userFromStorage = localStorage.getItem(APP_USER_KEY);
    if (userFromStorage != null) {
      return JSON.parse(userFromStorage);
    }
    // In case we don't have these yet just return a scaled down object with no id
    return { id: '--' } as any;
  }
  getCompanyContext(): any {
    const companyFromStorage = localStorage.getItem(APP_COMPANY_KEY);
    if (companyFromStorage != null) {
      return JSON.parse(companyFromStorage);
    }
    // In case we don't have these yet just return a scaled down object with no id
    return { id: '--' } as any;
  }
  getServerVariables(): [{ key: string; value: string }] {
    return [{ key: 'User-Agent', value: window.navigator.userAgent }];
  }
  getAppContext(): AppContext {
    return {
      user: this.getUserContext(),
      company: this.getCompanyContext(),
      serverVariables: this.getServerVariables()
    };
  }
}
