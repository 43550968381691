import { SafariRequired } from '@safarilaw-webapp/shared/crud';

import { Injectable } from '@angular/core';
import { ApiCallContext } from '@safarilaw-webapp/shared/common-objects-models';
import { DeliveryPortalAttachmentModel } from '../../_rms-api-models.generated';
import { RmsDataAdapter } from '../../shared/rms-base-adapter';
import { DeliveryPortalAttachment } from '../models/app/delivery-portal-attachment';

@Injectable({
  providedIn: 'root'
})
export class DeliveryPortalAttachmentAdapter extends RmsDataAdapter<DeliveryPortalAttachment> {
  fromGetModel(model: DeliveryPortalAttachmentModel, context?: ApiCallContext<any>): SafariRequired<DeliveryPortalAttachment> {
    return {
      dateFirstAccessedByServingParty: model.dateFirstAccessedByServingParty,
      dateFirstDeliveredToPortal: model.dateFirstDeliveredToPortal,
      folderName: model.folderName,
      id: this.helpers.fromApiId(model.id, context),
      name: model.name,
      sizeInBytes: model.sizeInBytes
    };
  }
}
