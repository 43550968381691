/* eslint-disable no-restricted-syntax -- low level file */
/* eslint-disable @typescript-eslint/no-unsafe-call -- low level file */
/* eslint-disable @typescript-eslint/no-unsafe-assignment -- low level file */
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Actions, createEffect, ofType, OnIdentifyEffects } from '@ngrx/effects';
import { Action, ActionCreator, Store } from '@ngrx/store';

import {
  ApiCallContext,
  cancelAllFiles,
  CrudOperationType,
  CrudServiceParams,
  FileObject,
  HTTP_STATUS_CODE_API_NOTAUTHORIZED,
  IdName,
  ObjectHelper,
  ObjectHistory,
  ObjectId,
  SafariObject,
  SafariObjectId,
  SafariReduxFileTransferObjectDefinition
} from '@safarilaw-webapp/shared/common-objects-models';
// this is just due to a service.spec.ts. We'll have to figure out how to change that test
// so it doesn't refer back to this file, but for now, it's ok to leave it as-is. Don't care too much about enforcing test lintability
// eslint-disable-next-line @nx/enforce-module-boundaries -- comments above
import { Collection, CrudGenericService, CrudService, SearchService } from '@safarilaw-webapp/shared/crud';
import { AppConfigurationService } from '@safarilaw-webapp/shared/environment';
import { ErrorHandlerBase, ErrorMessageParserService, FileTransferError } from '@safarilaw-webapp/shared/error-handling-message-parser';
import { FAILED_OBJECT_FILE, FailedFile, FailedObjectsService } from '@safarilaw-webapp/shared/failed-objects';

import { inject } from '@angular/core';
import {
  FileOperationDownloadRequest,
  FileOperationInfo,
  FileOperationMoveMultipleFilesRequest,
  FileOperationRemoveMultipleFilesRequest,
  FileOperationRemoveRequest,
  FileOperationType,
  FileOperationUploadRequest
} from '@safarilaw-webapp/shared/common-objects-models';
import { combineLatest, iif, Observable, ObservableInput, ObservedValueOf, of, OperatorFunction, throwError } from 'rxjs';
import { catchError, delay, dematerialize, filter, map, materialize, mergeMap, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import {
  ActionOptions,
  ActionSilenceErrorMode,
  DeleteMultipleObjectsActionFailInfo,
  DeleteMultipleObjectsActionInfo,
  DeleteObjectActionFailInfo,
  DeleteObjectActionInfo,
  HttpMethodOverride,
  LoadObjectActionFailInfo,
  LoadObjectActionInfo,
  LoadObjectListActionInfo,
  SafariObjectListId,
  UpdateObjectListActionFailInfo,
  UpdateObjectListActionInfo,
  UpdateOrCreateActionInfo
} from '../models/object';
import { LoadSearchActionInfo } from '../models/search';
import { reduxActionFail, ReduxErrorUiOption } from './actions/actions';
import { IDropdownState, ISafariObjectState } from './interface';
import { SafariReduxApiObject, SafariReduxDropdownObject, SafariReduxSearchDefinition } from './redux-generator';
// Generic class for uploading and removing files and associating them
// with a parent object

type MapFunction = <T, O extends ObservableInput<any>>(project: (value: T, index: number) => O) => OperatorFunction<T, ObservedValueOf<O>>;

export class ObjectFileTransferServiceBase extends ErrorHandlerBase {
  constructor(
    protected _store: Store<FileOperationInfo>,
    protected _actions: Actions<any>,
    public _fileTransferObject: SafariReduxFileTransferObjectDefinition,
    protected _crudGenericService: CrudGenericService,
    errorParserService: ErrorMessageParserService,
    protected _failedObjectsService: FailedObjectsService,
    protected _appConfig: AppConfigurationService
  ) {
    super(errorParserService);
  }

  // ActionCreator<string, Creator<any[], FileOperationInfo>>, //

  /**
   * @deprecated
   * @param payload
   * @returns
   */
  protected download(payload: FileOperationDownloadRequest): Observable<HttpResponse<Blob> | HttpErrorResponse> {
    return null;
  }
  upload(payload: FileOperationUploadRequest): Observable<any> {
    return null;
  }
  update(payload: FileOperationUploadRequest): Observable<any> {
    return null;
  }
  remove(payload: FileOperationRemoveRequest): Observable<any> {
    return null;
  }
  removeMultiple(payload: FileOperationRemoveMultipleFilesRequest): Observable<any> {
    return null;
  }
  moveMultiple(payload: FileOperationMoveMultipleFilesRequest): Observable<any> {
    return null;
  }
  getFileNameForProgressDisplay(payload: FileOperationUploadRequest): string {
    return payload.file.name;
  }
  getUploadUrl(payload: FileOperationUploadRequest): string {
    return null;
  }
  shouldUseTus(payload: FileOperationUploadRequest): boolean {
    // If somehow there is no file this is definitely not a TUS call
    if (payload.file == null) {
      return false;
    }
    return payload.file.size / 1024 / 1024 >= this._appConfig.uiSettings.file.tusUploadLimitInMb;
  }
  getUpdateMetadataUrl(payload: FileOperationUploadRequest): string {
    return null;
  }

  getUpdateUrl(payload: FileOperationUploadRequest): string {
    return null;
  }
  getRetrieveUrl(parentId: string, id: string = null, additionalInfo: any = null): string {
    return null;
  }
  getRemoveUrl(payload: FileOperationRemoveRequest | FileOperationRemoveMultipleFilesRequest): string {
    return null;
  }
  getRemoveMultipleFilesUrl(payload: FileOperationRemoveMultipleFilesRequest): string {
    return null;
  }
  getMoveMultipleFilesUrl(payload: FileOperationMoveMultipleFilesRequest): string {
    return null;
  }
  getMoveMultipleFoldersUrl(payload: FileOperationMoveMultipleFilesRequest): string {
    return null;
  }
  protected dispatchCancelMessage(
    displayFilename: string,
    actionId: string,
    payload: FileOperationUploadRequest | FileOperationRemoveRequest | FileOperationDownloadRequest | FileOperationRemoveMultipleFilesRequest,
    fileOperationType: FileOperationType
  ) {
    this._store.dispatch(
      this._fileTransferObject.default.actions.processFileFail({
        payload: {
          secondsUntilTransferDialogShown: 0,
          displayFilename,
          downloadLink: null,
          fileName: payload.fileName,
          percentComplete: 100,
          actionId,
          isError: true,
          message: 'Cancelled',
          fileOperationType,
          isPreview: fileOperationType === FileOperationType.Download ? (payload as FileOperationDownloadRequest).isPreview : null,
          parentId: payload.parentId
        } as FileOperationInfo
      })
    );
    return throwError(() => ({ error: 'File cancelled', status: FileTransferError.Cancelled }));
  }
  getAbortPipe(
    displayFilename: string,
    actionId: string,
    payload: FileOperationUploadRequest | FileOperationRemoveRequest | FileOperationDownloadRequest | FileOperationRemoveMultipleFilesRequest,
    fileOperationType: FileOperationType
  ) {
    return this._actions.pipe(
      ofType(this._fileTransferObject.default.actions.cancelTransfer, cancelAllFiles),
      mergeMap(() => {
        if (fileOperationType == FileOperationType.Remove || fileOperationType == FileOperationType.Add) {
          const file = fileOperationType == FileOperationType.Remove ? null : (payload as FileOperationUploadRequest).file;
          const operation = FileOperationType.Remove ? CrudOperationType.Delete : CrudOperationType.Create;
          const originalContent: FailedFile = {
            file,
            fileName: payload.fileName,
            parentId: payload.parentId,
            metadata: payload.metadata,
            id: (payload as FileOperationRemoveRequest | FileOperationUploadRequest).id,
            actionId: payload.actionId
          };
          this._failedObjectsService.addCancelledObject(FAILED_OBJECT_FILE, {
            operation,
            error: new Error('Cancelled'),
            originalContent
          });
        }

        return this.dispatchCancelMessage(displayFilename, actionId, payload, fileOperationType);
      })
    );
  }
  getRemoveAbortPipe(displayFilename: string, actionId: string, payload: FileOperationRemoveRequest) {
    return this.getAbortPipe(displayFilename, actionId, payload, FileOperationType.Remove);
  }
  getRemoveMultipleFilesAbortPipe(displayFilename: string, actionId: string, payload: FileOperationRemoveMultipleFilesRequest) {
    return this.getAbortPipe(displayFilename, actionId, payload, FileOperationType.Remove);
  }
  getMoveMultipleFilesAbortPipe(displayFilename: string, actionId: string, payload: FileOperationRemoveMultipleFilesRequest) {
    return this.getAbortPipe(displayFilename, actionId, payload, FileOperationType.Move);
  }
  getUploadAbortPipe(displayFilename: string, actionId: string, payload: FileOperationUploadRequest) {
    return this.getAbortPipe(displayFilename, actionId, payload, FileOperationType.Add);
  }
  getDownloadAbortPipe(displayFilename: string, actionId: string, payload: FileOperationRemoveRequest) {
    return this.getAbortPipe(displayFilename, actionId, payload, FileOperationType.Download);
  }
  protected get apiEndpoint(): string {
    throw new Error('Not implemented');
  }

  // TODO: I think these don't need to be in this generic service anymore. These are leftovers from
  // the original attachment design. They are still needed for IRs because these objects are integral
  // parts of an IR but they probably should be part of the IR service, not this.
  // This service should deal strictly with FILE transfers
  retrieveAll(parentId: string, params: CrudServiceParams, context: ApiCallContext = null) {
    return this._crudGenericService.retrieveAll(this.getRetrieveUrl(parentId, null, context), params);
  }
  retrieve(id: SafariObjectId, endpoint: string = null): Observable<any> {
    return this._crudGenericService.retrieve(endpoint ? endpoint : `${this.apiEndpoint}attachments/${ObjectId(id)}`);
  }
}

export enum HttpRequestBehaviorType {
  Switch,
  Merge
}
export class HttpRequestBehaviorConfig {
  get?: HttpRequestBehaviorType;
  getAll?: HttpRequestBehaviorType;
  createOrUpdate?: HttpRequestBehaviorType;
}

class BaseEffect<T extends SafariObject> {
  private _errorParserService: ErrorMessageParserService = null;
  private _rateLimit = 5;
  constructor(
    protected _store: Store<any>,
    protected _actions: Actions,
    reduxObject: SafariReduxApiObject<ISafariObjectState<T>, T>,
    childrenReduxObjects: SafariReduxApiObject<any, any>[] = [],
    protected httpRequestBehaviorConfig: HttpRequestBehaviorConfig = null
  ) {
    this._generateEffects(0, reduxObject);
    this._errorParserService = inject(ErrorMessageParserService);
    childrenReduxObjects = childrenReduxObjects ? childrenReduxObjects : [];
    for (let i = 0; i < childrenReduxObjects.length; i++) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- don't know what it could possibly be
      this._generateEffects(1 + i, childrenReduxObjects[i]);
    }
  }

  private _asyncJsError(error: unknown) {
    // This function is called by try/catch blocks and will be called in
    // case of JS errors such as bad code, null ref issues, etc.
    // Unlikely to happen but for consistency we want this to throw with
    // timeout (same as would be the case if it was an API error coming from an async HTTP call,
    // which would be thrown in the next VM cycle)
    return throwError(() => error).pipe(materialize(), delay(0), dematerialize());
  }

  private _generateEffects(effectGroup: number, reduxObject: SafariReduxApiObject<any, any>) {
    const prefix = 'effectGroup' + effectGroup.toString();
    // If this is the main object and not a child for backward compatilibity reasons we have to pass NULL
    // to createXYZEffect functions. When we get rid of all custom written effects we should tweak this so
    // these createXYZEffect functions always generate from what's passed to them (so the main parent object would be
    // treated the same and not "NULL")

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- can be anything
    const proto = Object.getPrototypeOf(reduxObject['service']?.adapter);

    if (Object.prototype.hasOwnProperty.call(proto, 'toUpdateModel') || Object.prototype.hasOwnProperty.call(proto, 'toCreateModel') || reduxObject['service']['_fileTransferService']) {
      this[prefix + 'UpdateObjectEffect$'] = this._createUpdateObjectEffect(reduxObject);
    }
    if (Object.prototype.hasOwnProperty.call(proto, 'toUpdateListModel')) {
      this[prefix + 'UpdateObjectListEffect$'] = this._createUpdateListEffect(reduxObject);
    }
    if (Object.prototype.hasOwnProperty.call(proto, 'fromGetModel')) {
      this[prefix + 'LoadObjectEffect$'] = this._createLoadObjectEffect(reduxObject);
    }
    if (Object.prototype.hasOwnProperty.call(proto, 'fromListModel')) {
      this[prefix + 'LoadObjectListEffect$'] = this._createLoadListEffect(reduxObject);
    }
    /**
     * history is an object-level effect with the idea that other objects may have that
     * but currently the only objects that have history are matters. There is no exact definition
     * of what "history object" looks like. We just return the same as we get from the API in the
     * adapters
     */
    if (Object.prototype.hasOwnProperty.call(proto, 'fromHistory')) {
      this[prefix + 'LoadHistoryEffect$'] = this._createLoadHistoryEffect(reduxObject);
    }
    if (Object.prototype.hasOwnProperty.call(proto, 'toDeleteModel')) {
      this[prefix + 'DeleteObjectEffect$'] = this._createDeleteObjectEffect(reduxObject);
    }
    if (Object.prototype.hasOwnProperty.call(proto, 'toDeleteMultipleModel')) {
      this[prefix + 'DeletMultipleObjectEffect$'] = this._createDeleteMultipleObjectsEffect(reduxObject);
    }
    if (Object.prototype.hasOwnProperty.call(proto, 'toUpdatePartialModel')) {
      this[prefix + 'UpdatePartialObjectEffect$'] = this._createUpdatePartialObjectEffect(reduxObject);
    }
    // Redux object has everything we need for this but to keep backward compatibility createXYZEffect
    // functions still expect a file transfer service object to be passed in separately so we'll have to
    // extract if from here and pass it further below (only if child though - again due to backcompat reasons)
    const fileTransferService = reduxObject['_fileTransferService'];

    if (fileTransferService) {
      // These will eventually change when we move some of the file transfer functionality out of there.
      // Things like move and zip are a bit unknown right now. They are currently implemented in base effect
      // with the idea that there may be other objects that have these but currently only matters have them.
      const protoFile = Object.getPrototypeOf(fileTransferService);
      if (Object.prototype.hasOwnProperty.call(protoFile, 'getUploadUrl') || Object.prototype.hasOwnProperty.call(protoFile, 'getUpdateUrl')) {
        this[prefix + 'UploadFileEffect$'] = this._createUploadFileEffect(reduxObject);
      }
      if (Object.prototype.hasOwnProperty.call(protoFile, 'getRemoveUrl')) {
        this[prefix + 'RemoveFileEffect$'] = this._createRemoveFileEffect(reduxObject);
      }

      if (Object.prototype.hasOwnProperty.call(protoFile, 'getMoveMultipleFilesUrl')) {
        this[prefix + 'MoveMultipleFilesEffect$'] = this._createMoveMultipleFilesEffect(reduxObject);
      }
      if (Object.prototype.hasOwnProperty.call(protoFile, 'getRemoveMultipleFilesUrl')) {
        this[prefix + 'RemoveMultipleFilesEffect$'] = this._createRemoveMultipleFilesEffect(reduxObject);
      }
    }
  }

  private readonly wrappedMergeMap = <T1, O extends ObservableInput<any>>(project: (value: T1, index: number) => O) => mergeMap(project, this._rateLimit);
  private readonly wrappedSwitchMap = <T2, O extends ObservableInput<any>>(project: (value: T2, index: number) => O) => switchMap(project);

  getMapOperator(httpRequestBehavior: HttpRequestBehaviorType): MapFunction {
    return httpRequestBehavior === HttpRequestBehaviorType.Switch ? this.wrappedSwitchMap : this.wrappedMergeMap;
  }

  protected loadHeaders(error: HttpErrorResponse) {
    // This is very annoying - angular's headers are lazy loaded meaning they won't be loaded until
    // accessed. Since we serialize this via redux, that later gets read by error handler service
    // IF we don't "ping" headers they will not be serialized and we won't get them in our error handler.
    // Hence the seemingly usesless call to headers.keys
    if (error instanceof HttpErrorResponse && typeof error?.headers?.keys == 'function') {
      error.headers.keys();
    }
  }
  getGenericLoadObjectListObservable(action: LoadObjectListActionInfo, reduxObject: SafariReduxApiObject<any, any>): Observable<Action> {
    const fn = this._retrieveAllObjects(reduxObject['service'], action.payload) as Observable<{
      items: T[];
      totalCount: number;
    }>;
    const context = action.context;
    const loadAction = reduxObject.default.actions.loadObjectList;
    const loadSuccessAction = reduxObject.default.actions.loadObjectListSuccess;
    const loadFailAction = reduxObject.default.actions.loadObjectListFail;

    const abort$ = this._actions.pipe(
      ofType(loadAction),
      filter(o => o.abort != null && (o.abort == '0' || (action.actionId && action.actionId == o.abort)))
    );
    return fn
      .pipe(
        map((o: { totalCount: number; items: T[] }) =>
          loadSuccessAction({
            originalPayload: action.payload,
            originalOptions: action.options,
            context,
            payload:
              action.options != null && action.options.countOnly
                ? {
                    totalCount: o.totalCount,
                    id: action.payload?.id || '',
                    filter: action.payload?.filter || null,
                    items: null
                  }
                : { ...o, ...{ id: action.payload?.id || '', filter: action.payload?.filter || null } },
            actionId: action.actionId,
            correlationId: action.correlationId
          })
        ),
        takeUntil(abort$)
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.loadHeaders(error);

          this._store.dispatch(
            reduxActionFail({
              error,
              payload: action.payload,
              originalOptions: action.options,
              context: action.context,
              originalPayload: action.payload,
              reduxErrorOptions: {
                uiOption: ReduxErrorUiOption.ErrorPage,
                source: 'BaseEffect.getGenericLoadObjectListObservable()',
                silent: this.shouldSilenceError(error.status, action.options)
              }
            })
          );
          return of(
            loadFailAction({
              error,
              payload: action.payload,
              actionId: action.actionId,
              correlationId: action.correlationId,
              originalPayload: action.payload,
              originalOptions: action.options,
              context
            })
          );
        })
      );
  }
  private shouldSilenceError(status: number, options: ActionOptions) {
    if (status === HTTP_STATUS_CODE_API_NOTAUTHORIZED) {
      return true;
    }
    if (options != null && options.silenceErrors != null) {
      if (options.silenceErrors.mode === ActionSilenceErrorMode.All) {
        return true;
      }
      if (options.silenceErrors.mode === ActionSilenceErrorMode.InList) {
        return options.silenceErrors.errors.includes(status);
      }
      if (options.silenceErrors.mode === ActionSilenceErrorMode.NotInList) {
        return !options.silenceErrors.errors.includes(status);
      }
    }
    return false;
  }
  getGenericDeleteObjectObservable(action: DeleteObjectActionInfo, reduxObject: SafariReduxApiObject<any, any>): Observable<Action> {
    const deleteAction = reduxObject.default.actions.deleteObject;
    const deleteSuccessAction = reduxObject.default.actions.deleteObjectSuccess;
    const deleteFailAction = reduxObject.default.actions.deleteObjectFail;
    const context = action.context;
    const abort$ = this._actions.pipe(
      ofType(deleteAction),
      filter((o: DeleteObjectActionInfo) => o.abort && (o.abort == '0' || (action.actionId && action.actionId == o.abort) || (action.payload && action.payload.id == o.abort)))
    );

    return (this._deleteExistingObject(reduxObject['service'], action) as Observable<T>).pipe(
      map(o => deleteSuccessAction({ payload: o, actionId: action.actionId, correlationId: action.correlationId, originalPayload: o })),
      takeUntil(abort$),
      catchError((error: HttpErrorResponse) => {
        this.loadHeaders(error);
        const service = reduxObject['service'];
        if (service['_fileTransferService']) {
          // This may seem confusing but keep in mind that this is error handling for file delete functionality.
          // If we get a 404 error here that means someone else deleted the file, so we'll just tell the user "Complete"
          // and that's what they'll see in the dialog. No need to be throwing an error for something that's already in the state
          // they want it to be
          const message = error.status == 404 ? 'Completed' : this._errorParserService.getErrorMessageFromErrorObject(error, true);
          const isError = error.status == 404 ? false : true;
          this._store.dispatch(
            service['safariReduxFileTransferObject'].default.actions.updateFileUploadProgress({
              payload: {
                actionId: action.actionId,
                ...action.payload,
                ...{
                  fileOperationType: FileOperationType.Remove,
                  message,
                  isError,
                  percentComplete: 100,
                  downloadLink: null
                }
              } as FileOperationInfo
            })
          );
          this._store.dispatch(
            reduxActionFail({
              error,
              payload: action.payload,

              originalPayload: action.payload,
              reduxErrorOptions: {
                source: 'BaseEffect.createRemoveFileEffect()',
                silent: true
              }
            })
          );
        } else {
          this._store.dispatch(
            reduxActionFail({
              error,
              payload: { id: action.payload.id },
              originalPayload: action.payload,
              originalOptions: action.options,
              context,
              reduxErrorOptions: {
                source: 'BaseEffect.getGenericDeleteObjectObservable()',
                silent: this.shouldSilenceError(error.status, action.options)
              }
            })
          );
        }

        const loadObjectActionFailInfo: DeleteObjectActionFailInfo = {
          error,
          payload: { id: action.payload.id },
          actionId: action.actionId,
          correlationId: action.correlationId,
          originalOptions: action.options,
          context,
          originalPayload: action.payload
        };
        return of(deleteFailAction(loadObjectActionFailInfo));
      })
    );
  }
  getGenericDeleteMultipleObjectObservable(action: DeleteMultipleObjectsActionInfo, reduxObject: SafariReduxApiObject<any, any>): Observable<Action> {
    const deleteAction = reduxObject.default.actions.deleteMultipleObjects;
    const deleteSuccessAction = reduxObject.default.actions.deleteMultipleObjectsSuccess;
    const deleteFailAction = reduxObject.default.actions.deleteMultipleObjectsFail;
    const context = action.context;
    const abort$ = this._actions.pipe(
      ofType(deleteAction),
      filter(o => o.abort && (o.abort == '0' || (action.actionId && action.actionId == o.abort)))
    );

    return (this._deleteExistingMultipleObjects(reduxObject['service'], action.payload.id, action.payload.deletePayload) as Observable<T>).pipe(
      map(o => deleteSuccessAction({ actionId: action.actionId, originalPayload: undefined })),
      takeUntil(abort$),
      catchError((error: HttpErrorResponse) => {
        this.loadHeaders(error);
        this._store.dispatch(
          reduxActionFail({
            error,
            payload: action.payload,
            originalPayload: action.payload,
            originalOptions: action.options,
            context,
            reduxErrorOptions: {
              source: 'BaseEffect.getGenericDeleteMultipleObjectsObservable()',
              silent: this.shouldSilenceError(error.status, action.options)
            }
          })
        );
        const loadObjectActionFailInfo: DeleteMultipleObjectsActionFailInfo = {
          error,
          correlationId: action.correlationId,
          actionId: action.actionId,
          originalOptions: action.options,
          context,
          originalPayload: undefined
        };
        return of(deleteFailAction(loadObjectActionFailInfo));
      })
    );
  }
  getGenericLoadObjectObservable(action: LoadObjectActionInfo<T>, reduxObject: SafariReduxApiObject<any, any>): Observable<Action> {
    const loadAction = reduxObject.default.actions.loadObject;
    const loadSuccessAction = reduxObject.default.actions.loadObjectSuccess;
    const loadFailAction = reduxObject.default.actions.loadObjectFail;

    const abort$ = this._actions.pipe(
      ofType(loadAction),
      filter((o: LoadObjectActionInfo<T>) => o.abort && (o.abort == '0' || (action.actionId && action.actionId == o.abort) || (action.payload && action.payload.id == o.abort)))
    );
    const originalObject = action.original;
    const context = action.context;

    return iif(
      () => action.payload.id == '0',
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- child can be anything
      this.getNewObject(reduxObject['service'] as any),
      this._getExistingObject(reduxObject['service'], action.payload.id, originalObject, context) as Observable<T>
    ).pipe(
      map(o => loadSuccessAction({ payload: o, correlationId: action.correlationId, actionId: action.actionId, originalOptions: action.options, context, originalPayload: { id: action.payload.id } })),
      takeUntil(abort$),
      catchError((error: HttpErrorResponse) => {
        this.loadHeaders(error);
        this._store.dispatch(
          reduxActionFail({
            error,
            payload: { id: action.payload.id },
            originalPayload: { ...{ id: action.payload.id }, ...originalObject },
            originalOptions: action.options,
            context: action.context,
            reduxErrorOptions: {
              maxValidationErrors: action.options?.maxValidationErrors,
              source: 'BaseEffect.getGenericLoadObjectObservable()',
              uiOption: ReduxErrorUiOption.ErrorPage,
              silent: this.shouldSilenceError(error.status, action.options)
            }
          })
        );
        const loadObjectActionFailInfo: LoadObjectActionFailInfo = {
          error,
          originalOptions: action.options,
          context: action.context,
          payload: { id: action.payload.id },
          actionId: action.actionId,
          correlationId: action.correlationId,
          originalPayload: { ...{ id: action.payload.id }, ...originalObject }
        };
        return of(loadFailAction(loadObjectActionFailInfo));
      })
    );
  }

  getGenericUpdateObjectListObservable(action: UpdateObjectListActionInfo<T & { actionId?: string }>, reduxObject: SafariReduxApiObject<any, any>): Observable<Action> {
    const updateObjectListAction = reduxObject.default.actions.updateObjectList;
    const updateObjectListSuccessAction = reduxObject.default.actions.updateObjectListSuccess;
    const updateObjectListFailAction = reduxObject.default.actions.updateObjectListFail;
    const context = action.context;
    const abort$ = this._actions.pipe(
      ofType(updateObjectListAction),
      filter((o: UpdateObjectListActionInfo<T>) => o.abort && (o.abort == '0' || (action.actionId && action.actionId == o.abort)))
    );
    return (this._updateList(reduxObject['service'], action.payload.originalList, action.payload.updatedList) as Observable<Collection<T>>).pipe(
      map(o =>
        updateObjectListSuccessAction({
          payload: o,
          actionId: action.actionId,
          correlationId: action.correlationId,
          context,
          originalOptions: action.options,
          originalPayload: action.payload
        })
      ),
      takeUntil(abort$),
      catchError((error: HttpErrorResponse) => {
        this.loadHeaders(error);
        this._store.dispatch(
          reduxActionFail({
            error,
            originalOptions: action.options,
            context,
            payload: { id: action.payload },
            originalPayload: action.payload,
            reduxErrorOptions: {
              maxValidationErrors: action.options?.maxValidationErrors,
              source: 'BaseEffect.getGenericUpdateObjectsObservable()',
              silent: this.shouldSilenceError(error.status, action.options)
            }
          })
        );
        const updateObjectListFail: UpdateObjectListActionFailInfo<T> = {
          error,
          payload: action.payload,
          originalPayload: action.payload,
          actionId: action.actionId,
          correlationId: action.correlationId,
          context,
          originalOptions: action.options
        };
        return of(updateObjectListFailAction(updateObjectListFail));
      })
    );
  }
  getGenericCreateOrUpdateObjectObservable(action: UpdateOrCreateActionInfo<T>, reduxObject: SafariReduxApiObject<any, any>): Observable<Action> {
    const createOrUpdateAction = reduxObject.default.actions.createOrUpdateObject;
    const createSuccessAction = reduxObject.default.actions.createObjectSuccess;
    const updateSuccessAction = reduxObject.default.actions.updateObjectSuccess;
    const createOrUpdateFailAction = reduxObject.default.actions.createOrUpdateObjectFail;

    // TODO: 7965
    // We can't get rid of __priorId here because createOrUpdateObjectOnce$ listens directly to the action
    // rather than selectors
    let __priorId: SafariObjectId = null;

    // eslint-disable-next-line deprecation/deprecation -- need compatiliby for now
    if (action.payload.__parentIds) {
      // eslint-disable-next-line deprecation/deprecation -- need compatiliby for now
      __priorId = SafariObject.id(...action.payload.__parentIds, SafariObject.idObjectOnly(action.payload.id));
    } else {
      // Otherwise convert itself to commaseparated string (or just a string without commas if there is no hierarchy)
      __priorId = SafariObject.id(action.payload.id);
    }

    const abort$ = this._actions.pipe(
      ofType(createOrUpdateAction),
      filter(
        (o: LoadObjectActionInfo<T>) =>
          o.abort != null &&
          (o.abort == '0' || (action.actionId && action.actionId == o.abort) || (action.payload.actionId && action.payload.actionId == o.abort) || (action.payload.id && action.payload.id == o.abort))
      )
    );

    // We should try to split this higher up somewhere. The two functions below are observables
    // so they won't execute unless they are needed but as they are built up they are calling
    // other functions.
    // If these other functions try to change something that the other observable needs
    // it could cause weird issues
    const f1 = (this._createNewObject(reduxObject['service'], action) as Observable<T>).pipe(
      map(o => {
        o['__priorId'] = __priorId;
        return createSuccessAction({
          originalPayload: action.payload,
          payload: o,
          correlationId: action.correlationId,
          originalOptions: action.options,
          actionId: action.actionId,
          context: action.context
        });
      }),
      takeUntil(abort$)
    );

    const f2 = (this._updateExistingObject(reduxObject['service'], action) as Observable<T>).pipe(
      map(o =>
        updateSuccessAction({
          originalPayload: action.payload,
          payload: o,
          correlationId: action.correlationId,
          originalOptions: action.options,
          actionId: action.actionId,
          context: action.context
        })
      ),
      takeUntil(abort$)
    );

    return iif(
      () =>
        action.options?.methodOverride != null // <-- first check if we're defining method overrides
          ? // If we are defining method overrides then we go to POST (true) if the method is post, otherwise PUT (false)
            action.options.methodOverride == HttpMethodOverride.Post
            ? true
            : false
          : // Otherwise if no method overrides were defined we'll just use the original logic - if new POST (true), otherwise PUT(false)
            ObjectHelper.isNew(action.payload),
      f1,
      f2
    ).pipe(
      catchError((error: HttpErrorResponse) => {
        this.loadHeaders(error);

        if (reduxObject['service']['_fileTransferService'] != null) {
          const payload = action.payload as unknown as FileObject;

          // In the case of error here (which could happen if there was a JS exception, such as nullref, in call to filetransferservice.upload)
          // we'll also want to dispatch processfilefail error.
          // Dispatch the error info that will be caught and shown by the file upload dialog
          this._store.dispatch(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access -- test
            reduxObject['service']['safariReduxFileTransferObject'].default.actions.processFileFail({
              payload: {
                secondsUntilTransferDialogShown: 0,
                displayFilename: action.options?.transferDialogOptions?.displayName || payload.file?.name,
                downloadLink: null,
                fileName: payload.file?.name,
                id: payload.id,
                percentComplete: 100,
                actionId: action.actionId,

                isError: true,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access -- test
                message: reduxObject['service']['_fileTransferService'].getErrorMessage(error, true),
                fileOperationType: FileOperationType.Add,

                totalProcessed: 0,
                totalSize: 0,
                // If we receive a message that has an existing file id BUT no file itself
                // it means we are not trying to update file content but instead are only updating
                // the metadata
                isMetadataUpdate: payload.id != null && payload.file == null
              } as FileOperationInfo
            })
          );
          return of(
            createOrUpdateFailAction({
              payload: action.payload,
              originalPayload: action.payload,
              originalOptions: action.options,
              error,
              actionId: action.actionId,
              context: action.context,
              correlationId: action.correlationId,
              reduxErrorOptions: {
                source: 'BaseEffect.getGenericCreateOrUpdateObjectObservable()',
                silent: true
              }
            })
          );
        } else {
          this._store.dispatch(
            reduxActionFail({
              payload: action.payload,
              error,

              originalPayload: action.payload,
              reduxErrorOptions: {
                mustResolve: true,
                url: window.location.href,
                maxValidationErrors: action.options?.maxValidationErrors,
                source: 'BaseEffect.createOrUpdateObject()',
                silent: this.shouldSilenceError(error.status, action.options)
              }
            })
          );
          return of(
            createOrUpdateFailAction({
              payload: action.payload,
              originalPayload: action.payload,
              originalOptions: action.options,
              error,
              actionId: action.actionId,
              context: action.context,
              correlationId: action.correlationId,
              reduxErrorOptions: {
                source: 'BaseEffect.getGenericCreateOrUpdateObjectObservable()',
                silent: this.shouldSilenceError(error.status, action.options)
              }
            })
          );
        }
      })
    );
  }
  getGenericUpdatePartialObjectObservable(action: UpdateOrCreateActionInfo<T>, reduxObject: SafariReduxApiObject<any, any>): Observable<Action> {
    const updatePartialAction = reduxObject.default.actions.updatePartialObject;
    const updatePartialSuccessAction = reduxObject.default.actions.updatePartialObjectSuccess;
    const updatePartialFailAction = reduxObject.default.actions.updatePartialObjectFail;

    const abort$ = this._actions.pipe(
      ofType(updatePartialAction),
      filter(
        (o: LoadObjectActionInfo<T>) =>
          o.abort != null &&
          (o.abort == '0' || (action.actionId && action.actionId == o.abort) || (action.payload.actionId && action.payload.actionId == o.abort) || (action.payload.id && action.payload.id == o.abort))
      )
    );

    const f2 = (this._partiallyUpdateExistingObject(reduxObject['service'], action, null) as Observable<T>).pipe(
      map(o =>
        updatePartialSuccessAction({
          originalPayload: action.payload,
          payload: o,
          correlationId: action.correlationId,
          originalOptions: action.options,
          actionId: action.actionId,
          context: action.context
        })
      ),
      takeUntil(abort$)
    );

    return f2.pipe(
      catchError((error: HttpErrorResponse) => {
        this.loadHeaders(error);

        this._store.dispatch(
          reduxActionFail({
            payload: action.payload,
            error,

            originalPayload: action.payload,
            reduxErrorOptions: {
              mustResolve: true,
              url: window.location.href,
              maxValidationErrors: action.options?.maxValidationErrors,
              source: 'BaseEffect.createOrUpdateObject()',
              silent: this.shouldSilenceError(error.status, action.options)
            }
          })
        );

        return of(
          updatePartialFailAction({
            payload: action.payload,
            originalPayload: action.payload,
            originalOptions: action.options,
            error,
            actionId: action.actionId,
            context: action.context,
            correlationId: action.correlationId,
            reduxErrorOptions: {
              source: 'BaseEffect.getGenericCreateOrUpdateObjectObservable()',
              silent: this.shouldSilenceError(error.status, action.options)
            }
          })
        );
      })
    );
  }
  private _createLoadListEffect(reduxObject: SafariReduxApiObject<any, any>) {
    const type = reduxObject.default.actions.loadObjectList;
    if (type == null) {
      // eslint-disable-next-line no-console -- ok here, just adding some extra info to the console
      console.error('Redux object effect mismatch', reduxObject);
      throw new Error('Could not find action for load list effect');
    }

    const mapOperator = this.getMapOperator(this.httpRequestBehaviorConfig?.getAll);

    return createEffect(() =>
      this._actions.pipe(
        ofType(type),
        filter((o: LoadObjectListActionInfo) => o.abort == null),
        mapOperator((action: LoadObjectListActionInfo) => this.getGenericLoadObjectListObservable(action, reduxObject))
      )
    );
  }
  private _createLoadHistoryEffect(reduxObject: SafariReduxApiObject<any, any>) {
    return createEffect(() =>
      this._actions.pipe(
        ofType(reduxObject.default.actions.loadObjectHistory),
        filter(o => !o.abort),
        mergeMap(action => {
          const abort$ = this._actions.pipe(
            ofType(reduxObject.default.actions.loadObjectHistory),
            filter(o => o.abort && (o.abort == '0' || (action.actionId && action.actionId == o.abort) || (action.payload && action.payload.id == o.abort)))
          );

          return (
            iif(
              () => action.payload.id == '0',
              // no history on a brand new object. should not be called by UI though
              of(null),
              reduxObject['service'].retrieveHistory(action.payload.id)
            )
              // .pipe(map(o => loadHistorySuccess({ payload: o })))
              .pipe(
                map((o: ObjectHistory) =>
                  reduxObject.default.actions.loadObjectHistorySuccess({
                    originalPayload: action.payload,
                    context: action.context,
                    originalOptions: action.options,
                    correlationId: action.correlationId,
                    actionId: action.actionId,
                    payload: {
                      id: action.payload.id,
                      history: o
                    }
                  })
                ),
                takeUntil(abort$)
              )
              .pipe(
                catchError((error: HttpErrorResponse) => {
                  this.loadHeaders(error);
                  // Let's not throw errors if loading history fails (for now)
                  return of(
                    reduxObject.default.actions.loadObjectHistoryFail({
                      originalOptions: action.options,
                      context: action.context,
                      payload: { id: action.payload.id, history: null },
                      error,
                      actionId: action.actionId,
                      correlationId: action.correlationId,
                      originalPayload: { id: action.payload.id, history: null }
                    })
                  );
                })
              )
          );
        })
      )
    );
  }
  private _createDeleteObjectEffect(reduxObject: SafariReduxApiObject<any, any>) {
    const type = reduxObject.default.actions.deleteObject;

    if (type == null) {
      // eslint-disable-next-line no-console -- ok here, just adding some extra info to the console
      console.error('Redux object effect mismatch', reduxObject);
      throw new Error('Could not find action for delete object effect');
    }

    return createEffect(() =>
      this._actions.pipe(
        ofType(type),
        filter((o: LoadObjectActionInfo<T>) => o.abort == null),
        mergeMap((action: LoadObjectActionInfo<T>) => this.getGenericDeleteObjectObservable(action, reduxObject), this._rateLimit)
      )
    );
  }
  private _createDeleteMultipleObjectsEffect(reduxObject: SafariReduxApiObject<any, any>) {
    const type = reduxObject.default.actions.deleteMultipleObjects;

    if (type == null) {
      // eslint-disable-next-line no-console -- ok here, just adding some extra info to the console
      console.error('Redux object effect mismatch', reduxObject);
      throw new Error('Could not find action for delete object effect');
    }

    return createEffect(() =>
      this._actions.pipe(
        ofType(type),
        filter(o => o.abort == null),
        mergeMap(action => this.getGenericDeleteMultipleObjectObservable(action, reduxObject), this._rateLimit)
      )
    );
  }
  private _createLoadObjectEffect(reduxObject: SafariReduxApiObject<any, any>) {
    const type = reduxObject.default.actions.loadObject;

    if (type == null) {
      // eslint-disable-next-line no-console -- ok here, just adding some extra info to the console
      console.error('Redux object effect mismatch', reduxObject);
      throw new Error('Could not find action for load object effect');
    }

    const mapOperator = this.getMapOperator(this.httpRequestBehaviorConfig?.get);

    return createEffect(() =>
      this._actions.pipe(
        ofType(type),
        filter((o: LoadObjectActionInfo<T>) => o.abort == null),
        mapOperator((action: LoadObjectActionInfo<T>) => this.getGenericLoadObjectObservable(action, reduxObject))
      )
    );
  }
  private _createUpdateObjectEffect(reduxObject: SafariReduxApiObject<any, any>) {
    const type = reduxObject.default.actions.createOrUpdateObject;
    if (type == null) {
      // eslint-disable-next-line no-console -- ok here, just adding some extra info to the console
      console.error('Redux object effect mismatch', reduxObject);
      throw new Error('Could not find action for update object effect');
    }

    const mapOperator = this.getMapOperator(this.httpRequestBehaviorConfig?.createOrUpdate);

    return createEffect(() =>
      this._actions.pipe(
        ofType(type),
        filter((o: UpdateOrCreateActionInfo<T>) => !o.abort),
        mapOperator(action => this.getGenericCreateOrUpdateObjectObservable(action, reduxObject))
      )
    );
  }
  private _createUpdatePartialObjectEffect(reduxObject: SafariReduxApiObject<any, any>) {
    const type = reduxObject.default.actions.updatePartialObject;
    if (type == null) {
      // eslint-disable-next-line no-console -- ok here, just adding some extra info to the console
      console.error('Redux object effect mismatch', reduxObject);
      throw new Error('Could not find action for update object effect');
    }
    return createEffect(() =>
      this._actions.pipe(
        ofType(type),
        filter((o: UpdateOrCreateActionInfo<T>) => !o.abort),
        mergeMap(action => this.getGenericUpdatePartialObjectObservable(action, reduxObject), this._rateLimit)
      )
    );
  }
  private _createUpdateListEffect(reduxObject: SafariReduxApiObject<any, any>) {
    const type = reduxObject.default.actions.updateObjectList;
    if (type == null) {
      // eslint-disable-next-line no-console -- ok here, just adding some extra info to the console
      console.error('Redux object effect mismatch', reduxObject);
      throw new Error('Could not find action for update list effect');
    }
    return createEffect(() =>
      this._actions.pipe(
        ofType(type),
        filter((o: UpdateObjectListActionInfo<T>) => o.abort == null),
        mergeMap(action => this.getGenericUpdateObjectListObservable(action, reduxObject), this._rateLimit)
      )
    );
  }

  private _createUploadFileEffect(reduxObject: SafariReduxApiObject<any, any>) {
    const service = reduxObject['_fileTransferService'];

    const type = reduxObject.default.actions.uploadFile;
    if (type == null) {
      // eslint-disable-next-line no-console -- ok here, just adding some extra info to the console
      console.error('Redux object effect mismatch', reduxObject);
      throw new Error('Could not find action for upload file effect');
    }
    return createEffect(() =>
      this._actions.pipe(
        ofType(type),

        mergeMap(action => {
          const payload = { ...(action.payload as FileOperationUploadRequest) };
          payload.actionId = payload.actionId ? payload.actionId : uuidv4();

          return service.upload(payload).pipe(
            map((o: { __etag: string }) => {
              const actionToReturn = reduxObject.default.actions.uploadFileSuccess;
              return actionToReturn({ payload: { ...payload, ...{ etag: o.__etag } } });
            }),
            catchError((error: HttpErrorResponse) => {
              this.loadHeaders(error);

              // In the case of error here (which could happen if there was a JS exception, such as nullref, in call to filetransferservice.upload)
              // we'll also want to dispatch processfilefail error.
              // Dispatch the error info that will be caught and shown by the file upload dialog
              this._store.dispatch(
                service._fileTransferObject.default.actions.processFileFail({
                  payload: {
                    secondsUntilTransferDialogShown: payload.secondsUntilTransferDialogShown,
                    displayFilename: payload.displayFilename,
                    downloadLink: null,
                    fileName: payload.fileName,
                    id: payload.id,
                    percentComplete: 100,
                    actionId: payload.actionId,

                    isError: true,
                    message: service.getErrorMessage(error, true),
                    fileOperationType: FileOperationType.Add,
                    parentId: payload.parentId,
                    metadata: payload.metadata,
                    totalProcessed: 0,
                    totalSize: 0,
                    // If we receive a message that has an existing file id BUT no file itself
                    // it means we are not trying to update file content but instead are only updating
                    // the metadata
                    isMetadataUpdate: payload.id != null && payload.file == null
                  } as FileOperationInfo
                })
              );
              // Dispatch the redux error (this will get logged)
              this._store.dispatch(
                reduxActionFail({
                  error,
                  payload,
                  originalPayload: action.payload,

                  reduxErrorOptions: {
                    source: 'BaseEffect.createUploadFileEffect()',
                    silent: true
                  }
                })
              );
              const actionToReturn = reduxObject.default.actions.uploadFileFail;
              // Just return a standard fail error
              return of(actionToReturn({ error, originalPayload: action.payload }));
            })
          );
        }, this._rateLimit)
      )
    );
  }
  private _createRemoveFileEffect(reduxObject: SafariReduxApiObject<any, any>) {
    const service = reduxObject['_fileTransferService'];

    const type = reduxObject.default.actions.removeFile;
    if (type == null) {
      // eslint-disable-next-line no-console -- ok here, just adding some extra info to the console
      console.error('Redux object effect mismatch', reduxObject);
      throw new Error('Could not find action for remove fil effect');
    }
    return createEffect(() =>
      this._actions.pipe(
        ofType<ReturnType<ActionCreator<any, (props: { payload: FileOperationRemoveRequest }) => { payload: FileOperationRemoveRequest } & Action<any>>>>(type),

        mergeMap((action: { payload: FileOperationRemoveRequest }) => {
          const payload = { ...action.payload };
          payload.actionId = payload.actionId ? payload.actionId : uuidv4();
          return service.remove(payload).pipe(
            map((info: FileOperationInfo) => {
              const actionToReturn = reduxObject.default.actions.removeFileSuccess;
              return actionToReturn({ payload: { ...info } });
            }),
            catchError((error: HttpErrorResponse) => {
              this.loadHeaders(error);

              const message = error.status == 404 ? 'Completed' : service.getErrorMessage(error, true);
              const isError = error.status == 404 ? false : true;
              this._store.dispatch(
                service._fileTransferObject.default.actions.updateFileUploadProgress({
                  payload: {
                    ...payload,
                    ...{
                      fileOperationType: FileOperationType.Remove,
                      message,
                      isError,
                      percentComplete: 100,
                      downloadLink: null
                    }
                  } as FileOperationInfo
                })
              );
              this._store.dispatch(
                reduxActionFail({
                  error,
                  payload,

                  originalPayload: action.payload,
                  reduxErrorOptions: {
                    source: 'BaseEffect.createRemoveFileEffect()',
                    silent: true
                  }
                })
              );
              const actionToReturn = reduxObject.default.actions.removeFileFail;
              return of(actionToReturn({ error, originalPayload: action.payload }));
            })
          );
        }, this._rateLimit)
      )
    );
  }

  private _createMoveMultipleFilesEffect(reduxObject: SafariReduxApiObject<any, any>) {
    const service = reduxObject['_fileTransferService'];

    const type = reduxObject.default.actions.moveMultipleFiles;

    if (type == null) {
      // eslint-disable-next-line no-console -- ok here, just adding some extra info to the console
      console.error('Redux object effect mismatch', reduxObject);
      throw new Error('Could not find action for move files effect');
    }
    return createEffect(() =>
      this._actions.pipe(
        ofType<ReturnType<ActionCreator<any, (props: { payload: FileOperationMoveMultipleFilesRequest }) => { payload: FileOperationMoveMultipleFilesRequest } & Action<any>>>>(type),

        mergeMap((action: { payload: FileOperationMoveMultipleFilesRequest }) => {
          const payload = { ...action.payload };
          payload.actionId = payload.actionId ? payload.actionId : uuidv4();

          return service.moveMultiple(payload).pipe(
            map((info: FileOperationInfo) => {
              const actionToReturn = reduxObject.default.actions.moveMultipleFilesSuccess;

              return actionToReturn({ payload: { ...info } });
            }),
            catchError((error: HttpErrorResponse) => {
              this.loadHeaders(error);

              const message = error.status == 404 ? 'Completed' : service.getErrorMessage(error, true);
              const isError = error.status == 404 ? false : true;
              for (const fileRequest of payload.fileRequests) {
                this._store.dispatch(
                  service._fileTransferObject.default.actions.updateFileUploadProgress({
                    payload: {
                      ...fileRequest,
                      ...{
                        fileOperationType: FileOperationType.Move,
                        message,
                        isError,
                        percentComplete: 100,
                        downloadLink: null
                      }
                    } as FileOperationInfo
                  })
                );
                this._store.dispatch(
                  reduxActionFail({
                    error,
                    payload: fileRequest,

                    originalPayload: fileRequest,
                    reduxErrorOptions: {
                      source: 'BaseEffect.createRemoveMultipleFilesEffect()',
                      silent: true
                    }
                  })
                );
              }

              const actionToReturn = reduxObject.default.actions.removeMultipleFilesFail;
              return of(actionToReturn({ error, originalPayload: action.payload }));
            })
          );
        }, this._rateLimit)
      )
    );
  }
  private _createRemoveMultipleFilesEffect(reduxObject: SafariReduxApiObject<any, any>) {
    const service = reduxObject['_fileTransferService'];

    const type = reduxObject.default.actions.removeMultipleFiles;

    if (type == null) {
      // eslint-disable-next-line no-console -- ok here, just adding some extra info to the console
      console.error('Redux object effect mismatch', reduxObject);
      throw new Error('Could not find action for remove multiple files effect');
    }
    return createEffect(() =>
      this._actions.pipe(
        ofType<ReturnType<ActionCreator<any, (props: { payload: FileOperationRemoveMultipleFilesRequest }) => { payload: FileOperationRemoveMultipleFilesRequest } & Action<any>>>>(type),

        mergeMap((action: { payload: FileOperationRemoveMultipleFilesRequest }) => {
          const payload = { ...action.payload };
          payload.actionId = payload.actionId ? payload.actionId : uuidv4();
          return service.removeMultiple(payload).pipe(
            map((info: FileOperationInfo) => {
              const actionToReturn = reduxObject.default.actions.removeMultipleFilesSuccess;
              return actionToReturn({ payload: { ...info } });
            }),
            catchError((error: HttpErrorResponse) => {
              this.loadHeaders(error);

              const message = error.status == 404 ? 'Completed' : service.getErrorMessage(error, true);
              const isError = error.status == 404 ? false : true;
              for (const fileRequest of payload.fileRequests) {
                this._store.dispatch(
                  service._fileTransferObject.default.actions.updateFileUploadProgress({
                    payload: {
                      ...fileRequest,
                      ...{
                        fileOperationType: FileOperationType.Remove,
                        message,
                        isError,
                        percentComplete: 100,
                        downloadLink: null
                      }
                    } as FileOperationInfo
                  })
                );
                this._store.dispatch(
                  reduxActionFail({
                    error,
                    payload: fileRequest,

                    originalPayload: fileRequest,
                    reduxErrorOptions: {
                      source: 'BaseEffect.createRemoveMultipleFilesEffect()',
                      silent: true
                    }
                  })
                );
              }

              const actionToReturn = reduxObject.default.actions.removeMultipleFilesFail;
              return of(actionToReturn({ error, originalPayload: action.payload }));
            })
          );
        }, this._rateLimit)
      )
    );
  }

  private _getExistingObject(service: CrudService<any>, id: SafariObjectId, payload: T = null, context: ApiCallContext): Observable<any> {
    try {
      return service.retrieve(id, payload, context);
    } catch (error: unknown) {
      return this._asyncJsError(error);
    }
  }
  private _createNewObject(service: CrudService<any>, action: UpdateOrCreateActionInfo<T>) {
    try {
      return service.create(
        action.payload,
        {
          autoRetrieveOnUpdate: action.options?.autoRetrieveOnUpdate,
          transferDialogOptions: action.options?.transferDialogOptions,
          whitelistedMergePaths: action.options?.whitelistedMergePaths,
          actionId: action.actionId
        },
        action.context
      );
    } catch (error: unknown) {
      return this._asyncJsError(error);
    }
  }

  private _retrieveAllObjects(service: CrudService<any>, effectParams: SafariObjectListId): Observable<{ items: T[]; totalCount: number }> {
    try {
      return service.retrieveAll(
        effectParams != null ? effectParams.filter : null,
        effectParams != null ? (SafariObject.idIsCompound(effectParams.id) ? SafariObject.idToArray(effectParams.id) : effectParams.id ? [SafariObject.id(effectParams.id)] : []) : null
      );
    } catch (error: unknown) {
      return this._asyncJsError(error);
    }
  }
  // eslint-disable-next-line @typescript-eslint/naming-convention -- special property
  private _updateExistingObject(service: CrudService<any>, action: UpdateOrCreateActionInfo<T>) {
    try {
      return service.update(
        action.payload,
        {
          autoRetrieveOnUpdate: action.options?.autoRetrieveOnUpdate,
          whitelistedMergePaths: action.options?.whitelistedMergePaths,
          actionId: action.actionId
        },
        action.context
      );
    } catch (error: unknown) {
      return this._asyncJsError(error);
    }
  }
  // eslint-disable-next-line @typescript-eslint/naming-convention -- special property
  private _partiallyUpdateExistingObject(service: CrudService<any>, action: UpdateOrCreateActionInfo<T>, original: T) {
    try {
      return service.updatePartial(
        action.payload,
        original,
        {
          autoRetrieveOnUpdate: action.options?.autoRetrieveOnUpdate,
          whitelistedMergePaths: action.options?.whitelistedMergePaths,
          actionId: action.actionId
        },
        action.context
      );
    } catch (error: unknown) {
      return this._asyncJsError(error);
    }
  }

  private _deleteExistingObject(service: CrudService<any>, action: DeleteObjectActionInfo): Observable<any> {
    try {
      return service.delete(action.payload.id, action.payload, null, {
        transferDialogOptions: action.options?.transferDialogOptions,

        actionId: action.actionId
      });
    } catch (error: unknown) {
      return this._asyncJsError(error);
    }
  }
  private _deleteExistingMultipleObjects(service: CrudService<any>, id: SafariObjectId, payload: any = null): Observable<any> {
    try {
      return service.deleteMultiple(id, payload);
    } catch (error: unknown) {
      return this._asyncJsError(error);
    }
  }
  protected getNewObject(service: CrudService<T>): Observable<T> {
    try {
      if (service.adapter) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- can be anything
        const proto = Object.getPrototypeOf(service.adapter);
        if (proto && Object.prototype.hasOwnProperty.call(proto, 'fromGetNewModel')) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call -- TODO: getNewObject refactor
          return of(service.adapter.fromGetNewModel() as unknown as T);
        }
      }
      // TODO: We need to start adding this to service and converting existing objects as we go.

      throw new Error('getNewObject Must be defined in child!');
    } catch (error: unknown) {
      return this._asyncJsError(error);
    }
  }
  private _updateList(service: CrudService<any>, originalList: any[], updatedList: any[]) {
    try {
      return service.updateAll(originalList, updatedList);
    } catch (error: unknown) {
      return this._asyncJsError(error);
    }
  }
}
export class DropdownUrlOverride {
  url: string;
  baseEndpoint: string;
}

export class DropdownEndpointMapper {
  get(id: any): DropdownUrlOverride {
    return null;
  }
}

export class EffectWithIdentifier<T extends SafariObject> extends BaseEffect<T> implements OnIdentifyEffects {
  private _id: string = null;
  constructor(
    store: Store<any>,
    actions: Actions,
    reduxObject: SafariReduxApiObject<ISafariObjectState<T>, T>,
    children: SafariReduxApiObject<any, any>[] = [],
    httpRequestBehaviorConfig: HttpRequestBehaviorConfig = null
  ) {
    super(store, actions, reduxObject, children, httpRequestBehaviorConfig);
    this._id = uuidv4();
  }
  ngrxOnIdentifyEffects(): string {
    return this._id;
  }
}

export class GenericDropdownEffects<T extends IDropdownState> implements OnIdentifyEffects {
  private _id: string;
  constructor(
    private _actions: Actions,
    private _store: Store<any>,
    protected _dropdownReduxObject: SafariReduxDropdownObject<T>,
    protected _alwaysLoad: SafariObjectId[] = [],
    protected _enumToEndpointMapper: DropdownEndpointMapper
  ) {
    this._id = uuidv4();
    this['loadDropdowns$'] = this.createLoadDropdownEffect();
    this['loadBulkDropdowns$'] = this.createLoadBulkDropdownEffect();
    for (let i = 0; i < this._alwaysLoad.length; i++) {
      // Normalize to comma sep string if needed
      this._alwaysLoad[i] = SafariObject.id(this._alwaysLoad[i]);
    }
  }
  ngrxOnIdentifyEffects(): string {
    return this._id;
  }

  createLoadDropdownEffect() {
    return createEffect(() =>
      this._actions.pipe(
        ofType(this._dropdownReduxObject.default.actions.loadDropdown),
        // Get the very latest from store for this dropdown ID
        mergeMap(action => of(action).pipe(withLatestFrom(this._store.select(this._dropdownReduxObject.default.selectors.dropdownState(action.id))))),
        mergeMap(([action, dropdownValues]) => {
          const idForService = SafariObject.idToArray(action.id).join('/');
          const idForStorage: string = SafariObject.id(action.id);
          let url = `picklists/${idForService}`;
          let baseEndpoint: string = null;
          if (this._enumToEndpointMapper != null) {
            const mapped: DropdownUrlOverride = this._enumToEndpointMapper.get(idForStorage);
            // if there was an override defined use that instead of basic mapping
            if (mapped != null) {
              url = 'picklists/' + mapped.url;
              baseEndpoint = mapped.baseEndpoint;
            }
          }
          if (this._alwaysLoad.includes(idForStorage) || !dropdownValues?.length) {
            return this._dropdownReduxObject.service
              .retrieveByUrl(url, baseEndpoint)
              .pipe(map(o => this._dropdownReduxObject.default.actions.loadDropdownSuccess({ id: idForStorage, dropdownValues: o })));
          }
          return of(this._dropdownReduxObject.default.actions.loadDropdownSuccess({ id: idForStorage, dropdownValues }));
        })
      )
    );
  }
  private _getIdForStorage(parentId: SafariObjectId, dropdownId: SafariObjectId) {
    const idWithParent = [];
    if (parentId) {
      idWithParent.push(parentId);
    }
    idWithParent.push(dropdownId);
    return SafariObject.id(idWithParent);
  }
  createLoadBulkDropdownEffect() {
    return createEffect(() =>
      this._actions.pipe(
        ofType(this._dropdownReduxObject.default.actions.loadBulkDropdown),
        mergeMap(action => {
          const selectors: Observable<[string, IdName[]]>[] = [];

          for (const id of action.dropdownIds) {
            const idWithParent = [];
            if (action.id) {
              idWithParent.push(action.id);
            }
            idWithParent.push(id);
            const idForStorage: string = SafariObject.id(idWithParent);

            selectors.push(combineLatest([of(idForStorage), this._store.select(this._dropdownReduxObject.default.selectors.dropdownState(idForStorage))]).pipe(take(1)));
          }

          return combineLatest([of(action), ...selectors]).pipe(take(1));
        }),
        mergeMap(([action, ...dropdownValues]) => {
          const typesArray: string[] = [];

          for (let i = 0; i < dropdownValues.length; i++) {
            if (this._alwaysLoad.includes(action.dropdownIds[i]) || dropdownValues[i][1] == null || !dropdownValues[i][1].length) {
              typesArray.push(SafariObject.id(action.dropdownIds[i]));
            } else {
              this._store.dispatch(this._dropdownReduxObject.default.actions.loadDropdownSuccess({ id: dropdownValues[i][0], dropdownValues: dropdownValues[i][1] }));
            }
          }
          if (typesArray?.length) {
            let url = 'picklists';
            if (action.id) {
              url += '/' + SafariObject.idToArray(action.id).join('/');
            }
            url += '?typesCsv=' + typesArray.join(',');

            this._dropdownReduxObject.service
              .retrieveInBulk(url)
              .pipe(
                mergeMap(o => {
                  for (const dropdownResult of o) {
                    const id = this._getIdForStorage(action.id, dropdownResult.type);
                    this._store.dispatch(this._dropdownReduxObject.default.actions.loadDropdownSuccess({ id, dropdownValues: dropdownResult.values }));
                  }

                  return of(o);
                }),
                take(1)
              )
              .subscribe();
          }

          return of(this._dropdownReduxObject.default.actions.loadBulkDropdownSuccess(null));
        })
      )
    );
  }
}

export class GenericSearchEffects {
  constructor(
    private _actions: Actions,
    private _searchService: SearchService,
    protected _searchReduxObject: SafariReduxSearchDefinition<any>,
    protected _searchEnum
  ) {}

  createLoadSearchEffect() {
    return createEffect(
      () =>
        this._actions.pipe(
          ofType(this._searchReduxObject.default.actions.loadSearch),
          filter<ReturnType<() => LoadSearchActionInfo>>(o => o.abort == null),
          switchMap((action: { id: string; query: string; actionId: string }) => {
            const abort$ = this._actions.pipe(
              ofType(this._searchReduxObject.default.actions.loadSearch),
              filter<ReturnType<() => LoadSearchActionInfo>>(o => o.abort != null && (o.abort == '0' || (action.actionId && action.actionId == o.abort) || (action.id && action.id == o.abort)))
            );
            const propName: string = action.id.toLowerCase();
            const query = action.query ?? '';
            const url = `search/${propName}`;
            const baseEndpoint: string = null;
            return this._searchService.retrieveByUrl(`${url}?query=${query}`, baseEndpoint).pipe(
              // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- ignore for now, will fix with strongtype search
              map(o => this._searchReduxObject.default.actions.loadSearchSuccess({ id: action.id, searchResult: o })),
              takeUntil(abort$)
            );
          })
        ) as Observable<Action>
    );
  }
}
