import { AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { User } from '@safarilaw-webapp/feature/co-manage/data-access';
import { RmsDataAccessObject } from '@safarilaw-webapp/feature/rms/data-access';
import { HeaderComponent } from '@safarilaw-webapp/shared/app-bootstrap';
import { SafariObject } from '@safarilaw-webapp/shared/common-objects-models';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { Observable, delay, filter, map } from 'rxjs';

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector -- ignore NG
  selector: 'sl-bootstrap-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [RouterLink, AsyncPipe, BsDropdownModule]
})
export class RmsHeaderComponent extends HeaderComponent implements OnInit {
  private RmsObject: RmsDataAccessObject;

  headerModel$: Observable<any>;
  private _router: Router;

  companyLogoUrl$: Observable<string>;
  companyLabel$: Observable<string>;
  useCompanyLabel$: Observable<boolean>;
  useCompanyLogo$: Observable<boolean>;
  constructor() {
    super();
    this._router = this.inject(Router);
    this.RmsObject = this.inject(RmsDataAccessObject);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.companyLogoUrl$ = this._store.select(this._appUiReduxObject.default.selectors.getCompanyLogoUrl).pipe(delay(0));
    this.companyLabel$ = this._store.select(this._appUiReduxObject.default.selectors.getCompanyLabel).pipe(delay(0));
    this.useCompanyLabel$ = this._store.select(this._appUiReduxObject.default.selectors.getUseCompanyLabel);
    this.useCompanyLogo$ = this._store.select(this._appUiReduxObject.default.selectors.getUseCompanyLogo);

    this.currentUserInfo$ = this.observeObject$(this.RmsObject.Profile, SafariObject.NOID).pipe(
      filter(o => o != null),
      // TODO: 8387
      map(o => ({ email: o.email, initials: o.name ? o.name[0] : o.email[0], name: o.name }) as unknown as User)
    );
  }
}
