// DO NOT EXPORT THIS FILE IN INDEX.TS.
// THIS IS MEANT TO STAY INTERNAL

export enum DropdownActionTypes {
  ClearDropdown = 0,
  LoadDropdown = 1,
  LoadDropdownSuccess = 2,
  LoadDropdownFail = 3,
  LoadBulkDropdown = 4,
  LoadBulkDropdownSuccess = 5,
  LoadBulkDropdownFail = 6
}

export enum ObjectActionTypes {
  LoadObjectList = 0,
  LoadObjectListSuccess = 1,
  LoadObjectListFail = 2,
  LoadObject = 3,
  LoadObjectSuccess = 4,
  LoadObjectFail = 5,
  CreateOrUpdateObject = 6,
  CreateObjectSuccess = 7,
  UpdateObjectSuccess = 8,
  CreateOrUpdateObjectFail = 9,
  DeleteObject = 10,
  DeleteObjectSuccess = 11,
  DeleteObjectFail = 12,
  LoadObjectHistory = 13,
  LoadObjectHistorySuccess = 14,
  LoadObjectHistoryFail = 15,
  ClearObjectError = 16,
  UploadFile = 17,
  UploadFileSuccess = 18,
  UploadFileFail = 19,
  RemoveFile = 20,
  RemoveFileSuccess = 21,
  RemoveFileFail = 22,
  SwapCurrentWithRefreshed = 23,
  UpdateObjectList = 24,
  UpdateObjectListSuccess = 25,
  UpdateObjectListFail = 26,
  ClearState = 27,
  RemoveMultipleFiles = 28,
  RemoveMultipleFilesSuccess = 29,
  RemoveMultipleFilesFail = 30,
  MoveMultipleFiles = 31,
  MoveMultipleFilesSuccess = 32,
  MoveMultipleFilesFail = 33,
  GenerateZipFileLink = 34,
  GenerateZipFileLinkSuccess = 35,
  GenerateZipFileLinkFail = 36,
  UpdatePartialObject = 37,
  UpdatePartialObjectSuccess = 38,
  UpdatePartialObjectFail = 39,
  SwapCurrentListWithRefreshed = 40,
  DeleteMultipleObjects = 41,
  DeleteMultipleObjectsSuccess = 42,
  DeleteMultipleObjectsFail = 43
}
