import { Injectable } from '@angular/core';
import { ApiCallContext } from '@safarilaw-webapp/shared/common-objects-models';
import { ApiDropdownAdapter } from '@safarilaw-webapp/shared/crud';
import { DropdownType } from '../../enums';
import { DropdownSectionAdapter } from '../dropddown-section/adapter/dropdown-section.adapter';
import { DropdownMatterFieldAdapter } from '../dropdown-matter-field/adapters/dropdown-matter-field.adapter';
import { DropdownMatterSubtypeAdapter } from '../dropdown-matter-subtype/adapters/dropdown-matter-subtype.adapter';
import { DropdownStateAdapter } from '../dropdown-state/adapter/dropdown-state-adapter';
import { DropdownSubjectType as DropdownSubjectTypeApi } from '../dropdown-subject-types/models/api/dropdown-subject-type';
import { DropdownSubjectType } from '../dropdown-subject-types/models/app/dropdown-subject-type';

@Injectable({
  providedIn: 'root'
})
export class DropdownApiAdapter extends ApiDropdownAdapter {
  private _dropdownStateAdapter: DropdownStateAdapter;
  private _dropdownMatterSubtypeAdapter: DropdownMatterSubtypeAdapter;
  private _dropdownMatterFieldAdapter: DropdownMatterFieldAdapter;
  private _dropdownSectionAdapter: DropdownSectionAdapter;

  constructor() {
    super();
    this._dropdownStateAdapter = this.inject(DropdownStateAdapter);
    this._dropdownMatterSubtypeAdapter = this.inject(DropdownMatterSubtypeAdapter);
    this._dropdownMatterFieldAdapter = this.inject(DropdownMatterFieldAdapter);
    this._dropdownSectionAdapter = this.inject(DropdownSectionAdapter);
  }
  fromListModel(model: any, context: ApiCallContext) {
    const url = context.__url;
    if (url.endsWith('/' + DropdownType.SubjectTypes.toString())) {
      const extendedModel = model as DropdownSubjectTypeApi;
      let mappedModel: Required<DropdownSubjectType> = null;
      mappedModel = {
        ...super.fromListModel(extendedModel),
        description: extendedModel.description
      };
      return mappedModel;
    } else if (url.endsWith('/' + DropdownType.MatterSubtypes.toString())) {
      return this._dropdownMatterSubtypeAdapter.fromListModel(model);
    } else if (url.endsWith('/' + DropdownType.States.toString())) {
      return this._dropdownStateAdapter.fromListModel(model);
    } else if (url.endsWith('/' + DropdownType.FormFields.toString())) {
      return this._dropdownMatterFieldAdapter.fromListModel(model);
    } else if (url.endsWith('/' + DropdownType.IntakeFormSection.toString())) {
      return this._dropdownSectionAdapter.fromListModel(model);
    } else if (url.endsWith('/' + DropdownType.LegalAuthorityTypes.toString())) {
      return {
        // quick "hack" but these two should have their own models
        description: model.description,
        ...this._dropdownStateAdapter.fromListModel(model)
      };
    } else if (url.endsWith('/' + DropdownType.ResponseDocumentTypes.toString())) {
      return {
        description: model.description,
        ...this._dropdownStateAdapter.fromListModel(model)
      };
    }

    return super.fromListModel(model);
  }
}
