import { Injectable } from '@angular/core';
import { ApiDataAdapter, SafariRequired } from '@safarilaw-webapp/shared/crud';
import { OrganizationModelPagedResponseModel, SearchOrganizationsModel as SearchOrganizationsModelApi } from '../../_rms-api-models.generated';
import { OrganizationSearch, OrganizationSearchRequest, OrganizationSearchResponse } from '../models/app/organization-search';

@Injectable({
  providedIn: 'root'
})
export class OrganizationSearchAdapter extends ApiDataAdapter<OrganizationSearch> {
  constructor() {
    super();
  }

  toCreateModel(o: OrganizationSearchRequest): SafariRequired<SearchOrganizationsModelApi> {
    return {
      query: o.query,
      top: o.top,
      skip: o.skip,
      orderBy: o.orderBy
    };
  }

  fromGetModel(list: OrganizationModelPagedResponseModel): SafariRequired<OrganizationSearchResponse> {
    const response: OrganizationSearchResponse = {
      id: null,
      items: []
    };
    list.items.forEach(org => {
      response.items.push({
        id: org.name,
        name: org.name,
        isActive: true,
        order: 0
      });
    });
    return response;
  }
}
