export const reduxObjectInitState = {
  list: {
    loading: [],
    loaded: [],
    saving: [],
    saved: [],
    deleting: [],
    deleted: [],

    current: [],

    failedToLoad: [],
    failedToSave: [],
    failedToDelete: [],
    failedToRefresh: []
  },
  object: {
    loading: [],
    loaded: [],
    saving: [],
    saved: [],
    deleting: [],
    deleted: [],

    current: [],

    failedToLoad: [],
    failedToSave: [],
    failedToDelete: [],
    failedToRefresh: []
  },
  auditHistory: {
    loading: [],
    loaded: [],
    current: [],
    failedToLoad: []
  }
};
