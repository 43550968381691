import { IAddress } from '@safarilaw-webapp/shared/common-objects-models';
import { Country } from '../../../enums';
import { AddressModel } from '../../../_comanage-api-models.generated';

export class Address implements IAddress, Required<AddressModel> {
  street1 = null;
  street2 = null;
  city = null;
  state: number = null;
  zipCode = null;
  country = Country.UnitedStates;
}
