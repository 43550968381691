<ng-container *ngIf="shouldShowSwWarning">
    <h4 class="text-danger text-center">
      Warning. It looks like your service worker may be disabled. Did you use CTRL+F5 or have bypass for url checked ? 
    </h4>
  </ng-container>
  <block-ui >
  
  <ng-container *ngIf="!siteUnderMaintenance && !showSplash"> 
    <ng-container *ngIf="shouldShowBanner()">
      <div class="alert alert-warning alert-dismissible fade show text-center mb-0" role="alert">
        <strong>Unsupported browser detected!</strong> To ensure the full functionality of ServePort, please use a current version of <a href="https://www.google.com/chrome/">Chrome</a>, <a href="https://www.mozilla.org/en-US/firefox/new/">FireFox</a>, or <a href="https://www.microsoft.com/en-us/edge">Edge</a>.
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </ng-container>
    <div class="mainClass">
      <div class="wrapper" >
        <sl-bootstrap-navbar *ngIf="canShowNavbar"></sl-bootstrap-navbar>
        <main>
          <sl-rms-maintenance-header *ngIf="siteScheduledMaintenance" [maintenanceInfo]="maintenanceInfo"></sl-rms-maintenance-header>
          <sl-bootstrap-header *ngIf="canShowHeader"></sl-bootstrap-header>
          <div [ngClass]="containerClass">
            @if (showDivider) {
              <aside id="s_act-primary" class="s_act p-0" style="width: 100%;border-bottom: none;">
                <div class="row no-gutters">
                  <div  class="col"></div>
                </div>
              </aside>
            }
            <router-outlet ></router-outlet>
          </div>
        </main>
      </div>
    </div>

  </ng-container>
  
  <ng-container *ngIf="siteUnderMaintenance && !showSplash">
 
    <sl-rms-maintenance-splash [maintenanceInfo]="maintenanceInfo"></sl-rms-maintenance-splash>
  </ng-container>
  
  <ng-container *ngIf=" showSplash">
 
   <sl-rms-update-splash></sl-rms-update-splash> 
  </ng-container>
  
  </block-ui>