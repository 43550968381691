export enum ErrorType {
  Conflict,
  Navigation,
  Validation,
  NotFound,
  Firewall
}
export class ErrorObject {
  uuid: any;
  details: any[];
  errorType: ErrorType;

  safariApiError: boolean;
  message: string;
  jsStack: string;
  developerException: any;
  pageContext?: any;
  maxValidationErrors?: number;
  originalError: any; // Most likely httperrorresponse or string, but really could be anything JS wants it to be
}
