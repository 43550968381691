import { createSelector, DefaultProjectorFn, MemoizedSelector, Selector } from '@ngrx/store';
import { IdName, SafariObject, SafariObjectId } from '@safarilaw-webapp/shared/common-objects-models';
import { IDropdownState } from '../interface';

export const createGetDropdownListSelector =
  (dropdownSelector: Selector<object, IDropdownState>, dropdownType: { [key: string]: SafariObjectId; [value: number]: SafariObjectId }) => (id: SafariObjectId) =>
    createSelector(dropdownSelector, state => state[SafariObject.id(id).toLowerCase()]);

export const createGetDropdownStateSelector = (dropdownState: Selector<object, IDropdownState>, dropdownType: { [key: string]: SafariObjectId; [value: number]: SafariObjectId }) =>
  new DefaultDropdownSelectorConverter({
    dropdownState: createGetDropdownListSelector(dropdownState, dropdownType)
  });
export class DefaultDropdownSelectorConverter {
  constructor(
    private _selectors: {
      dropdownState: (id: SafariObjectId) => MemoizedSelector<object, IdName[], DefaultProjectorFn<IdName[]>>;
    }
  ) {}
  get dropdownState() {
    return this._selectors.dropdownState;
  }
}
