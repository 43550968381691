/* eslint-disable no-restricted-syntax -- framework use*/
import { InjectionToken, inject } from '@angular/core';

import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EffectWithIdentifier, GenericDropdownEffects } from '@safarilaw-webapp/shared/redux';

import { MyAuth } from '../auth/models/my-auth';
import { DeliveryPortal } from '../delivery-portal/models/app/delivery-portal';
import { IntakePortalForm } from '../intake-portal-form/models/app/intake-portal-form';
import { IntakePortal } from '../intake-portal/models/app/intake-portal';
import { MyIntakePortal } from '../my-intake-portal/models/app/my-intake-portal';
import { OrganizationSearch } from '../ogranization-search/models/app/organization-search';
import { Profile } from '../profile/models/app/profile';
import { Submission } from '../submission/models/app/submission';
import {
  ACKNOWLEDGE_INSTRUCTIONS_REDUX_OBJECT_TOKEN,
  CONFIRM_RECEIPT_REDUX_OBJECT_TOKEN,
  DELIVERY_PORTAL_REDUX_OBJECT_TOKEN,
  DROPDOWN_REDUX_OBJECT_TOKEN,
  GENERATE_SUBMISSION_TEMPLINK_REDUX_OBJECT_TOKEN,
  GENERATE_TEMPLINK_REDUX_OBJECT_TOKEN,
  GENERATE_TEMPZIP_REDUX_OBJECT_TOKEN,
  INTAKE_PORTAL_FORM_REDUX_OBJECT_TOKEN,
  INTAKE_PORTAL_REDUX_OBJECT_TOKEN,
  INTAKE_PORTAL_URL_REDUX_OBJECT_TOKEN,
  MY_AUTH_REDUX_OBJECT_TOKEN,
  MY_INTAKE_PORTAL_REDUX_OBJECT_TOKEN,
  MY_PROFILE_REDUX_OBJECT_TOKEN,
  ORGANIZATION_SEARCH_REDUX_OBJECT_TOKEN,
  SUBMISSION_BATCH_ATTACHMENT_LINK_ATTACHMENT_LINK_REDUX_OBJECT_TOKEN,
  SUBMISSION_BATCH_REDUX_OBJECT_TOKEN,
  SUBMISSION_REDUX_OBJECT_TOKEN
} from './injectable-object-tokens';
import { IRmsStateDropdown } from './state.interface';
import { AttachmentLink } from '@safarilaw-webapp/shared/common-objects-models';
import { SubmissionBatch } from '../submission-batch/models/app/submission-batch';

export const getEffectTokens = () => [
  new InjectionToken<EffectWithIdentifier<DeliveryPortal>>('DELIVERY_PORTAL_REDUX_EFFECT_TOKEN', {
    providedIn: 'root',
    factory: () =>
      new EffectWithIdentifier<DeliveryPortal>(inject(Store), inject(Actions), inject(DELIVERY_PORTAL_REDUX_OBJECT_TOKEN), [
        inject(ACKNOWLEDGE_INSTRUCTIONS_REDUX_OBJECT_TOKEN),
        inject(CONFIRM_RECEIPT_REDUX_OBJECT_TOKEN)
      ])
  }),
  new InjectionToken<EffectWithIdentifier<MyAuth>>('MY_AUTH_REDUX_EFFECT_TOKEN', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<MyAuth>(inject(Store), inject(Actions), inject(MY_AUTH_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<AttachmentLink>>('GENERATE_TEMPLINK_LINK_REDUX_EFFECT_TOKEN', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<AttachmentLink>(inject(Store), inject(Actions), inject(GENERATE_TEMPLINK_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<AttachmentLink>>('GENERATE_SUBMISSION_TEMPLINK_LINK_REDUX_EFFECT_TOKEN', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<AttachmentLink>(inject(Store), inject(Actions), inject(GENERATE_SUBMISSION_TEMPLINK_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<AttachmentLink>>('GENERATE_TEMPZIP_LINK_REDUX_EFFECT_TOKEN', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<AttachmentLink>(inject(Store), inject(Actions), inject(GENERATE_TEMPZIP_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<IntakePortal>>('INTAKE_PORTAL_REDUX_EFFECT_TOKEN', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<IntakePortal>(inject(Store), inject(Actions), inject(INTAKE_PORTAL_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<IntakePortal>>('INTAKE_PORTAL_URL_REDUX_EFFECT_TOKEN', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<IntakePortal>(inject(Store), inject(Actions), inject(INTAKE_PORTAL_URL_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<IntakePortalForm>>('INTAKE_PORTAL_FORM_REDUX_EFFECT_TOKEN', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<IntakePortalForm>(inject(Store), inject(Actions), inject(INTAKE_PORTAL_FORM_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<Submission>>('SUBMISSION_REDUX_EFFECT_TOKEN', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<Submission>(inject(Store), inject(Actions), inject(SUBMISSION_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<OrganizationSearch>>('ORGANIZATION_SEARCH_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<OrganizationSearch>(inject(Store), inject(Actions), inject(ORGANIZATION_SEARCH_REDUX_OBJECT_TOKEN))
  }),

  new InjectionToken<GenericDropdownEffects<IRmsStateDropdown>>('DROPDOWN_REDUX_EFFECT_TOKEN', {
    providedIn: 'root',
    factory: () => new GenericDropdownEffects<IRmsStateDropdown>(inject(Actions), inject(Store), inject(DROPDOWN_REDUX_OBJECT_TOKEN), [], null)
  }),

  new InjectionToken<EffectWithIdentifier<Profile>>('MY_PROFILE_REDUX_EFFECT_TOKEN', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<Profile>(inject(Store), inject(Actions), inject(MY_PROFILE_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<MyIntakePortal>>('MY_PROFILE_REDUX_EFFECT_TOKEN', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<MyIntakePortal>(inject(Store), inject(Actions), inject(MY_INTAKE_PORTAL_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<SubmissionBatch>>('SUBMISSION_BATCH_REDUX_EFFECT_TOKEN', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<SubmissionBatch>(inject(Store), inject(Actions), inject(SUBMISSION_BATCH_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<AttachmentLink>>('SUBMISSION_BATCH_ATTACHMENT_LINK_ATTACHMENT_LINK_REDUX_EFFECT_TOKEN', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<AttachmentLink>(inject(Store), inject(Actions), inject(SUBMISSION_BATCH_ATTACHMENT_LINK_ATTACHMENT_LINK_REDUX_OBJECT_TOKEN))
  })
  /*** BEWARE OF DOG - DO NOT DELETE OR PUT ANYTHING ELSE BELOW THIS ***/
];
