<div class="mb-0 alert">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Inactivity Logout Alert</h4>
  </div>
  <div class="modal-body">
    <p>You will be logged out soon due to inactivity. Click "Continue" to remain logged in.</p>
  </div>
  <div class="modal-footer justify-content-center">
    <button type="button" class="btn btn-primary" (click)="dismissModal()">Continue</button>
    <button type="button" class="btn btn-outline-secondary" (click)="logout()">Log Out Now</button>
  </div>
</div>