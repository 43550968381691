import { Injectable } from '@angular/core';
import { SafariObjectId } from '@safarilaw-webapp/shared/common-objects-models';
import { PageUiAction, PageUiState, SafariReduxPageUiObject, defaultPageUiState } from '@safarilaw-webapp/shared/redux';
import * as redux from '../../redux/index';

export class IRmsIntakePageUiState extends PageUiState {
  removeFileRequest: { id: SafariObjectId };
  previewLocalFileRequest: { id: SafariObjectId };
  updateTypeaheadRequest: { id: SafariObjectId; value: string };
  currentIntakeFormId: { id: SafariObjectId };
  intakeAccessRequest: PageUiAction;
  currentIntakePortalLogoUrl: { url: string };
}

const getPageState = redux.createSelector(redux.getSharedFeatureState, state => state.pages.intakePageUiState);
const defaultPortalPageUiState = {
  removeFileRequest: undefined,
  previewLocalFileRequest: undefined,
  intakeAccessRequest: undefined,
  updateTypeaheadRequest: undefined,
  currentIntakeFormId: undefined,

  currentIntakePortalLogoUrl: undefined,
  ...defaultPageUiState
};
@Injectable({ providedIn: 'root' })
export class RmsIntakePageUiReduxObject extends SafariReduxPageUiObject<IRmsIntakePageUiState> {
  // Doing this to prevent the compiler from complaining. We will be getting rid of this default
  // thing but this makes it compile for now
  default = {};

  requestIntakeAccess = super.addMessage('Request Intake Access', state => state.intakeAccessRequest, { intakeAccessRequest: this.fromAction() });

  requestFileRemoval = super.addMessage('Request File Removal', state => state.removeFileRequest, { removeFileRequest: this.fromAction() });

  requestLocalFilePreview = super.addMessage('Request Local File Preview', state => state.previewLocalFileRequest, { previewLocalFileRequest: this.fromAction() });

  requestTypeaheadUpdate = super.addMessage('Request Typeahead Update', state => state.updateTypeaheadRequest, { updateTypeaheadRequest: this.fromAction() });

  updateCurrentIntakeFormId = super.addMessage('Current Intake Form Id', state => state.currentIntakeFormId, { currentIntakeFormId: this.fromAction() });

  constructor() {
    super('Rms', 'Intake', getPageState, defaultPortalPageUiState);
  }
}
