import { InjectionToken, NgModule } from '@angular/core';
import { ActionReducerMap, StoreModule } from '@ngrx/store';

import { IAppControlState } from './state/interfaces/layout-interface';
import { UiReducerService } from './state/state.reducer';

const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<IAppControlState>>('Ui  Reducers');

@NgModule({
  imports: [StoreModule.forFeature('shared', REDUCER_TOKEN)],
  providers: [
    {
      provide: REDUCER_TOKEN,
      deps: [UiReducerService],
      useFactory: (service: UiReducerService) => service.reducers
    }
  ]
})
export class SharedUiKitComponentStateModule {
  constructor() {}
}
