import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppDialogUiReduxObject } from '../../../state/actions/dialog-actions';
import { ConfirmationDialogButton } from '../../models/confirmation-dialog-payload';

@Directive()
export class DialogBaseComponent implements OnInit, OnDestroy {
  protected _closeDialogRequestedBy: ConfirmationDialogButton = null;

  id: string;

  noUiBlock: boolean;
  data: any;
  dialogData: any;

  constructor(
    protected _appDialogUiReduxObject: AppDialogUiReduxObject,
    protected _modalService: BsModalService,
    protected _store: Store<any>,
    protected _actions$: Actions,
    protected _modalRef: BsModalRef = null
  ) {}

  // Child components already call super.ngOnInit() so keeping this here in case we add something
  // in the future.
  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method -- leaving it here for now
  ngOnInit() {}

  closeModal(dialogButton: ConfirmationDialogButton = ConfirmationDialogButton.Auto) {
    this._closeDialogRequestedBy = dialogButton;
    this._modalService.setDismissReason(this.id);

    this._modalRef.hide();
  }

  ngOnDestroy() {
    this._store.dispatch(
      this._appDialogUiReduxObject.default.actions.modalDialogDismissed({
        payload: { buttonType: this._closeDialogRequestedBy, noUiBlock: !!this.noUiBlock, id: this.id, parentData: this.data, dialogData: this.dialogData }
      })
    );
  }
}
