import { ActivatedRouteSnapshot, DetachedRouteHandle, BaseRouteReuseStrategy } from '@angular/router';

export class SafariDefaultReuseStategy extends BaseRouteReuseStrategy {
  handlers: { [key: string]: DetachedRouteHandle } = {};

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return super.shouldDetach(route);
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    super.store(route, handle);
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return super.shouldAttach(route);
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    return super.retrieve(route);
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    if (future.data.shouldReuse != true) {
      return false;
    }
    return super.shouldReuseRoute(future, curr);
  }
}
