// TODO (backend release): These should probably be in their own module called something like AppContextModule

import { User } from '@auth0/auth0-spa-js';

// Ideally state/effects/reducers that deal with these would move to that module as well
export const APP_COMPANY_KEY = 'safari_my_company';
export const APP_USER_KEY = 'safari_my_user';

export class AppContext {
  company: any;
  user: User;
  serverVariables: [{ key: string; value: string }];
}
