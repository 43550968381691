// DO NOT EXPORT THIS FILE IN INDEX.TS.
// THIS IS MEANT TO STAY INTERNAL

export enum ObjectSelectorTypes {
  GetCurrentObject = 0,
  GetCurrentObjectError = 1,
  GetCurrentObjectIsLoading = 2,
  GetObjectsFailed = 3,
  GetObjectsSaving = 4,
  GetNumObjectsFailed = 5,
  GetNumObjectsSaving = 6,
  GetNumObjectsPending = 7,
  GetIsObjectSaveBlocked = 8,
  GetObjectsForList = 9,
  GetObjectsForListIsLoading = 10,
  GetObjectHistory = 11,
  GetObjectsCount = 12,
  GetCurrentObjectIsRefreshing = 13,
  GetRefreshedObject = 14
}
export enum FileOperationSelectorTypes {
  GetFileOperations = 0,
  GetPreviewFile = 1,
  GetPreviewFileClosed = 2,
  GetPreviewFiledEdit = 3
}
export enum SelectorState {
  Current = 1,
  Loaded = 2,
  Failed = 4,
  Loading = 8,
  Saved = 16,
  Saving = 32
}
