export const navbarGroupIdDashboard = 1;
export const navbarGroupIdWorkflow = 2;
export const navbarGroupIdRequests = 3;
export const navbarGroupIdAdmin = 4;

// prettier-ignore
export class NavbarGroup {
  constructor(
    public id: number,
    public title: string,
    public permissionFunction: string[] = null
  ) {}
}
