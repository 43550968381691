import { Injectable } from '@angular/core';
import { ApiDataAdapter } from '@safarilaw-webapp/shared/crud';
import { CompanyRegisteredAgentSettingsModel } from '../../_comanage-api-models.generated';
import { RegisteredAgentSettings } from '../models/app/registered-agent-settings';

@Injectable({
  providedIn: 'root'
})
export class RegisteredAgentSettingsAdapter extends ApiDataAdapter<RegisteredAgentSettings> {
  fromGetModel(o: CompanyRegisteredAgentSettingsModel): Required<RegisteredAgentSettings> {
    const retVal: RegisteredAgentSettings = {
      cscSettings: o.cscSettings
        ? {
            isEnabled: o.cscSettings.isEnabled,
            goLiveDate: o.cscSettings.goLiveDate,
            // username: o.cscSettings.username,
            // password: o.cscSettings.password,
            feedId: o.cscSettings.feedId,
            // apiKey: o.cscSettings.apiKey,
            catchAllMatterSubtypeId: o.cscSettings.matterSubtypeId_CatchAll,
            registeredAgentTypeMaps: o.cscSettings.registeredAgentTypeMaps
          }
        : null,
      ctSettings: o.ctSettings
        ? {
            isEnabled: o.ctSettings.isEnabled,
            goLiveDate: o.ctSettings.goLiveDate,
            // username: o.ctSettings.username,
            // password: o.ctSettings.password,
            channelId: o.ctSettings.channelId,
            catchAllMatterSubtypeId: o.ctSettings.matterSubtypeId_CatchAll,
            registeredAgentTypeMaps: o.ctSettings.registeredAgentTypeMaps
          }
        : null
    } as any as RegisteredAgentSettings;
    return retVal;
  }
}
