import { createAction, props } from '@ngrx/store';
import { ActionErrorBase } from '../../models/object';

export enum ReduxErrorUiOption {
  Dialog,
  ErrorPage
}
export class ReduxError {
  source: string;
  url?: string;
  silent?: boolean;
  mustResolve?: boolean;
  uiOption?: ReduxErrorUiOption;
  maxValidationErrors?: number;
}

export const reduxDebugAction = createAction('[Redux] Redux Debug Action', props<{ message: string }>());

export enum GenericReduxActions {
  ReduxActionFail = '[Shared] Action Error',

  PurgeAllObjectErrors = '[Shared] Purge All Object Errors',
  ObjectNotFoundError = '[Shared] Object Not Found Error',
  ClearObjectSavingCounter = '[Shared] Clear Object Saving Counter',
  ClearAllObjectStates = '[Shared] Clear All Object States',
  DeserializeFromState = '[Shared] Deserialize From State'
}

export const reduxActionFail = createAction(GenericReduxActions.ReduxActionFail, props<ActionErrorBase>());
export const purgeAllObjectErrors = createAction(GenericReduxActions.PurgeAllObjectErrors);
export const objectNotFoundError = createAction(GenericReduxActions.ObjectNotFoundError);
export const clearAllObjectStates = createAction(GenericReduxActions.ClearAllObjectStates);
export const deserializeFromState = createAction(GenericReduxActions.DeserializeFromState);
