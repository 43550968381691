import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CrudOperationType,
  HTTP_STATUS_CODE_API_NOTAUTHORIZED,
  HTTP_STATUS_CODE_API_NOTFOUND,
  HTTP_STATUS_CODE_API_TOOMANY,
  HTTP_STATUS_CODE_API_VALIDATION,
  HTTP_STATUS_CODE_WEB_VALIDATION_BASE
} from '@safarilaw-webapp/shared/common-objects-models';

import cloneDeep from 'lodash-es/cloneDeep';
import { ErrorsToInclude } from '../../enums';
import { FailedObject } from '../../models/failed-object';
enum FailureType {
  Error,
  Cancelled
}
// Temporary const until we upgrade to FileReduxObject
export const FAILED_OBJECT_FILE = 'File';
@Injectable({
  providedIn: 'root'
})
export class FailedObjectsService {
  private _failedObjects = new Map<string, FailedObject[]>();
  private _cancelleddObjects = new Map<string, FailedObject[]>();

  constructor() {}
  private _getObjectMap(failureType: FailureType): Map<string, FailedObject[]> {
    return failureType == FailureType.Error ? this._failedObjects : this._cancelleddObjects;
  }
  private _hasFailedOrCancelledObjects(failureType: FailureType) {
    const objectMap = this._getObjectMap(failureType);
    for (const entry of objectMap.entries()) {
      if (entry[1] != null && entry[1].length > 0) {
        return true;
      }
    }
    return false;
  }
  get hasFailedObjects() {
    return this._hasFailedOrCancelledObjects(FailureType.Error);
  }
  get hasCancelledObjects() {
    return this._hasFailedOrCancelledObjects(FailureType.Cancelled);
  }
  private _getFailedOrCancelledObjectsTypes(failureType: FailureType) {
    const objectMap: Map<string, FailedObject[]> = this._getObjectMap(failureType);
    const arr: string[] = [];
    for (const entry of objectMap.entries()) {
      if (entry[1] != null && entry[1].length > 0) {
        arr.push(entry[0]);
      }
    }
    return arr;
  }
  getFailedObjectsTypes() {
    return this._getFailedOrCancelledObjectsTypes(FailureType.Error);
  }
  getCancelledObjectsTypes() {
    return this._getFailedOrCancelledObjectsTypes(FailureType.Cancelled);
  }
  private _addFailedOrCancelledObject(objectType: string, object: FailedObject, failureType: FailureType) {
    if (object == null) {
      return;
    }
    if (object.operation == CrudOperationType.Delete && object.error instanceof HttpErrorResponse && object.error?.status == HTTP_STATUS_CODE_API_NOTFOUND) {
      // Makes no sense re-adding objects they tried to delete if someone already deleted them
      return;
    }
    if (object.originalContent?.id == null && object.originalContent?.actionId == null) {
      throw new Error('Failed or cancelled object must have id or actionId');
    }
    const objectMap = this._getObjectMap(failureType);
    let arr = objectMap.get(objectType);
    if (arr == null) {
      arr = [];
    }

    arr.push(object);
    objectMap.set(objectType, arr);
  }
  addFailedObject(objectType: string, object: FailedObject) {
    this._addFailedOrCancelledObject(objectType, object, FailureType.Error);
  }
  addCancelledObject(objectType: string, object: FailedObject) {
    this._addFailedOrCancelledObject(objectType, object, FailureType.Cancelled);
  }
  private _removeFailedOrCancelledObjectsById(objectType: string, ids: string | string[], failureType: FailureType) {
    const objectMap = this._getObjectMap(failureType);
    const arr = objectMap.get(objectType);
    if (arr == null) {
      return;
    }
    if (Array.isArray(ids)) {
      for (const id of ids) {
        this._removeFailedOrCancelledObjectById(arr, objectType, id, failureType);
      }
    } else {
      this._removeFailedOrCancelledObjectById(arr, objectType, ids, failureType);
    }
  }
  removeFailedObjectsById(objectType: string, ids: string | string[]) {
    this._removeFailedOrCancelledObjectsById(objectType, ids, FailureType.Error);
  }
  removeCancelledObjectsById(objectType: string, ids: string | string[]) {
    this._removeFailedOrCancelledObjectsById(objectType, ids, FailureType.Cancelled);
  }
  private _removeFailedOrCancelledObjectById(arr: FailedObject[], objectType: string, id: string, failureType: FailureType) {
    const objectMap = this._getObjectMap(failureType);
    arr = objectMap.get(objectType);
    arr = arr.filter(o => o.originalContent.id != id && o.originalContent.actionId != id);
    objectMap.set(objectType, arr);
  }
  private _removeFailedOrCancelledObjectsByType(objectType: string, operation: CrudOperationType = null, failureType: FailureType) {
    const objectMap = this._getObjectMap(failureType);
    if (operation == null) {
      objectMap.set(objectType, null);
    } else {
      let arr = objectMap.get(objectType);
      if (arr == null) {
        return;
      }
      arr = arr.filter(o => o.operation != operation);
      objectMap.set(objectType, arr);
    }
  }
  removeFailedObjectsByType(objectType: string, operation: CrudOperationType = null) {
    this._removeFailedOrCancelledObjectsByType(objectType, operation, FailureType.Error);
  }
  removeCancelledObjectsByType(objectType: string, operation: CrudOperationType = null) {
    this._removeFailedOrCancelledObjectsByType(objectType, operation, FailureType.Cancelled);
  }
  private _removeAllFailedOrCancelledObjects(failureType: FailureType) {
    if (failureType == FailureType.Error) {
      this._failedObjects = new Map<string, FailedObject[]>();
    } else {
      this._cancelleddObjects = new Map<string, FailedObject[]>();
    }
  }
  removeAllFailedObjects() {
    this._removeAllFailedOrCancelledObjects(FailureType.Error);
  }
  removeAllCancelledObjects() {
    this._removeAllFailedOrCancelledObjects(FailureType.Cancelled);
  }
  removeAllFailedOrCancelledObjects() {
    this.removeAllFailedObjects();
    this.removeAllCancelledObjects();
  }
  getCancelledObjectsByType(objectType: string, operation: CrudOperationType = null): FailedObject[] {
    let arr = this._cancelleddObjects.get(objectType);
    if (arr == null) {
      return [];
    }

    arr = arr.filter(o => operation == null || o.operation == operation);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call -- cloneDeep
    return cloneDeep(arr) as FailedObject[];
  }
  getFailedObjectsByType(
    objectType: string,
    operation: CrudOperationType = null,
    errorsToInclude: ErrorsToInclude = ErrorsToInclude.Permission | ErrorsToInclude.Validation | ErrorsToInclude.Other
  ): FailedObject[] {
    let arr = this._failedObjects.get(objectType);
    if (arr == null) {
      return [];
    }

    arr = arr.filter(o => operation == null || o.operation == operation);

    if (!(errorsToInclude & ErrorsToInclude.Deleted)) {
      // Don't ever return 404s from here. This function is used to rebuild the list of files to add/remove etc,
      // and no sense returning 404ed objects since they will never succeeed retries
      // This should be default in objects that are trying to reconstruct back
      arr = arr.filter(o => !(o.error instanceof HttpErrorResponse) || o.error.status != HTTP_STATUS_CODE_API_NOTFOUND);
    }
    if (!(errorsToInclude & ErrorsToInclude.Permission)) {
      // For messaging reasons the parent may request to see if there are any 404s so
      // that it can present a different message
      arr = arr.filter(o => !(o.error instanceof HttpErrorResponse) || o.error.status != HTTP_STATUS_CODE_API_NOTAUTHORIZED);
    }
    if (!(errorsToInclude & ErrorsToInclude.Validation)) {
      // For messaging reasons the parent may request to see if there are any 404s so
      // that it can present a different message
      arr = arr.filter(o => !(o.error instanceof HttpErrorResponse) || (o.error.status != HTTP_STATUS_CODE_API_VALIDATION && o.error.status < 90000 && o.error.status != HTTP_STATUS_CODE_API_TOOMANY));
    }
    if (!(errorsToInclude & ErrorsToInclude.Other)) {
      // For messaging reasons the parent may request to see if there are any 404s so
      // that it can present a different message
      arr = arr.filter(
        o =>
          !(o.error instanceof HttpErrorResponse) ||
          o.error.status == HTTP_STATUS_CODE_API_VALIDATION ||
          o.error.status > HTTP_STATUS_CODE_WEB_VALIDATION_BASE ||
          o.error.status == HTTP_STATUS_CODE_API_TOOMANY ||
          o.error.status == HTTP_STATUS_CODE_API_NOTAUTHORIZED ||
          o.error.status == HTTP_STATUS_CODE_API_NOTFOUND
      );
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call -- cloneDeep
    return cloneDeep(arr) as FailedObject[];
  }
}
