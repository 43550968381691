// Based on https://github.com/angular/angular/blob/main/aio/content/examples/service-worker-getting-started/src/app/handle-unrecoverable-state.service.ts

import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

const notifyUser = (message: string) => {
  alert(message);
};

// #docregion sw-unrecoverable-state
@Injectable({
  providedIn: 'root'
})
export class SwHandleUnrecoverableStateService {
  constructor(updates: SwUpdate) {
    // NG examples (from which this was taken) don't check for isEnabled. That still works fine but
    // causes annoying network errors (404 on ngsw-json) in dev mode in console
    if (!updates.isEnabled) {
      return;
    }
    updates.unrecoverable.subscribe(event => {
      notifyUser('An error occurred that we cannot recover from:\n' + event.reason + '\n\nPlease reload the page.');
    });
  }
}
// #enddocregion sw-unrecoverable-state
