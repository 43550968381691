import { Injectable } from '@angular/core';
import { ApiDataAdapter, SafariRequired } from '@safarilaw-webapp/shared/crud';
import { UserCompanyModel } from '../../_comanage-api-models.generated';
import { MyCompanies } from '../models/app/my-companies';

@Injectable({
  providedIn: 'root'
})
export class MyCompaniesAdapter extends ApiDataAdapter<MyCompanies> {
  fromListModel(model: UserCompanyModel): SafariRequired<MyCompanies> {
    return {
      domainName: model.domainName,
      fullName: model.fullName,
      id: this.helpers.fromApiId(model.id),
      isActive: model.isActive,
      lastLoginDate: this.helpers.fromApiDate(model.lastLoginDate),
      name: model.name
    };
  }
}
