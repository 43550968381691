/* eslint-disable @typescript-eslint/no-unsafe-argument -- framework level */
/* eslint-disable @typescript-eslint/no-unsafe-assignment -- framwork level */
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {
  ApiCallContext,
  AutoRetrieveOnUpdate,
  cancelAllFiles,
  CrudOperationType,
  CrudServiceParams,
  FileObject,
  FileOperationInfo,
  FileOperationType,
  ICrudLogger,
  ObjectHistory,
  ObjectId,
  SafariObject,
  SafariObjectId,
  SafariReduxFileTransferObjectDefinition,
  TransferDialogOptions
} from '@safarilaw-webapp/shared/common-objects-models';
import * as deepDiff from 'deep-diff';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';

import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { FileTransferError } from '@safarilaw-webapp/shared/error-handling-message-parser';
import { FAILED_OBJECT_FILE, FailedFile, FailedObjectsService } from '@safarilaw-webapp/shared/failed-objects';

import { childError } from '@safarilaw-webapp/shared/utils';
import { FileTransferService } from '../../../file/services/file-transfer/file-transfer.service';
import { ApiDataAdapter } from '../../adapter/adapter';
import { Collection, CrudServiceBase } from '../crud-orchestrator/crud-base.service';

export enum ResponseType {
  HttpReponse,
  RawBlob,
  ParsedBlob
}
export class EndpointOptions {
  responseType?: ResponseType;
}
export class EndpointOverrides {
  put?: string;
  delete?: string;
  get?: string;
  getOptions?: EndpointOptions;
  create?: string;
}
export class ServiceConfiguration {
  constructor(
    public apiRoot: string,
    public apiSuffix: string,
    public updateWhiteList: string[] = [],
    public endpointOverrides: EndpointOverrides = null,
    public otherEndpoints: Record<string, string> = null
  ) {}
}
export class CrudServiceCallOptions {
  actionId: SafariObjectId;
  transferDialogOptions?: TransferDialogOptions;
  autoRetrieveOnUpdate?: AutoRetrieveOnUpdate;
  whitelistedMergePaths?: string[];
}
/**
 * This represents crud service that operates on safariobjects (meaning it maps things like IDs, calls adapters, etc)
 * However - some of this logic is currently in crud.base.sevice so that should eventually be moved up here
 */
export class CrudService<T extends SafariObject> extends CrudServiceBase<T> {
  public otherEndpoints: Record<string, string> = null;
  private _endpointOverrides: EndpointOverrides = null;
  private _apiRoot: string = null;
  private _apiSuffix: string = null;
  private _updateWhiteList: string[] = [];
  private _fileTransferService: FileTransferService = null;
  private _store: Store<any>;
  private _actions: Actions<any>;
  private _failedObjectsService: FailedObjectsService;
  private _computedEndPoint: string;
  constructor(
    protected _adapter: ApiDataAdapter<T>,
    protected crudServiceLogger: ICrudLogger,
    serviceConfig: ServiceConfiguration = null,
    protected safariReduxFileTransferObject: SafariReduxFileTransferObjectDefinition = null
  ) {
    super();
    this._apiSuffix = serviceConfig?.apiSuffix || null;
    this._apiRoot = serviceConfig?.apiRoot || null;
    this._updateWhiteList = serviceConfig?.updateWhiteList || [];
    this._endpointOverrides = serviceConfig?.endpointOverrides || null;
    this.otherEndpoints = serviceConfig?.otherEndpoints;
    if (safariReduxFileTransferObject) {
      this._fileTransferService = this.inject(FileTransferService);
      this._store = this.inject(Store);
      this._actions = this.inject(Actions);
      this._failedObjectsService = this.inject(FailedObjectsService);
    }
  }
  get adapter() {
    return this._adapter;
  }

  protected get updateWhiteList(): string[] {
    return this._updateWhiteList;
  }
  protected set updateWhiteList(value: string[]) {
    this._updateWhiteList = value;
  }
  protected get apiRoot(): string {
    return this._apiRoot;
  }
  protected get apiSuffix(): string {
    return this._apiSuffix;
  }

  get endpoint() {
    return this._computedEndPoint || (this._computedEndPoint = `${this.apiRoot}${this.apiSuffix}`);
  }
  retrieveHistory(id: SafariObjectId): Observable<ObjectHistory> {
    const params = {
      orderBy: null,
      skip: null,
      top: 5000
    } as CrudServiceParams;
    const queryString = this.getQueryStringFromParams(params);
    return this._getHttpClient()
      .get<ObjectHistory>(`${this.endpoint}/${ObjectId(id)}/history?${queryString}`, {
        headers: this.getHeaders(),
        observe: 'response',
        reportProgress: false,
        responseType: 'json'
      })
      .pipe(
        map(o => {
          const obj = o.body;
          obj['__etag'] = o.headers.get('ETag');
          return obj;
        })
      );
  }
  // This will be removed eventually
  private _checkForParentIds(object: T, endpoint: string, useObjectId = true) {
    const id = object.id;
    // Backward compatibility - will be removed eventually
    // eslint-disable-next-line deprecation/deprecation -- needed for backward compatibility for now
    let parentIds: string[] = Object.prototype.hasOwnProperty.call(object, '__parentIds') ? object.__parentIds : [];
    if (SafariObject.idIsCompound(id)) {
      parentIds = [];
    }

    return SafariObject.formatEndpoint(SafariObject.id(parentIds, SafariObject.idToArray(id)), endpoint, useObjectId);
  }
  protected _getCreateEndpoint(object: T) {
    return this._checkForParentIds(object, this._getEndpoint(this._endpointOverrides?.create), false);
  }
  create(object: T & { id?: string; __parentIds?: any }, options: CrudServiceCallOptions = null, context: ApiCallContext = null): Observable<T> {
    if (this._fileTransferService) {
      const fileObject = object as unknown as FileObject;
      const displayFilename = options?.transferDialogOptions?.displayName;
      const abort$ = this.getUploadAbortPipe(options?.actionId, displayFilename, object as any, options?.transferDialogOptions);
      return this._fileTransferService
        .upload(
          this._getCreateEndpoint(object),
          fileObject.file,
          fileObject.id.toString(),
          options?.transferDialogOptions?.displayName,

          this.safariReduxFileTransferObject,
          null,

          options?.actionId.toString(),
          {
            etag: fileObject.__etag,
            formValues: fileObject.formValues,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- it's really "any", not a hack... We don't know what it could possibly be this low
            additionalInfo: fileObject.additionalInfo
          },
          fileObject.shouldUseTus,
          options?.transferDialogOptions.originalContent
        )
        .pipe(
          // upload method from fileTransferService will be returning a bunch of events, so we need to filter only
          // on the final return (HttpResponse) before we return to the caller, otherwise
          // multiple Success events will be issued.
          filter(x => x instanceof HttpResponse),
          map(() => ({ ...object })),
          takeUntil(abort$)
        );
    } else {
      return this._post(this._getCreateEndpoint(object), object, this.adapter, context) as Observable<T>;
    }
  }
  private _getEndpoint(endpointOverride: string) {
    return endpointOverride ? this.apiRoot + endpointOverride : this.endpoint;
  }
  protected _getRetrieveEndpoint(id: SafariObjectId) {
    return SafariObject.formatEndpoint(id, this._getEndpoint(this._endpointOverrides?.get));
  }

  protected _getRetrieveAllEndpoint(parentIds: string[] = null) {
    if (parentIds == null || parentIds.length === 0) {
      return `${this.endpoint}`;
    }
    return SafariObject.formatEndpoint(SafariObject.id(parentIds, SafariObject.NOID), this._getEndpoint(this._endpointOverrides?.get));
  }
  protected _getUpdateEndpoint(object: T) {
    return this._checkForParentIds(object, this._getEndpoint(this._endpointOverrides?.put));
  }
  protected _getUpdatePartialEndpoint(object: Partial<T>) {
    return this._checkForParentIds(object as T, this._getEndpoint(this._endpointOverrides?.put));
  }
  retrieve(id: SafariObjectId, payload: T = null, context: ApiCallContext = null): Observable<T | File | HttpResponse<Blob>> {
    if (this._endpointOverrides?.getOptions?.responseType == ResponseType.RawBlob) {
      return this._retrieveRawBlobResponse(this._getRetrieveEndpoint(id));
    } else if (this._endpointOverrides?.getOptions?.responseType == ResponseType.ParsedBlob) {
      return this._retrieveParsedBlobResponse(this._getRetrieveEndpoint(id));
    }
    return this._retrieveJsonResponse(this._getRetrieveEndpoint(id), payload, this.adapter, id, context) as Observable<T>;
  }
  retrieveAll(params: CrudServiceParams = null, parentIds: string[] = [], context: ApiCallContext = null): Observable<Collection<T>> {
    const queryString = this.getQueryStringFromParams(params);
    return this._retrieveAll(this._getRetrieveAllEndpoint(parentIds) + (queryString.length > 0 ? '?' + queryString : ''), this.adapter, parentIds, context);
  }
  updateAll(originalList: T[], updatedList: T[], params: CrudServiceParams = null, parentIds: string[] = [], context: ApiCallContext = null): Observable<Collection<T>> {
    return this._updateAll(this._getRetrieveAllEndpoint(parentIds), originalList, updatedList, this.adapter, parentIds, context, params);
  }
  handleUpdateError(object: T & { id?: SafariObjectId; __parentIds?: string[] }, error: HttpErrorResponse, retryEndpoint: string, additionalWhitelist: string[]) {
    const dynamicWhiteList = additionalWhitelist == null ? [] : additionalWhitelist;

    const fullWhiteList = [...this.updateWhiteList, ...dynamicWhiteList];
    if (error.status === 409 && fullWhiteList.length > 0) {
      return this._retrieveJsonResponse(retryEndpoint, null, null).pipe(
        mergeMap(objectFromDb => {
          // extract '__etag' that was automatically applied via retrieve
          // objectFromDbCurrent will contain the original object without '__etag'.
          // Also get rid of __base that comes back from retrieve endpoint. We don't want to
          // compare those
          // eslint-disable-next-line no-unused-vars -- destructuring
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call -- generic type
          const { __etag, __base, ...objectFromDbCurrent } = objectFromDb;
          // get original object (before user edited anything)
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call -- generic type
          const objectFromDbOriginal = object['__base'];
          // shallow copy object that we're trying to save so we can modify etag
          // (otherwise it would be readonly since it ultimately comes from the store)
          const objectToSave = { ...object };
          const diffs = deepDiff.diff(objectFromDbOriginal, objectFromDbCurrent);

          // if we are in conflict but both cached (__base) and current object from DB
          // are identical then this is most likely due to some integration event or
          // a child update that only updated etag of the object and nothing else.
          // In that case we can skip the logic below and go straight to updating etag
          // with the latest etag from the DB
          if (diffs != null) {
            // Check all the changes between the original object and the one that's in the DB
            // Any change that is not whitelisted is caused 'read only' and wil cause the error
            // to bubble and show "Another user has changed..."
            for (const diff of diffs) {
              if (diff.path == null) {
                return throwError(() => ({ ...error, ...{ conflictPath: null } }));
              }
              const fullPath = diff.path.join('.');

              if (!fullWhiteList.includes(fullPath)) {
                const failedMergeMessage = 'Auto-merging not attempted. Path not in whitelist path: ' + fullPath;
                // eslint-disable-next-line no-console -- We want to keep this console.log
                console.log(failedMergeMessage);
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, no-console -- deepdiff
                console.log('DB value: ', objectFromDbOriginal[fullPath]);
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, no-console -- deepdiff
                console.log('app value: ', objectFromDbCurrent[fullPath]);

                if (this.crudServiceLogger) {
                  this.crudServiceLogger.logAutoMergeEvent(object.id, failedMergeMessage);
                }
                // change not whitelisted - bail out
                return throwError(() => ({ ...error, ...{ conflictPath: fullPath } }));
              } else {
                const autoMergeMessage = 'Auto-merging due to save conflict in ' + fullPath;
                // eslint-disable-next-line no-console -- We want to keep this console.log
                console.log(autoMergeMessage);
                if (this.crudServiceLogger) {
                  this.crudServiceLogger.logAutoMergeEvent(object.id, autoMergeMessage);
                }
              }
            }
          } else {
            const autoMergeMessage = 'Merging due to etag diff';
            // eslint-disable-next-line no-console -- We want to keep this console.log
            console.log(autoMergeMessage);
            if (this.crudServiceLogger) {
              this.crudServiceLogger.logAutoMergeEvent(object.id, autoMergeMessage);
            }
          }
          objectToSave['__etag'] = __etag as string;

          // Let's call this recursively. Turns out there could be more changes like this
          // For example both alerts and deliveryStatus might get changed one after another
          return of(objectToSave);
        })
      );
    }
    return throwError(() => error);
  }

  update(object: T & { id?: SafariObjectId; __parentIds?: string[] }, options: CrudServiceCallOptions = null, context: ApiCallContext = null): Observable<T> {
    let id: SafariObjectId = object.id;
    if (object['__parentIds'] != null) {
      // eslint-disable-next-line deprecation/deprecation -- needed for backward compatibility for now
      id = SafariObject.id(...object.__parentIds, id);
    }
    return this._put(object, this._getUpdateEndpoint(object), this.adapter, context).pipe(
      mergeMap(o => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- getPrototypeOf returns any
        const adapterProto = this.adapter ? Object.getPrototypeOf(this.adapter) : null;

        // Now let's see if there's GET. NOTE: if adapter is not present at all then we assume GET exists.
        // Soon we'll make sure that no service can be created without adapter , but for now adding this
        // for compatibility
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- getPrototypeOf returns any
        const hasGet: boolean = adapterProto ? Object.hasOwnProperty.call(adapterProto, 'fromGetModel') : true;

        // If we are telling it to not autoretrieve OR if there is no GET method
        // to begin with then we just return what came in. I think we can start using in the majority
        // of our calls because we really just need the etag and we alreayd have that. However, we
        // have to be careful if there's anything else we want from the API, like for example some recalculated
        // properties etc. In that case we'd want to autoRetrieve after PUT.
        // Currently autoRetrieveOnUpdate is not  defaulted so if nothing is passsed in it will retrieve everything
        // (skip to the bottom of the function), but I think over time
        // as majority of our endpoints start using  AutoRetrieveOnUpdate.EtagOnly | we can then flip this to
        // default to  AutoRetrieveOnUpdate.EtagOnly |
        if (options?.autoRetrieveOnUpdate == AutoRetrieveOnUpdate.EtagOnly || !hasGet) {
          // NOTE that if noAutoRetrieve is set if will just take what you passed in and add etag to it.
          // That's because as of right now PUT endpoints from the API always return NULL (204-NoContent)
          // However, if in the future some new PUT endpoint is added by the API and we need to get the response
          // for it we'll have to make some modifications
          return of({ ...object, __etag: o?.__etag || object.__etag });
        } else if (options?.autoRetrieveOnUpdate == AutoRetrieveOnUpdate.Response) {
          // This will return full response and body from PUT method. Currently there is nothing that returns
          // BODY from PUT
          return of(o);
        } else if (options?.autoRetrieveOnUpdate == AutoRetrieveOnUpdate.None) {
          // Not sure why we'd want this but the option is there. It will simply return exactly what was passed in,
          // and won't change the etag.
          return of(object);
        }
        // If none of the above then go to the object's retrieve endpoint and get everything.
        return this.retrieve(id) as Observable<T>;
      }),

      catchError((error: HttpErrorResponse) =>
        this.handleUpdateError(object, error, this._getRetrieveEndpoint(id), options ? options.whitelistedMergePaths : []).pipe(mergeMap(o => this.update(o, options, context)))
      )
    );
  }
  updatePartial(
    object: Partial<T> & { id?: SafariObjectId; __parentIds?: string[] },
    originalObject: Partial<T> & { id?: SafariObjectId; __parentIds?: string[] },
    options: CrudServiceCallOptions = null,
    context: ApiCallContext = null
  ): Observable<T> {
    let id: SafariObjectId = object['id'] as string;
    if (object['__parentIds'] != null) {
      // eslint-disable-next-line deprecation/deprecation -- needed for backward compatibility for now
      id = SafariObject.id(...object.__parentIds, id);
    }
    return this._patch(object, originalObject, this._getUpdatePartialEndpoint(object), this.adapter, context).pipe(
      mergeMap(
        () =>
          // At this time we just return the object we sent. This is currently used only for bulk patching. Long
          // term I think we should have a flag (in additinoalInfo maybe) that specifies whether we want to retrieve
          // or not
          of(object) as Observable<T>
      ),

      catchError((error: HttpErrorResponse) =>
        this.handleUpdateError(object as T, error, this._getRetrieveEndpoint(id), options ? options.whitelistedMergePaths : []).pipe(
          mergeMap(o => this.updatePartial(o, originalObject, options, context))
        )
      )
    );
  }
  protected _getDeleteEndpoint(id: SafariObjectId) {
    return SafariObject.formatEndpoint(id, this._getEndpoint(this._endpointOverrides?.delete));
  }
  protected dispatchCancelMessage(actionId: SafariObjectId, id: SafariObjectId, transferDialogOptions: TransferDialogOptions, fileOperationType: FileOperationType) {
    this._store.dispatch(
      this.safariReduxFileTransferObject.default.actions.processFileFail({
        payload: {
          secondsUntilTransferDialogShown: 0,
          displayFilename: transferDialogOptions.displayName,
          downloadLink: null,
          id,
          percentComplete: 100,
          actionId,
          isError: true,
          message: 'Cancelled',
          fileOperationType
        } as FileOperationInfo
      })
    );
    return throwError(() => ({ error: 'File cancelled', status: FileTransferError.Cancelled }));
  }

  getAbortPipe(actionId: SafariObjectId, id: SafariObjectId, object: any, transferDialogOptions: TransferDialogOptions, fileOperationType: FileOperationType) {
    return this._actions.pipe(
      ofType(this.safariReduxFileTransferObject.default.actions.cancelTransfer, cancelAllFiles),
      mergeMap(() => {
        if (fileOperationType == FileOperationType.Remove || fileOperationType == FileOperationType.Add) {
          const operation = FileOperationType.Remove ? CrudOperationType.Delete : CrudOperationType.Create;
          const originalContent: FailedFile = {
            file: object,
            fileName: transferDialogOptions.displayName,
            metadata: transferDialogOptions.additionalInfo,

            id,
            actionId: actionId.toString()
          };
          this._failedObjectsService.addCancelledObject(FAILED_OBJECT_FILE, {
            operation,
            error: new Error('Cancelled'),
            originalContent
          });
        }

        return this.dispatchCancelMessage(actionId, id, transferDialogOptions, fileOperationType);
      })
    );
  }
  getRemoveAbortPipe(actionId: SafariObjectId, id: SafariObjectId, transferDialogOptions: TransferDialogOptions) {
    return this.getAbortPipe(actionId, id, null, transferDialogOptions, FileOperationType.Remove);
  }
  getUploadAbortPipe(actionId: SafariObjectId, id: SafariObjectId, file: File, transferDialogOptions: TransferDialogOptions) {
    return this.getAbortPipe(actionId, id, file, transferDialogOptions, FileOperationType.Add);
  }
  private _remove(id: SafariObjectId, transferDialogOptions: TransferDialogOptions, actionId: SafariObjectId): Observable<any> {
    const info = {
      id,
      actionId,
      displayFilename: transferDialogOptions.displayName,
      secondsUntilTransferDialogShown: transferDialogOptions.secondsUntilTransferDialogShown,
      ...{
        message: 'Removing',
        fileOperationType: FileOperationType.Remove,
        isError: false,
        percentComplete: 0,
        downloadLink: null
      }
    } as FileOperationInfo;
    this._store.dispatch(this.safariReduxFileTransferObject.default.actions.updateFileUploadProgress({ payload: info }));
    return this.getRemoveAbortPipe(actionId, id, transferDialogOptions);
  }

  delete(id: SafariObjectId, body: any, endpoint: string = null, crudServiceCallOptions: CrudServiceCallOptions): Observable<any> {
    if (this._fileTransferService) {
      const abort$ = this._remove(id, crudServiceCallOptions.transferDialogOptions, crudServiceCallOptions.actionId);

      return this._delete(endpoint ? endpoint : this._getDeleteEndpoint(id), body).pipe(
        tap(() => {
          const result = {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- OK for now
            id,
            actionId: crudServiceCallOptions.actionId,
            displayFilename: crudServiceCallOptions.transferDialogOptions.displayName,
            secondsUntilTransferDialogShown: 0,
            ...{
              message: 'Removed',
              isError: false,
              percentComplete: 100,
              fileOperationType: FileOperationType.Remove,
              downloadLink: null
            }
          } as FileOperationInfo;
          this._store.dispatch(this.safariReduxFileTransferObject.default.actions.updateFileUploadProgress({ payload: result }));
        }),
        takeUntil(abort$),

        map(() => ({ id })),

        childError(
          this._failedObjectsService,
          null, // this._loggerService,
          CrudOperationType.Delete,
          FAILED_OBJECT_FILE,
          crudServiceCallOptions.transferDialogOptions.originalContent,
          true
        )
      );
    }
    return this._delete(endpoint ? endpoint : this._getDeleteEndpoint(id), body).pipe(map(() => ({ id })));
  }
  deleteMultiple(id: SafariObjectId, body: any, endpoint: string = null): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- disable any check for now
    return this._delete(endpoint ? endpoint : this._getDeleteEndpoint(id), this._adapter.toDeleteMultipleModel(body)).pipe(map(() => ({ id })));
  }
}
