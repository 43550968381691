<div class="wrapper">
   <main>      
      <div class="container-fluid">
         <div class="justify-content-center">
            <div class="col-md-12 text-center mt-5">
               @if(errorMessage) {
                  <span class="s_txt-xxl">ServePort Update Error</span>
               } @else {
                  <span class="s_txt-xxl">Updating ServePort...</span>
               }

               @if(errorMessage) {
                  <hr>
               <div class="text-danger">
                  <br>
                  <p>
                     Failed to update the application. This can be caused by a network error, VPN or proxy settings, or ad-blockers and browser extensions. <br>
                  </p>
                   <p>
                     If the error keeps occuring please contact your IT department to verify that the <b>serveport.com</b> domain is added to the list of safe domains. 
                   </p>
       
                   <p>
                     In the meanwhile you can temporarily bypass this error by pressing CTRL and F5 at the same time (Windows) or CMD, Shift and R (Mac).
                   </p>
                  
               </div>
               }  


               
            </div>
         </div>
      </div>
   </main>
</div>