import * as API from './_comanage-api-models.generated';

export enum NotificationDeliveryMethod {
  Email = 1,
  NotificationBell = 2
}
export enum NotificationType {
  PortalExpiredUnaccessed = 1,
  FaxDeliveryFailure = 2,
  EmailDeliveryFailure = 3,
  AssignedAsReviewer = 4
}

export type DropdownType = API.PicklistType;
export const DropdownType = API.PicklistType;

export type PhoneType = API.PhoneType;
export const PhoneType = API.PhoneType;

enum SafariAppNameExtensions {
  RmsName = 'RMS',
  LpmsName = 'LPMS'
}

export const SafariApp = { ...API.App, ...SafariAppNameExtensions };
export type SafariApp = typeof SafariApp;

export type Country = API.Country;
export const Country = API.Country;

export type SecurityRole = API.SecurityRole;
export const SecurityRole = API.SecurityRole;
export type WhiteLabelEmailType = API.WhiteLabelEmailType;
export const WhiteLabelEmailType = API.WhiteLabelEmailType;

export type EmployeeSize = API.EmployeeSize;
export const EmployeeSize = API.EmployeeSize;

export type IndustryClassification = API.IndustryClassification;
export const IndustryClassification = API.IndustryClassification;

export type SupportContactLevel = API.SupportContactLevel;
export const SupportContactLevel = API.SupportContactLevel;

export type GarnishmentProcessingType = API.GarnishmentProcessingType;
export const GarnishmentProcessingType = API.GarnishmentProcessingType;
