import { Injectable } from '@angular/core';
import { UserPermissionType } from '@safarilaw-webapp/shared/auth';
import { ApiCallContext } from '@safarilaw-webapp/shared/common-objects-models';
import { ApiDropdownAdapter } from '@safarilaw-webapp/shared/crud';
import { UserPermission } from '../../co-user/models/app/user-permission';
import { DropdownType } from '../../enums';
import { DropdownOneTimePinRead } from '../dropdown-onetimepin/models/api/dropdown-onetimepin-read';
import { DropdownOneTimePin } from '../dropdown-onetimepin/models/app/dropdown-onetimepin';
import { DropdownStateAdapter } from '../dropdown-state/adapter/dropdown-state-adapter';

@Injectable({
  providedIn: 'root'
})
export class DropdownApiAdapter extends ApiDropdownAdapter {
  constructor(private _dropdownStateAdapter: DropdownStateAdapter) {
    super();
  }
  fromListModel(model: any, context: ApiCallContext) {
    const url = context.__url;
    if (url.endsWith('/' + DropdownType.UserPermissions.toString())) {
      const extendedModel = model;
      const mappedModel: Required<UserPermission> = {
        ...super.fromListModel(extendedModel),

        app: extendedModel.app,
        description: extendedModel.description,
        isOwner: false
      };
      mappedModel.isOwner = mappedModel.id == UserPermissionType.Owner;
      return mappedModel;
    } else if (url.endsWith('/' + DropdownType.RequireOneTimePinDefaults.toString())) {
      const extendedModel = model as DropdownOneTimePinRead;
      let mappedModel: Required<DropdownOneTimePin> = null;
      mappedModel = {
        ...super.fromListModel(extendedModel),
        isOptional: extendedModel.isOptional,
        requireOneTimePin: extendedModel.requireOneTimePin
      };
      return mappedModel;
    } else if (url.endsWith('/' + DropdownType.States.toString())) {
      return this._dropdownStateAdapter.fromListModel(model);
    }
    return super.fromListModel(model);
  }
}
