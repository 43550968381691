import { Injectable } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { UI_REDUCER } from '@safarilaw-webapp/shared/redux';
import { AppErrorReduxObject, AppUiReduxObject, ISharedState } from './actions/ui-actions';

@Injectable({
  providedIn: 'root'
})
export class AppReducerService {
  constructor(private _appUiReduxObject: AppUiReduxObject, private _appErrorReduxObject: AppErrorReduxObject) {}
  get reducers() {
    const reducers: ActionReducerMap<ISharedState> = {
      errorState: UI_REDUCER(this._appErrorReduxObject),
      uiState: UI_REDUCER(this._appUiReduxObject)
    };
    return reducers;
  }
}
