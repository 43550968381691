/* eslint-disable no-restricted-syntax -- framework-level functionality */
import { CommonModule } from '@angular/common';
import { inject, InjectionToken, NgModule } from '@angular/core';
import { EffectsModule, USER_PROVIDED_EFFECTS } from '@ngrx/effects';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { getEffectTokens } from './redux/injectable-effect-tokens';
import { ReducerService } from './redux/reducer.service';
import { FEATURE_STATE, IRmsState } from './redux/state.interface';

import { SafariReduxFileTransferObjectDefinition } from '@safarilaw-webapp/shared/common-objects-models';
import { fileTransferReduxObject } from './redux/file-transfer';
import { AttachmentLinkName, GENERATE_SUBMISSION_TEMPLINK_REDUX_OBJECT_TOKEN, GENERATE_TEMPLINK_REDUX_OBJECT_TOKEN, GENERATE_TEMPZIP_REDUX_OBJECT_TOKEN } from './redux/injectable-object-tokens';
const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<IRmsState>>('Rms DataAccess Reducers');

export const RMS_FILE_TRANSFER_OBJECT = new InjectionToken<SafariReduxFileTransferObjectDefinition>('Rms FileTransfer Object', {
  factory: () => fileTransferReduxObject,
  providedIn: 'root'
});

@NgModule({
  imports: [CommonModule, StoreModule.forFeature(FEATURE_STATE, REDUCER_TOKEN), EffectsModule.forFeature()],
  providers: [
    ...[
      {
        provide: REDUCER_TOKEN,
        deps: [ReducerService],
        useFactory: (service: ReducerService) => service.reducers
      },
      {
        provide: USER_PROVIDED_EFFECTS,
        useValue: getEffectTokens(),
        multi: true
      }
    ]
  ]
})
export class FeatureRmsDataAccessModule {
  constructor() {
    AttachmentLinkName['_serveportFileAttachment'] = inject(GENERATE_TEMPLINK_REDUX_OBJECT_TOKEN).toString();
    AttachmentLinkName['_portalZipAttachment'] = inject(GENERATE_TEMPZIP_REDUX_OBJECT_TOKEN).toString();
    AttachmentLinkName['_submissionAcknowledgmentAttachment'] = inject(GENERATE_SUBMISSION_TEMPLINK_REDUX_OBJECT_TOKEN).toString();
  }
}
